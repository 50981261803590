// Component that will be used to highlight if the button is selected or not
// This component will display an icon and a text next to it
// props:
//  - selected: boolean -> if the button is selected or not (to highlight it) 
//  - onClick: function -> function to be executed when the button is clicked
//  - icon: string (name of the icon) or ReactNode (icon component) -> icon to be displayed
//  - text: string
//  - className: string

import React from 'react'

import Icon from './../../Icons/Icon'

export const ActiveButton = ({
    selected,
    onClick,
    icon,
    text,
    className,
    ...props
}) => {
    // if the icon is a string, we will use the Icon component 
    // to display the icon
    return (
        <button
            onClick={onClick}
            className={`btn h-9 flex flex-row items-center text-black bg ${
                selected ? 'bg-amber-300 bg-opacity-90' : 'bg-gray-100'
            } ${className}`}
            {...props}
        >
            {typeof icon === 'string' ? <Icon
                name={icon}
                className={`h-[18px] w-[18px] pr-5 ${
                    selected ? 'text-experts-green-7' : 'text-black'
                }`}
            />: icon}
            <div className="text-[10px] font-semibold">{text}</div>
        </button>
    )
}