export const LEVERAGE = 'Leverage'
export const INCREASE = 'Increase'
export const STOP_LOSS = 'stopLoss'
export const CROSS = 'Cross'
export const CLOSE_PARTIAL = 'closePartial'
export const HEDGING = 'hedgingOrder'
export const EMPTY = null
export const NA = 'N/A'

export const PHASE_TYPES_ARR = [LEVERAGE, INCREASE, STOP_LOSS, CROSS, HEDGING]

export const PHASE_TYPES = {
    LEVERAGE,
    INCREASE,
    STOP_LOSS,
    CROSS,
    CLOSE_PARTIAL,
    EMPTY,
}

export const TOOLTIP_MESSAGE = {
    [EMPTY]: '',
    [LEVERAGE]:
        'Enter a value to define the desired level of leverage. This value will determine the specific leverage value that will be applied to your investment.',
    [INCREASE]:
        'Enter a value to represent the desired percentage increase relative to the current qty. This value will be applied to your investment and determine the amount of increase in your investment.',
    [CROSS]:
        'Enter a value to define the desired level of leverage. This value will determine the specific leverage value that will be applied to your investment.',
    [STOP_LOSS]:
        'Enter a value to represent the desired percentage increase relative to the current Stop Loss percentage. Stop Loss refers to the price level at which an investment will automatically be sold to prevent further losses. This value will determine the new Stop Loss level for your investment.',
    [CLOSE_PARTIAL]:
        'Percentage of the investment to be closed. This value will determine the amount of your investment that will be closed.',
}

export const TITLE_BY_TYPE = {
    [LEVERAGE]: 'Change Leverage',
    [INCREASE]: 'Increase Quantity',
    [CROSS]: 'Change Cross',
    [STOP_LOSS]: 'Change Stop Loss',
    [CLOSE_PARTIAL]: 'Close Partial',
    [HEDGING]: 'Hedging Order',
    [EMPTY]: 'None',
}

export const FIELD_TITLE_BY_TYPE = {
    [LEVERAGE]: 'Leverage',
    [INCREASE]: 'QTY',
    [CROSS]: 'Leverage',
    [STOP_LOSS]: 'Stop Loss Percentage',
    [CLOSE_PARTIAL]: 'Close Partial',
    [HEDGING]: 'Hedging Order',
    [EMPTY]: 'Leverage',
}

export const CONDITION_NEGATIVE_TITLE_BY_TYPE = {
    // [LEVERAGE]: 'Negative',
    // [INCREASE]: 'Negative',
    // [CROSS]: 'Negative',
    // [HEDGING]: 'Negative',
    // [CLOSE_PARTIAL]: 'Negative',
    DEFAULT: 'Negative',
    [STOP_LOSS]: 'Distance Negative',
}

export const CONDITION_POSITIVE_TITLE_BY_TYPE = {
    // [EMPTY]: 'Positive',
    // [LEVERAGE]: 'Positive',
    // [INCREASE]: 'Positive',
    // [CROSS]: 'Positive',
    // [CLOSE_PARTIAL]: 'Positive',
    DEFAULT: 'Positive',
    [STOP_LOSS]: 'Distance Positive',
}

export const MESSAGE_BY_ACTION = {
    [LEVERAGE]: 'Change Leverage',
    [CROSS]: 'Change to Cross',
    [INCREASE]: 'Increase Qty',
    [NA]: 'N/A',
    [STOP_LOSS]: 'Change Stop Loss',
    [CLOSE_PARTIAL]: 'Close Partial',
    [HEDGING]: 'Hedging Order',
    [EMPTY]: 'None',
}
