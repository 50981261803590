/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { Input, Radio } from 'antd'
import {
    usePlaceLimitOrder,
    useTradesReducerState,
    usePlaceMarketOrder,
    useSymbolsUsed,
    useSetChildActiveTrades,
    useRepurchaseLimitOrder,
    usePlaceMarketRatio,
} from 'src/store/reducers/tradesReducer/trades.hook'
import useResponsiveValue from 'src/hooks/useResponsiveValue'

import { useEmisors } from '../../store/reducers/dashboardReducer/dashboardReducer'
import Button from 'src/components/Buttons/Button'
import { numberValidation } from 'src/service/inputValidations'
import ActiveChildsTrades from './ActiveChildsTrades'
import { useRetryCallback } from './../../hooks/useRetryCallback'

const initialStateRepurchase = {
    repurchase: 50,
    customRepurchase: '10',
    repurchaseOption: 'default',
}

const TokenTrade2 = ({
    symbol = '',
    orderType = '',
    disabled,
    totalBalanceUsed,
    selectedStrategy,
}) => {
    const { maxCap, tradeBalance, tradePercentage, initialLeverage } =
        selectedStrategy ?? {
            maxCap: 0,
            tradeBalance: [],
            tradePercentage: [],
        }

    const initialState = {
        maxaccbalance: maxCap,
        typeOrder: 'limit',
        limitPrice: '',
        stopLoss: '',
        takeProfit: '',
        leverage: Math.min(...initialLeverage),
        maxRisk: Math.min(...tradePercentage),
    }

    const [
        {
            maxaccbalance,
            limitPrice,
            stopLoss,
            takeProfit,
            maxRisk,
            typeOrder,
            leverage,
        },
        setState,
    ] = useState(initialState)
    const [{ repurchase, customRepurchase, repurchaseOption }, setRepurchase] =
        useState(initialStateRepurchase)

    const {
        bybitTrades,
        tradesMoney,
        activeOrders,
        symbolsPending,
        childsActiveTrades,
    } = useTradesReducerState()
    const { emisorSelected } = useEmisors()
    const symbolsUsed = useSymbolsUsed()
    const currentSide = orderType === 'Long' ? 'Buy' : 'Sell'

    const enableRepurchase = useMemo(() => {
        return (
            symbolsUsed.length > 0 &&
            symbolsUsed.filter((v) => v[0] === symbol && v[1] === currentSide)
                .length > 0
        )
    }, [bybitTrades, symbol, currentSide])

    const { placeLimitOrder, loading: loadingLimit } = usePlaceLimitOrder(true)
    const { placeMarketRatio, loading: loadingRatio } =
        usePlaceMarketRatio(true)

    const { repurchaseLimitOrder, loading: loadingRepurchaseLimit } =
        useRepurchaseLimitOrder()
    const { placeMarketOrder, loading: loadingMarket } = usePlaceMarketOrder()
    const setChildsActiveTrades = useSetChildActiveTrades()

    const tradeData = useMemo(() => {
        if (!enableRepurchase) return undefined
        return (
            bybitTrades.find(
                (v) => v.symbol === symbol && v.side === currentSide
            ) ??
            tradesMoney.find(
                (v) => v.symbol === symbol && v.side === currentSide
            )
        )
    }, [enableRepurchase, symbol, currentSide, bybitTrades, tradesMoney])

    const isTradeOpen = useMemo(() => {
        return (
            symbolsUsed.length > 0 &&
            symbolsUsed.filter((v) => v[0] === symbol && v[1] !== currentSide)
                .length > 0
        )
    }, [symbolsUsed, symbol, currentSide])

    const handlePlaceOrder = useCallback(async () => {
        let body = {
            keyId: emisorSelected,
            side: orderType,
            symbol,
        }
        if (!enableRepurchase) {
            body = {
                ...body,
                maxaccbalance: maxaccbalance,
                percentage: maxRisk,
                leverage: Number(leverage),
                stopLoss: Number(stopLoss),
                takeProfit: Number(takeProfit),
            }

            if (typeOrder === 'limit') {
                body = {
                    ...body,
                    entryPrice: Number(limitPrice),
                }
                placeLimitOrder(body)
            } else {
                placeMarketRatio(body)
            }
        } else {
            const percentage =
                repurchaseOption === 'default'
                    ? repurchase
                    : Number(customRepurchase)

            body = {
                ...body,
                percentage,
                qty: Number(tradeData.quantity),
            }
            if (typeOrder === 'limit') {
                body = {
                    ...body,
                    entryPrice: Number(limitPrice),
                }
                repurchaseLimitOrder(body)
            } else {
                body = {
                    ...body,
                    isRepurchase: true,
                }
                placeMarketOrder(body)
            }
        }
    }, [
        placeMarketRatio,
        enableRepurchase,
        typeOrder,
        orderType,
        repurchase,
        repurchaseOption,
        customRepurchase,
        emisorSelected,
        symbol,
        limitPrice,
        tradeData,
        maxRisk,
        stopLoss,
        leverage,
        maxaccbalance,
        takeProfit,
    ])

    const disabledAll = useMemo(() => {
        return (
            activeOrders.filter((v) => v.symbol === symbol && !v.reduce_only)
                .length > 0 ||
            symbolsPending.filter((v) => v === symbol).length > 0 ||
            disabled ||
            loadingRepurchaseLimit ||
            loadingRatio ||
            loadingLimit ||
            loadingMarket ||
            isTradeOpen
        )
    }, [
        symbol,
        activeOrders,
        disabled,
        isTradeOpen,
        loadingLimit,
        loadingRepurchaseLimit,
        loadingMarket,
        loadingRatio,
    ])

    const disabledButton = useMemo(() => {
        return (
            (!enableRepurchase &&
                ((typeOrder === 'limit' && !limitPrice) ||
                    !leverage ||
                    !stopLoss ||
                    !maxRisk)) ||
            (enableRepurchase && typeOrder === 'limit' && !limitPrice)
        )
    }, [
        limitPrice,
        totalBalanceUsed,
        enableRepurchase,
        typeOrder,
        stopLoss,
        maxRisk,
        leverage,
    ])

    const buttonSize = useResponsiveValue({ base: 'small', xl: 'normal' })

    useEffect(() => {
        setState((state) => ({ ...state, limitPrice: '' }))
        if (enableRepurchase) {
            setRepurchase(initialStateRepurchase)
        } else {
            setState(initialState)
        }
    }, [enableRepurchase])

    const handleSetInitialState = useCallback(() => {
        setState((state) => ({
            typeOrder: 'limit',
            limitPrice: '',
            stopLoss: '',
            maxaccbalance: maxCap,
            leverage: Math.min(...initialLeverage),
            maxRisk: Math.min(...tradePercentage),
        }))
    }, [symbol, tradePercentage, initialLeverage, maxCap])

    useEffect(() => {
        handleSetInitialState()
    }, [symbol, selectedStrategy])

    return (
        <React.Fragment>
            <ActiveChildsTrades
                visible={childsActiveTrades}
                onClose={() => {
                    setChildsActiveTrades(false)
                }}
            />
            <div className="bg-white rounded-md border py-3 px-3 shadow-experts-lg">
                <div className="space-y-3">
                    <div className="flex">
                        <div className="text-xs xl:text-base font-semibold tracking-widest cursor-pointer hover:text-experts-green-2">
                            {symbol}
                        </div>
                        <div className="ml-auto">
                            <div
                                className={`text-sm font-semibold tracking-wider rounded-full w-16 text-center border ${
                                    orderType === 'Long'
                                        ? 'text-experts-green-2 bg-experts-green-4 border-experts-green-6'
                                        : 'text-experts-red-2 bg-experts-red-1 border-experts-red-11'
                                }`}
                            >
                                {orderType}
                            </div>
                        </div>
                    </div>{' '}
                    <div className="">
                        <div>Order</div>{' '}
                        <div className="custom-radio">
                            <Radio.Group
                                options={[
                                    { label: 'Limit', value: 'limit' },
                                    { label: 'Market', value: 'market' },
                                ]}
                                disabled={disabledAll}
                                name="typeOrder"
                                onChange={({ target: { value, name } }) => {
                                    setState((state) => ({
                                        ...state,
                                        [name]: value,
                                    }))
                                }}
                                value={typeOrder}
                                optionType="button"
                            />
                        </div>
                    </div>
                    {!enableRepurchase && (
                        <div className="">
                            <div>Max Account Balance</div>{' '}
                            <div className="custom-radio">
                                <Radio.Group
                                    options={tradeBalance.map((v) => ({
                                        label: `${v}%`,
                                        value: v,
                                    }))}
                                    disabled={disabledAll}
                                    name="maxaccbalance"
                                    value={maxaccbalance}
                                    optionType="button"
                                    size={'middle'}
                                    onChange={({ target: { value, name } }) => {
                                        setState((state) => ({
                                            ...state,
                                            [name]: value,
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {typeOrder === 'limit' && (
                        <div className="">
                            <div>Limit/Price</div>
                            <Input
                                id="limitPrice"
                                name="limitPrice"
                                placeholder="Limit/Price"
                                value={limitPrice}
                                disabled={disabledAll}
                                onChange={({ target: { value, name } }) => {
                                    const _value = numberValidation(value, 6, 0)

                                    if (_value)
                                        setState((state) => ({
                                            ...state,
                                            [name]: _value,
                                        }))
                                }}
                                onBlur={(e) => {
                                    const {
                                        target: { value, name },
                                    } = e
                                    if (value === '0')
                                        setState((state) => ({
                                            ...state,
                                            [name]: '1',
                                        }))
                                }}
                                size={'middle'}
                            />
                        </div>
                    )}
                    {!enableRepurchase && (
                        <div className="">
                            <div>Leverage</div>
                            <div className="custom-radio">
                                <Radio.Group
                                    optionType="button"
                                    options={initialLeverage.map((v) => ({
                                        label: `${v}x`,
                                        value: v,
                                    }))}
                                    disabled={disabledAll}
                                    name="leverage"
                                    onChange={({ target: { value, name } }) => {
                                        setState((state) => ({
                                            ...state,
                                            [name]: value,
                                        }))
                                    }}
                                    value={leverage}
                                    size={'middle'}
                                />
                            </div>
                        </div>
                    )}
                    {!enableRepurchase ? (
                        <React.Fragment>
                            <div className="">
                                <div>Take Profit</div>
                                <Input
                                    id="takeProfit"
                                    name="takeProfit"
                                    placeholder="Take Profit"
                                    disabled={disabledAll}
                                    value={takeProfit}
                                    onChange={({ target: { value, name } }) => {
                                        const _value = numberValidation(
                                            value,
                                            6,
                                            0
                                        )
                                        if (_value)
                                            setState((state) => ({
                                                ...state,
                                                [name]: _value,
                                            }))
                                    }}
                                    onBlur={({ target: { value, name } }) => {
                                        if (value === '0')
                                            setState((state) => ({
                                                ...state,
                                                [name]: '1',
                                            }))
                                    }}
                                    size={'middle'}
                                />
                            </div>
                            <div className="">
                                <div>Stop Loss</div>
                                <Input
                                    id="stopLoss"
                                    name="stopLoss"
                                    placeholder="Stop Loss"
                                    disabled={disabledAll}
                                    value={stopLoss}
                                    onChange={({ target: { value, name } }) => {
                                        const _value = numberValidation(
                                            value,
                                            6,
                                            0
                                        )
                                        if (_value)
                                            setState((state) => ({
                                                ...state,
                                                [name]: _value,
                                            }))
                                    }}
                                    onBlur={({ target: { value, name } }) => {
                                        if (value === '0')
                                            setState((state) => ({
                                                ...state,
                                                [name]: '1',
                                            }))
                                    }}
                                    size={'middle'}
                                />
                            </div>
                            {/* <div className="">
                                <div>Ratio percentage</div>
                                <Input
                                    id="ratioPercentage"
                                    name="ratioPercentage"
                                    placeholder="Ratio Percentage"
                                    disabled={disabledAll}
                                    value={ratioPercentage}
                                    onChange={({ target: { value, name } }) => {
                                        const _value = numberValidation(
                                            value,
                                            6,
                                            0
                                        )

                                        if (_value)
                                            setState((state) => ({
                                                ...state,
                                                [name]: _value,
                                            }))
                                    }}
                                    onBlur={({ target: { value, name } }) => {
                                        if (value === '0')
                                            setState((state) => ({
                                                ...state,
                                                [name]: '1',
                                            }))
                                    }}
                                    size={'middle'}
                                />
                            </div> */}
                            <div className="">
                                <div>Trade Max Risk</div>{' '}
                                <div className="custom-radio">
                                    <Radio.Group
                                        optionType="button"
                                        options={tradePercentage.map((v) => ({
                                            label: `${v}%`,
                                            value: v,
                                        }))}
                                        disabled={disabledAll}
                                        name="maxRisk"
                                        onChange={({
                                            target: { value, name },
                                        }) => {
                                            setState((state) => ({
                                                ...state,
                                                [name]: value,
                                            }))
                                        }}
                                        value={maxRisk}
                                        size={'middle'}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className="flex flex-row">
                            <div>
                                <div>Qty Percentage</div>{' '}
                                <div className="custom-radio">
                                    <Radio.Group
                                        options={[
                                            { label: '50%', value: 50 },
                                            { label: '100%', value: 100 },
                                        ]}
                                        disabled={disabledAll}
                                        onChange={({ target: { value } }) => {
                                            setRepurchase((state) => ({
                                                ...state,
                                                repurchase: value,
                                                repurchaseOption: 'default',
                                            }))
                                        }}
                                        value={
                                            repurchaseOption === 'default'
                                                ? repurchase
                                                : undefined
                                        }
                                        optionType="button"
                                    />
                                </div>
                            </div>
                            <div
                                className={`custom-input-risk ml-2 mt-auto ${
                                    repurchaseOption === 'custom'
                                        ? 'custom-input-risk-selected'
                                        : ''
                                }`}
                            >
                                <Input
                                    id="customRepurchase"
                                    name="customRepurchase"
                                    className="w-12"
                                    value={customRepurchase}
                                    disabled={disabledAll}
                                    onFocus={() => {
                                        setRepurchase((state) => ({
                                            ...state,
                                            repurchaseOption: 'custom',
                                        }))
                                    }}
                                    onChange={({ target: { value, name } }) => {
                                        const _value = numberValidation(
                                            value,
                                            3,
                                            0,
                                            100
                                        )
                                        if (_value)
                                            setRepurchase((state) => ({
                                                ...state,
                                                [name]: _value,
                                            }))
                                    }}
                                    onBlur={({ target: { value } }) => {
                                        if (value === '0')
                                            setRepurchase((state) => ({
                                                ...state,
                                                [name]: '1',
                                            }))
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <Button
                        loading={
                            loadingRatio ||
                            loadingLimit ||
                            loadingMarket ||
                            loadingRepurchaseLimit
                        }
                        onClick={handlePlaceOrder}
                        disabled={disabledAll || disabledButton}
                        size={buttonSize}
                        className="w-full text-xs xl:text-lg h-8"
                        spinnerSize="normal"
                    >
                        {loadingLimit ||
                        loadingMarket ||
                        loadingRepurchaseLimit ||
                        loadingRatio
                            ? ''
                            : !enableRepurchase
                            ? 'Open Trade'
                            : 'Repurchase'}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TokenTrade2
