/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useCallback } from 'react'
import { useSetSelectedEmisor } from 'src/store/reducers/dashboardReducer/dashboardReducer'
// import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { Input, Select } from 'antd'
import Button from 'src/components/Buttons/Button'
import { useEmisors } from './../../../store/reducers/dashboardReducer/dashboardReducer'
import {
    useAdmin,
    useSetSharedKey,
    useShareKeys,
} from 'src/store/reducers/adminReducer/adminReducer'
import { sleep } from 'src/utils/promise'
import useDeepCompareEffect from 'src/hooks/useDeepCompareEffect'

const ActiveKey = ({ data, main, activeKey, sharedkeys }) => {
    const setSelectedEmisor = useSetSelectedEmisor()
    const { traders } = useAdmin()
    const { SetSharedKey, loading, error } = useSetSharedKey()

    const options = traders?.map((trade) => {
        return {
            label: `${trade.username}`,
            value: trade.id,
        }
    })

    const [users, setUsers] = useState(sharedkeys)

    useDeepCompareEffect(() => {
        setUsers(sharedkeys)
    }, [sharedkeys])

    const selectProps = {
        mode: 'multiple',
        className: 'w-full',
        value: users,
        options,
        onChange: (newValue) => {
            setUsers(newValue)
        },
        placeholder: 'Select User to Shared...',
        maxTagCount: 'responsive',
    }

    // const [showDetails, setShowDetails] = useState(false)

    const accountName = useMemo(() => {
        return main ? `Main: ${data.name}` : `Sub Account: ${data.name}`
    }, [data, main])

    const handleSaveSharedKey = useCallback(async () => {
        await sleep(600)

        await SetSharedKey(data.id, users).then(() => {})
    }, [users, data.id])

    return (
        <div className="mt-4 p-5 text rounded-md shadow-experts-lg border bg-white">
            <div className="flex justify-end ">
                {/* <div className="flex flex-row items-center px-1  cursor-pointer">
                    <div
                        className="relative mt-[1px] ml-2"
                        onClick={() => {
                            setShowDetails(!showDetails)
                        }}
                    >
                        {showDetails ? (
                            <CaretUpOutlined />
                        ) : (
                            <CaretDownOutlined />
                        )}
                    </div>
                </div> */}
            </div>
            <div className="mb-5 flex justify-start ">
                <div
                    className={`text-base ${
                        activeKey
                            ? "font-semibold before:content-[''] before:inline-block before:w-2 before:h-2 before:rounded-full before:mr-2 before:relative before:-mt-[0.05rem] before:bg-experts-green-2"
                            : ''
                    }`}
                >
                    {accountName} {data.shared ? '(Shared)' : ''}
                </div>
            </div>
            <form className="grid gap-4">
                <div className="grid grid-cols-5">
                    <div className="col-span-2 mr-10">
                        <Input
                            className="w-full pl-5 h-10"
                            placeholder="*********"
                            type="password"
                            disabled={true}
                        />
                    </div>
                    <div className="col-span-2 mr-10">
                        <Input
                            className="w-full pl-5 h-10"
                            placeholder="*****************"
                            type="password"
                            disabled={true}
                        />
                    </div>
                    <div className="">
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedEmisor(data.id)
                            }}
                            disabled
                            variant="secondary"
                            className="w-full h-10"
                        >
                            SELECT ACCOUNT{' '}
                        </Button>
                    </div>
                </div>
                <div className="grid grid-cols-5">
                    <div className="col-span-2 mr-10">
                        {/* <Input
                            className="w-full pl-5 h-10"
                            placeholder="trader"
                            type="Input"
                            disabled={false}
                        /> */}
                        <Select {...selectProps} />
                    </div>
                    <div className="col-span-1">
                        <Button
                            onClick={(e) => {
                                e.preventDefault()
                                handleSaveSharedKey()
                            }}
                            disabled={loading}
                            loading={loading}
                            variant="gray"
                            className="w-full h-10"
                        >
                            Share{' '}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

const ActiveKeys = ({ emisorId }) => {
    const { emisors, emisorSelected } = useEmisors()
    const { sharedkeys } = useShareKeys()

    return (
        <div className="mt-4">
            <div className="text-lg font-bold">Keys</div>
            {emisors?.map((data, index) => {
                return (
                    <ActiveKey
                        key={`active-key-${data.id}`}
                        data={data}
                        main={index === 0}
                        activeKey={data.id === emisorSelected}
                        sharedkeys={
                            sharedkeys
                                ?.find((v) => v.key.id === data.id)
                                ?.users.map((user) => user.id) ?? []
                        }
                    />
                )
            })}
        </div>
    )
}

export default ActiveKeys
