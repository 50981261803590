import React from 'react'

const SimulatorStaticsParams = ({
    side,
    accountBalance,
    qty,
    margin,
    leverage,
    entryPrice,
}) => {
    return (
        <div className="grid grid-cols-3 gap-3">
            <div className="">
                <div className="font-medium text-[10px] text-experts-gray-5">
                    Side
                </div>
                <div className="text-black font-bold text-xs mt-1">{side}</div>
            </div>
            <div className="">
                <div className="font-medium text-[10px] text-experts-gray-5">
                    Account Balance
                </div>
                <div className="text-black font-bold text-xs mt-1">
                    {accountBalance}
                </div>
            </div>
            <div className="">
                <div className="font-medium text-[10px] text-experts-gray-5">
                    Qty
                </div>
                <div className="text-black font-bold text-xs mt-1">{qty}</div>
            </div>
            <div className="">
                <div className="font-medium text-[10px] text-experts-gray-5">
                    Margin
                </div>
                <div className="text-black font-bold text-xs mt-1">
                    {margin}
                </div>
            </div>
            <div className="">
                <div className="font-medium text-[10px] text-experts-gray-5">
                    Leverage
                </div>
                <div className="text-experts-yellow-3 font-bold text-xs mt-1">
                    {leverage}x
                </div>
            </div>
            <div className="">
                <div className="font-medium text-[10px] text-experts-gray-5">
                    Entry Price
                </div>
                <div className="text-black font-bold text-xs mt-1">
                    {entryPrice}
                </div>
            </div>
        </div>
    )
}

export default SimulatorStaticsParams
