import React from 'react'

import AlertModal from '../../Modals/GenericModals/AlertModal'

const ActiveChildsOrders = ({ ...props }) => {
    return (
        <AlertModal
            {...props}
            detail="You cannot subscribe a postion to money management, some users have active orders. Please try again later."
        />
    )
}

export default ActiveChildsOrders
