/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Alert, Checkbox, Form, Input } from 'antd'
import { FaUser, FaLock, FaEnvelope, FaSpinner, FaWallet } from 'react-icons/fa'
import { TbArrowLeft } from 'react-icons/tb'

import { useFormik } from 'formik'
import * as yup from 'yup'
import validator from 'validator'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useEthers } from '@usedapp/core'
import useAuthW3 from '../hooks/web3/useAuthW3'
import logoIgniite from 'src/assets/images/logo.png'
import {
    sendEmailForgotPassord,
    FORGOT_PASSWORD_SCREEN,
    useUpdateScreenAccess,
    useResetScreenAccess,
} from '../store/reducers/auth/authReducer'
import FormikAntInput from '../components/FormikAntDInputs/FormikAntInput'
import FormikAntInputPassword from '../components/FormikAntDInputs/FormikAntInputPassword'
import { IoWalletOutline } from 'react-icons/io5'
import FormikAntInpuWithButton from '../components/FormikAntDInputs/FormikAntInpuWithButton'
import NoLoggedLayout from 'src/components/layouts/AuthLayout'
import useAuth from '../hooks/useAuth'
import { sleep } from 'src/utils/promise'
import useInput from './../hooks/useInput'
import Button from './../components/Buttons/Button'

const FormItem = Form.Item

const schema = yup.object().shape({
    email: yup
        .string()
        .test(
            'emptySpace',
            'Must not contain empty spaces',
            (value) => !value || (value && !value?.includes(' '))
        )
        .email('must be a valid email')
        .required('this field is required'),
})

const ForgetPassword = () => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState('')
    const { value: valueEmail, bind: bindEmail } = useInput('')
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const updateScreenAccess = useUpdateScreenAccess()

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault()

            let err = ''
            setLoading(true)
            if (valueEmail.trim() === '') err = 'Field is required'

            if (err !== '') {
                setError(err)
                await sleep(1000)
                setLoading(false)
                return
            }
            try {
                await dispatch(sendEmailForgotPassord(valueEmail)).unwrap()
                updateScreenAccess(FORGOT_PASSWORD_SCREEN)
                navigate('emailSended')
            } catch (error) {
                console.log('Error: ', error)
                if (error?.error) setError(error.message)
            }
            await sleep(1000)
            setLoading(false)
        },
        [valueEmail, updateScreenAccess, dispatch]
    )

    useResetScreenAccess(loading)
    return (
        <div className="w-full max-w-[450px] mx-auto relative">
            <div className="mt-8 flex flex-row items-end ">
                <div className="text-lg ">FORGOT PASSWORD</div>
            </div>

            <div className="mt-6  font-semibold text-base">
                Please enter your email to search for your account.
            </div>
            <div className="flex justify-center mt-4">
                <form className="flex flex-col w-full" onSubmit={handleSubmit}>
                    <div className="w-full">
                        <Input
                            id={'email'}
                            name={'email'}
                            placeholder={'EMAIL'}
                            bordered={false}
                            className={`h-[60px] rounded-sm w-full shadow-experts-lg bg-white placeholder:`}
                            style={{ fontSize: '12px' }}
                            {...bindEmail}
                        />
                    </div>

                    <div className={'mt-4 h-[40.17px]'}>
                        {error && (
                            <Alert
                                className=""
                                showIcon
                                message={error}
                                closable
                                type="error"
                                onClose={() => setError('')}
                            />
                        )}
                    </div>

                    <div className="flex flex-row mt-8 mb-6 gap-3">
                        <Button
                            onClick={() => {
                                navigate('/login')
                            }}
                            type="submit"
                            variant="light"
                            className="h-[68px] w-1/5 rounded-none"
                        >
                            {' '}
                            <TbArrowLeft size={'1.6rem'} className="" />
                        </Button>
                        <Button
                            disabled={loading}
                            type="submit"
                            className="h-[68px] w-full rounded-none"
                        >
                            {loading ? (
                                <FaSpinner className="animate-spin text-2xl" />
                            ) : (
                                'SEND EMAIL'
                            )}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ForgetPassword
