import React, { useCallback } from 'react'
import { useFormik } from 'formik'

import FormikAntInput from '../../../FormikAntDInputs/FormikAntInput'
import Button from '../../../Buttons/Button'
import { Select, Tooltip } from 'antd'
import numeral from 'numeral'
import useResponsiveValue from '../../../../hooks/useResponsiveValue'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import {
    PHASE_TYPES_ARR,
    PHASE_TYPES,
    TITLE_BY_TYPE,
    FIELD_TITLE_BY_TYPE,
    TOOLTIP_MESSAGE,
    CONDITION_NEGATIVE_TITLE_BY_TYPE,
    CONDITION_POSITIVE_TITLE_BY_TYPE,
} from 'src/constants/phase.constants'
import {
    formatValueToNumberOnChange,
    formatValueToNumber,
} from 'src/utils/inputsUtilities'
import { setOptionsValidationsByType } from './../helpers/stagesFieldValidatios'

const { Option } = Select

const StageEditForm = ({ initialValues = {}, onSave, onCancel }) => {
    const handleSubmit = useCallback(({ size, ...values }) => {
        onSave({ ...values, size: numeral(size).format('0.00') })
    }, [])

    const $initialValues = {
        /**
         * Action type to take when condition is met positive
         */
        actionSecondary: null,
        /**
         * Action type to take when condition is met negative
         */
        action: 'Leverage',

        /**
         * Condition to check for positive
         */
        conditionSecondary: null,
        /**
         * Condition to check for negative
         */
        condition: '50',

        /**
         * Size to take when condition is met positive
         * @type {string}
         */
        sizeSecondary: null,

        /**
         * Size to take when condition is met negative
         */
        size: '50',

        /**
         * Take profit to take when condition is met positive
         */
        takeProfitSecondary: null,

        /**
         * Take profit to take when condition is met negative
         */
        takeProfit: '100',
        ...initialValues,
    }
    const formik = useFormik({
        initialValues: $initialValues,
        onSubmit: handleSubmit,
    })

    const { buttonSize, inputSize } = useResponsiveValue({
        base: {
            buttonSize: 'small',
            inputSize: 'small',
        },
        xl: {
            buttonSize: 'normal',
            inputSize: 'middle',
        },
    })

    console.log($initialValues)
    return (
        <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col py-2 lg:py-6 px-3 lg:px-8"
        >
            <div className="flex flex-col md:flex-row gap-3 lg:gap-8">
                <div className="flex-1">
                    <div className="text-xs tracking-wide mb-2">
                        Action to take
                    </div>
                    <Select
                        className="w-full"
                        value={formik.values?.action}
                        onChange={(v) => {
                            formik.setValues({
                                ...formik.values,
                                action: v,
                            })
                        }}
                        size={inputSize}
                    >
                        {PHASE_TYPES_ARR.map((type) => (
                            <Option key={`option-${type}`} value={type}>
                                {TITLE_BY_TYPE[type]}
                            </Option>
                        ))}
                    </Select>
                    {/* <FormikAntInput formik={formik} /> */}
                </div>
                <div className="flex-1">
                    <div className="text-xs tracking-wide mb-2">
                        {CONDITION_NEGATIVE_TITLE_BY_TYPE[
                            formik.values?.action
                        ] || CONDITION_NEGATIVE_TITLE_BY_TYPE.DEFAULT}{' '}
                        %
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="condition"
                        showError={false}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 2,
                                enableEmptyValue: true,
                                min: 0,
                                max: 100,
                            })
                            formik.setValues({
                                ...formik.values,
                                [name]: $value,
                            })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (value === '0') {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '10',
                                })
                            } else {
                                const $value = formatValueToNumber(value, '10')

                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }
                        }}
                    />
                </div>
                <div className="flex-1">
                    <div className="flex flex-row">
                        <div className="text-xs tracking-wide mb-2">
                            {FIELD_TITLE_BY_TYPE[formik.values?.action]}
                        </div>
                        <Tooltip title={TOOLTIP_MESSAGE[formik.values?.action]}>
                            <AiOutlineQuestionCircle className="ml-1" />
                        </Tooltip>
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="size"
                        showError={false}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(
                                value,
                                setOptionsValidationsByType(
                                    formik.values?.action
                                )
                            )
                            formik.setValues({
                                ...formik.values,
                                [name]: $value,
                            })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (value === '0') {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '10',
                                })
                            } else {
                                const $value = formatValueToNumber(value, '10')

                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }
                        }}
                    />
                </div>
                <div className="flex-1">
                    <div className="text-xs tracking-wide mb-2">
                        Take Profit %
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="takeProfit"
                        showError={false}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 4,
                                min: 0,
                                enableEmptyValue: true,
                            })

                            formik.setValues({
                                ...formik.values,
                                [name]: $value,
                            })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (value === '0') {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '10',
                                })
                            } else {
                                const $value = formatValueToNumber(value, '10')

                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col md:flex-row gap-3 lg:gap-8 mt-4">
                <div className="flex-1">
                    <div className="text-xs tracking-wide mb-2">
                        Secondary Action to take
                    </div>
                    <Select
                        className="w-full"
                        value={formik.values?.actionSecondary}
                        onChange={(v) => {
                            let $values = {
                                ...formik.values,
                                actionSecondary: v,
                            }
                            if (v === null) {
                                /**
                                    Reset secondary values
                                */
                                $values = {
                                    ...$values,
                                    conditionSecondary: null,
                                    takeProfitSecondary: null,
                                    sizeSecondary: null,
                                }
                            } else {
                                /**
                                    Set secondary values
                                */
                                $values = {
                                    ...$values,
                                    conditionSecondary: '10',
                                    takeProfitSecondary: '10',
                                    sizeSecondary: '10',
                                }
                            }
                            formik.setValues($values)
                        }}
                        size={inputSize}
                    >
                        <Option value={PHASE_TYPES.EMPTY}>
                            {TITLE_BY_TYPE[PHASE_TYPES.EMPTY]}
                        </Option>
                        <Option value={PHASE_TYPES.CLOSE_PARTIAL}>
                            {TITLE_BY_TYPE[PHASE_TYPES.CLOSE_PARTIAL]}
                        </Option>
                    </Select>
                </div>
                <div className="flex-1">
                    <div className="text-xs tracking-wide mb-2">
                        Secondary{' '}
                        {CONDITION_POSITIVE_TITLE_BY_TYPE[
                            formik.values?.actionSecondary
                        ] || CONDITION_POSITIVE_TITLE_BY_TYPE.DEFAULT}{' '}
                        %
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="conditionSecondary"
                        showError={false}
                        disabled={formik.values?.actionSecondary === null}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 2,
                                enableEmptyValue: true,
                                min: 0,
                                max: 100,
                            })
                            formik.setValues({
                                ...formik.values,
                                [name]: $value,
                            })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (formik.values?.actionSecondary) {
                                if (value === '0') {
                                    formik.setValues({
                                        ...formik.values,
                                        [name]: '10',
                                    })
                                } else {
                                    const $value = formatValueToNumber(
                                        value,
                                        '10'
                                    )

                                    formik.setValues({
                                        ...formik.values,
                                        [name]: $value,
                                    })
                                }
                            }
                        }}
                    />
                </div>
                <div className="flex-1">
                    <div className="flex flex-row">
                        <div className="text-xs tracking-wide mb-2">
                            Secondary{' '}
                            {
                                FIELD_TITLE_BY_TYPE[
                                    formik.values?.actionSecondary
                                ]
                            }
                        </div>
                        <Tooltip
                            title={
                                TOOLTIP_MESSAGE[formik.values?.actionSecondary]
                            }
                        >
                            <AiOutlineQuestionCircle className="ml-1" />
                        </Tooltip>
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="sizeSecondary"
                        showError={false}
                        disabled={formik.values?.actionSecondary === null}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(
                                value,
                                setOptionsValidationsByType(
                                    formik.values?.action
                                )
                            )
                            formik.setValues({
                                ...formik.values,
                                [name]: $value,
                            })
                        }}
                        onBlur={(e) => {
                            const { value, name } = e.target

                            formik.setTouched({ [name]: true })
                            if (formik.values?.actionSecondary) {
                                if (value === '0') {
                                    formik.setValues({
                                        ...formik.values,
                                        [name]: '10',
                                    })
                                } else {
                                    const $value = formatValueToNumber(
                                        value,
                                        '10'
                                    )

                                    formik.setValues({
                                        ...formik.values,
                                        [name]: $value,
                                    })
                                }
                            }
                        }}
                    />
                </div>
                <div className="flex-1">
                    <div className="text-xs tracking-wide mb-2">
                        Secondary Take Profit %
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="takeProfitSecondary"
                        showError={false}
                        disabled={formik.values?.actionSecondary === null}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 4,
                                min: 0,
                                enableEmptyValue: true,
                            })
                            formik.setValues({
                                ...formik.values,
                                [name]: $value,
                            })
                        }}
                        onBlur={(e) => {
                            const { value, name } = e.target

                            formik.setTouched({ [name]: true })
                            if (formik.values?.actionSecondary) {
                                if (value === '0') {
                                    formik.setValues({
                                        ...formik.values,
                                        [name]: '10',
                                    })
                                } else {
                                    const $value = formatValueToNumber(
                                        value,
                                        '10'
                                    )

                                    formik.setValues({
                                        ...formik.values,
                                        [name]: $value,
                                    })
                                }
                            }
                        }}
                    />
                </div>
            </div>
            <div className="ml-auto flex gap-2 mt-5">
                <Button
                    size={buttonSize}
                    type="submit"
                    className="w-20 lg:w-24 h-8"
                >
                    Accept
                </Button>
                <Button
                    size={buttonSize}
                    onClick={onCancel}
                    variant="light"
                    className="w-20 lg:w-24 h-8"
                >
                    Cancel
                </Button>
            </div>
        </form>
    )
}

const StageEdit = ({ isNew, initialValues, onSave, onCancel }) => {
    return (
        <div className="rounded-md relative border-experts-gray-2 bg-white shadow-experts-lg overflow-hidden">
            <div className="py-1 px-3 lg:px-8 rounded-md bg-black ">
                <div className="font-semibold text-white tracking-widest text-sm lg:text-base">
                    {isNew ? 'New Stage' : 'Edit Stage'}
                </div>
            </div>
            <StageEditForm
                onSave={onSave}
                onCancel={onCancel}
                initialValues={initialValues}
            />
        </div>
    )
}

export default StageEdit
