import React from 'react'
import Icon from 'src/components/Icons/Icon'
import GenericModal from './GenericModal'

const AlertModal = ({ visible, onClose, detail }) => {
    return (
        <GenericModal
            centered
            visible={visible}
            onClose={onClose}
            footer={null}
            width={526}
            buttonText="OK"
        >
            <div className="-mt-14 -mx-6 bg-experts-yellow-5 py-">
                <div className="flex flex-col items-center py-5">
                    <Icon name="alert" className="h-14 w-14" />
                    <div className="text-xl text-experts-gray-6 font-semibold mt-1 tracking-widest">
                        Oops!
                    </div>
                </div>
            </div>
            <div className="text-center text-lg mt-2 px-2 text-experts-gray-6 tracking-wide">
                {detail}
            </div>
        </GenericModal>
    )
}

export default AlertModal
