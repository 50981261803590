import React, { useCallback, useEffect } from 'react'
import { Select } from 'antd'
import numeral from 'numeral'
import { useFormik } from 'formik'
import CustomModal from './../../../Modals/GenericModals/CustomModal'
import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'
// import { numberValidation } from 'src/service/inputValidations'
import {
    CONDITION_NEGATIVE_TITLE_BY_TYPE,
    FIELD_TITLE_BY_TYPE,
    HEDGING,
    PHASE_TYPES_ARR,
    TITLE_BY_TYPE,
} from 'src/constants/phase.constants'
import * as yup from 'yup'
import {
    formatValueToNumber,
    formatValueToNumberOnChange,
} from 'src/utils/inputsUtilities'
import { setOptionsValidationsByType } from '../helpers/stagesFieldValidatios'

const { Option } = Select

const schema = yup.object().shape({
    condition: yup
        .number()
        .moreThan(0, 'Must be greater than 0')
        .required('Required'),
    size: yup
        .number()
        .moreThan(0, 'Must be greater than 0')
        .required('Required'),
    takeProfit: yup
        .string()
        .required('Required')
        .when('action', (action) => {
            if (action === HEDGING) {
                return yup.string().equals(['N/A'])
            } else {
                return yup
                    .number()
                    .moreThan(0, 'Must be greater than 0')
                    .required('Required')
            }
        }),
})

const StageEditMobileModal = ({
    initialValues = {
        // name: '',
        action: 'Leverage',
        condition: '50',
        size: '50',
        takeProfit: '100',
    },
    onCancel,
    onSave,
    loading,
    index,
    ...props
}) => {
    const handleSubmit = useCallback(
        ({ size, ...values }) => {
            onSave(index, { ...values, size: numeral(size).format('0.00') })
        },
        [index]
    )

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {    
            ...initialValues,
            takeProfit: initialValues.action === HEDGING ? 'N/A' : initialValues.takeProfit,
        },
        onSubmit: handleSubmit,
        validationSchema: schema,
    })

    useEffect(() => {
        if (initialValues) formik.resetForm(initialValues)
    }, [initialValues])

    return (
        <CustomModal
            {...props}
            loading={loading}
            onCancel={onCancel}
            onOk={() => {
                formik.submitForm()
            }}
            width={380}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="space-y-4">
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            Action to take
                        </div>
                        <Select
                            className="w-full"
                            value={formik.values?.action}
                            onChange={(v) => {
                                const newState = {
                                    ...formik.values,
                                    action: v,
                                }
                                if (v === HEDGING) {
                                    newState.takeProfit = 'N/A'
                                } else if (
                                    formik.values?.takeProfit === 'N/A'
                                ) {
                                    newState.takeProfit = '100'
                                }
                                formik.setValues(newState)
                            }}
                            size={'small'}
                        >
                            {PHASE_TYPES_ARR.map((type) => (
                                <Option key={`option-${type}`} value={type}>
                                    {TITLE_BY_TYPE[type]}
                                </Option>
                            ))}
                        </Select>
                        {/* <FormikAntInput formik={formik} /> */}
                    </div>{' '}
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            {CONDITION_NEGATIVE_TITLE_BY_TYPE[
                                formik.values?.action
                            ] || CONDITION_NEGATIVE_TITLE_BY_TYPE.DEFAULT}{' '}
                            %
                        </div>
                        <FormikAntInput
                            formik={formik}
                            name="condition"
                            onChange={(e) => {
                                const {
                                    target: { value, name },
                                } = e
                                const $value = formatValueToNumberOnChange(
                                    value,
                                    {
                                        decimals: 2,
                                    }
                                )

                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }}
                            onBlur={(e) => {
                                const { name, value } = e.target

                                formik.setTouched({ [name]: true })
                                if (value === '0') {
                                    formik.setValues({
                                        ...formik.values,
                                        [name]: '10',
                                    })
                                } else {
                                    const $value = formatValueToNumber(
                                        value,
                                        '10'
                                    )

                                    formik.setValues({
                                        ...formik.values,
                                        [name]: $value,
                                    })
                                }
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            {FIELD_TITLE_BY_TYPE[formik.values?.action]}{' '}
                        </div>
                        <FormikAntInput
                            formik={formik}
                            name="size"
                            onChange={(e) => {
                                const {
                                    target: { value, name },
                                } = e
                                const $value = formatValueToNumberOnChange(
                                    value,
                                    setOptionsValidationsByType(
                                        formik.values?.action
                                    )
                                )
                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }}
                            onBlur={(e) => {
                                const { name, value } = e.target

                                formik.setTouched({ [name]: true })
                                if (value === '0') {
                                    formik.setValues({
                                        ...formik.values,
                                        [name]: '10',
                                    })
                                } else {
                                    const $value = formatValueToNumber(
                                        value,
                                        '10'
                                    )

                                    formik.setValues({
                                        ...formik.values,
                                        [name]: $value,
                                    })
                                }
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            Take Profit %
                        </div>
                        <FormikAntInput
                            formik={formik}
                            name="takeProfit"
                            disabled={formik.values?.action === HEDGING}
                            value={
                                formik.values?.action === HEDGING
                                    ? 'N/A'
                                    : formik.values?.takeProfit
                            }
                            onChange={(e) => {
                                const {
                                    target: { value, name },
                                } = e
                                const $value = formatValueToNumberOnChange(
                                    value,
                                    {
                                        decimals: 4,
                                        min: 0,
                                        enableEmptyValue: true,
                                    }
                                )

                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }}
                            onBlur={(e) => {
                                const { name, value } = e.target

                                formik.setTouched({ [name]: true })
                                if (value === '0') {
                                    formik.setValues({
                                        ...formik.values,
                                        [name]: '10',
                                    })
                                } else {
                                    const $value = formatValueToNumber(
                                        value,
                                        '10'
                                    )

                                    formik.setValues({
                                        ...formik.values,
                                        [name]: $value,
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
            </form>
        </CustomModal>
    )
}

export default StageEditMobileModal
