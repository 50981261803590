import React, { useCallback } from 'react'
import CustomModal from 'src/components/Modals/GenericModals/CustomModal'
import { useRemoveStrategy } from './../../../store/reducers/strategies/strategiesReducer'

const RemoveStrategyModal = ({ visible = false, onClose, data }) => {
    const { removeStrategy, loading } = useRemoveStrategy()

    const handleClose = () => {
        onClose()
    }

    const { strategyId, name } = data

    const handleOkay = useCallback(async () => {
        removeStrategy(strategyId).then(() => {
            handleClose()
        })
    }, [strategyId, name, removeStrategy])

    return (
        <CustomModal
            loading={loading}
            visible={visible}
            title="Remove Strategy"
            width={450}
            onCancel={handleClose}
            buttonOkText="Remove"
            onOk={() => handleOkay()}
        >
            <div className="text-center">
                <div className="text-lg">
                    Are you sure you want to remove this strategy?
                </div>
                <div className="font-semibold text-3xl mt-2">{name}</div>
            </div>
        </CustomModal>
    )
}

export default RemoveStrategyModal
