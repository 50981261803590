import React, { useCallback, useRef } from 'react'

import { useUpdateStrategy } from '../../../store/reducers/strategies/strategiesReducer'
import CustomModal from 'src/components/Modals/GenericModals/CustomModal'
import FormStrategy from './FormStrategy'

const UpdateStrategyModal = ({ visible = false, onClose, data }) => {
    const { updateStrategy, loading } = useUpdateStrategy()
    const formRef = useRef(null)

    const { strategyId, ...restData } = data

    const handleClose = () => {
        onClose()
    }

    const handleSubmit = useCallback(
        async ({
            name,
            leverage,
            takeProfit,
            side,
            tradeBalances,
            tradePercentages,
            leverages,
            ratioStrategy,
            symbols,
        }) => {
            updateStrategy(
                strategyId,
                name,
                side,
                ratioStrategy ? leverages : [Number(leverage)],
                takeProfit,
                tradeBalances,
                tradePercentages,
                Math.max(...tradeBalances),
                ratioStrategy,
                symbols
            ).then(() => {
                handleClose()
            })
        },
        [strategyId]
    )

    const handleOkay = useCallback(() => {
        formRef.current.submitForm()
    }, [])
    return (
        <CustomModal
            loading={loading}
            visible={visible}
            title={'Update Strategy'}
            width={750}
            onCancel={handleClose}
            onOk={handleOkay}
        >
            <FormStrategy
                ref={formRef}
                onSubmit={handleSubmit}
                data={restData}
                enableReinitialize
            />
        </CustomModal>
    )
}

export default UpdateStrategyModal
