import React, { useState } from 'react'
import { Input } from 'antd'
import CapsuleList from './CapsuleList'
import { numberValidation } from 'src/service/inputValidations'

const CapsuleField = ({ name, onChange, onDelete, capsules = [] }) => {
    const [inputValue, setInputValue] = useState()

    const handleOnKeyDown = (e) => {
        const {
            key,
            target: { value },
        } = e
        if (key === 'Enter' && value) {
            if (capsules.indexOf(Number(value)) !== -1) {
                return window.alert("You can't set a repeatedValue")
            }
            if (value === '0') {
                return window.alert("You can't set a value of zero")
            }
            onChange(Number(value))
            setInputValue(null)
        }
    }

    return (
        <div className="flex flex-wrap items-center gap-3">
            <CapsuleList list={capsules} name={name} onDelete={onDelete} />
            <Input
                id={name}
                name={name}
                className="w-20"
                onKeyDown={handleOnKeyDown}
                onChange={(e) => {
                    const {
                        target: { value },
                    } = e
                    const _value = numberValidation(value, 2, 0, 100)
                    if (typeof _value !== 'undefined')
                        setInputValue(_value === '0' ? '' : _value)
                }}
                value={inputValue}
            />
        </div>
    )
}

export default CapsuleField
