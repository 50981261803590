import { Switch } from 'antd'
import Button from 'src/components/Buttons/Button'
import React, { useMemo, useState } from 'react'
// import TurnOffMMStrategyModal from './TurnOffMMStrategyModal'
import ActiveMMStrategyModal from './ActiveMMStrategyModal'
import useResponsiveValue from './../../hooks/useResponsiveValue'
import { useUserInfo } from './../../store/reducers/userInfoReducer/userInfoReducer'
import { useActivateStrategy } from 'src/store/reducers/strategies/strategiesReducer'
import { useDeactivateStrategy } from './../../store/reducers/strategies/strategiesReducer'
import Icon from '../Icons/Icon'

const messages = {
    enable: 'To enable or disable an strategy you require admin permission.',
    alreadyActive: 'You already have an active strategy for this side.',
    active: 'To edit an active strategy you require admin permission.',
    remove: 'To remove an active strategy you require to disable it.',
}

const InfoStage = ({
    configData,
    onAddStrategy,
    editedStage,
    strategies = [],
    onReset,
    onCreateStrategy,
    onEditStrategy,
    onDelete,
    onClickCalculator,
}) => {
    const [
        modal,
        // {
        //     // enableMMModal,
        //     // activeStrategyModal,
        //     // alreadyActive,
        //     // removeStrategyModal,
        // }
        setVisibleModal,
    ] = useState(null)
    // {
    //     enableMMModal: false,
    //     activeStrategyModal: false,
    //     alreadyActive: false,
    //     removeStrategyModal: false,
    // }
    const { isSuperuser } = useUserInfo()

    const {
        active,
        name,
        id,
        side,
        initialLeverage,
        initialTP,
        ratioStrategy,
    } = configData ?? {}

    const { activate, loading: activating } = useActivateStrategy()
    const { deactivate, loading: deactivating } = useDeactivateStrategy()

    const { buttonSize, switchSize } = useResponsiveValue({
        base: {
            buttonSize: 'small',
            switchSize: 'default',
        },
        lg: {
            buttonSize: 'normal',
            switchSize: 'small',
        },
    })

    console.log('strategies', ratioStrategy)
    const anotherStrategyIsActive = useMemo(() => {
        return (
            strategies.filter((s) => s.id !== id && s.active && s.side === side)
                .length > 0
        )
    }, [strategies, id, side])

    return (
        <React.Fragment>
            <ActiveMMStrategyModal
                visible={!!modal}
                strategyName={name}
                message={messages?.[modal]}
                onClose={() => setVisibleModal(null)}
            />
            <div className="flex flex-col lg:flex-row">
                {configData && (
                    <div className="flex flex-col lg:flex-row">
                        <div className="flex flex-row">
                            <div className="mr-3 lg:mr-5">
                                <div className="text-lg font-bold text-experts-gray-7 tracking-widest min-w-fit">
                                    {name}
                                </div>

                                <div
                                    className={`hidden lg:block mt-auto text-sm font-semibold tracking-wider rounded-full w-16 text-center border ${
                                        side === 'Buy'
                                            ? 'text-experts-green-2 bg-experts-green-4 border-experts-green-6'
                                            : 'text-experts-red-2 bg-experts-red-1 border-experts-red-11'
                                    }`}
                                >
                                    {side === 'Buy' ? 'Long' : 'Short'}
                                </div>
                            </div>{' '}
                            <Switch
                                className="relative w-14 lg:w-16 ml-auto lg:ml-0 lg:mt-1"
                                size={switchSize}
                                loading={activating || deactivating}
                                onChange={() => {
                                    if (!anotherStrategyIsActive) {
                                        if (isSuperuser) {
                                            if (active) activate(id)
                                            else deactivate(id)
                                        } else {
                                            setVisibleModal('enable')
                                        }
                                    } else {
                                        setVisibleModal('alreadyActive')
                                    }
                                }}
                                checked={active}
                            />
                        </div>
                        <div className="flex justify-between lg:justify-start lg:space-x-8 mt-4 lg:mt-0">
                            <div
                                className={`block lg:hidden mt-auto text-xs font-semibold tracking-wider rounded-full w-16 text-center border ${
                                    side === 'Buy'
                                        ? 'text-experts-green-2 bg-experts-green-4 border-experts-green-6'
                                        : 'text-experts-red-2 bg-experts-red-1 border-experts-red-11'
                                }`}
                            >
                                {side === 'Buy' ? 'Long' : 'Short'}
                            </div>
                            <div>
                                <div className="text-[10px] font-semibold lg:text-base text-experts-gray-6 tracking-widest min-w-fit">
                                    Initial Leverage
                                </div>
                                <div className="text-sm lg:text-lg text-black font-semibold">
                                    {initialLeverage}
                                </div>
                            </div>
                            <div>
                                {' '}
                                <div className="text-[10px] font-semibold lg:text-base text-experts-gray-6 tracking-widest min-w-fit">
                                    Initial Take Profit
                                </div>
                                <div className="text-sm lg:text-lg text-black font-semibold">
                                    {initialTP}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="md:ml-auto w-full lg:w-auto flex space-x-4 lg:space-x-6 mt-4 lg:mt-0">
                    {editedStage && (
                        <Button
                            size={buttonSize}
                            onClick={onReset}
                            variant="light"
                            className="w-full lg:w-auto text-sm lg:text-lg"
                        >
                            Reset
                        </Button>
                    )}
                    {configData && (
                        <Button
                            size={buttonSize}
                            onClick={() => {
                                if (active && !isSuperuser)
                                    setVisibleModal('active')
                                else onEditStrategy()
                            }}
                            className="w-full lg:w-auto text-sm lg:text-lg"
                            variant="light"
                        >
                            <Icon
                                name="3-edit"
                                className="h-[18px] w-[18px] mr-3 text-experts-green-7"
                            />
                            <div className="">Edit</div>
                        </Button>
                    )}
                    {configData && (
                        <Button
                            size={buttonSize}
                            onClick={() => {
                                if (active) setVisibleModal('remove')
                                else onDelete(id)
                            }}
                            className="w-full lg:w-auto text-sm lg:text-lg"
                            variant="light"
                        >
                            <Icon
                                name="3-trash"
                                className="h-[18px] w-[18px] mr-3"
                            />
                            <div className="">Delete</div>
                        </Button>
                    )}
                    <Button
                        size={buttonSize}
                        onClick={onAddStrategy}
                        variant="gradient"
                        className="w-full lg:w-auto text-sm lg:text-lg"
                    >
                        <Icon name="3-new" className="h-[18px] w-[18px] mr-3" />
                        <div className="">New</div>
                    </Button>
                    <Button
                        onClick={onClickCalculator}
                        size={buttonSize}
                        className="px-3"
                    >
                        <Icon
                            name="3-calculate"
                            className="h-[18px] w-[18px]"
                        />
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default InfoStage
