import classNames from 'classnames'

const Tabs = ({ menus, selected, onSelect }) => {
    return (
        <div className="-mx-5 md:mx-0 flex">
            <div className="w-full flex flex-row  text-experts-gray-6 border-experts-gray-4 border-b px-2 gap-7 overflow-x-auto overflow-y-hidden">
                {menus.map((menu) => (
                    <div
                        key={`tab-menu-${menu.id}`}
                        className={classNames(
                            "min-w-fit cursor-pointer  relative flex text-xs xl:text-base  px-4 lg:px-6 box-border before:content-[''] h-7 xl:h-[34px]",
                            {
                                'before:absolute before:-bottom-[1px] before:left-0 before:right-0 font-bold before:h-[3px] before:bg-experts-green-2':
                                    menu.id === selected,
                            }
                        )}
                        onClick={() => onSelect(menu.id)}
                    >
                        {menu.name}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Tabs
