import React, { useState } from 'react'
import Button from 'src/components/Buttons/Button'
import Icon from './../../Icons/Icon'
import useResponsiveValue from 'src/hooks/useResponsiveValue'
import TradersList from './TradersList'
import AddTraderModal from './AddTraderModal/index'
import {
    useAdmin,
    useFetchTraders,
} from './../../../store/reducers/adminReducer/adminReducer'

const TradersPage = () => {
    const [visible, setVisible] = useState(false)

    const buttonSize = useResponsiveValue({
        base: 'small',
        lg: 'normal',
    })

    useFetchTraders()
    const { traders } = useAdmin()

    return (
        <div className="w-full h-full">
            <div className="flex flex-row items-end justify-between">
                <div className="text-lg font-bold">Traders</div>
                <Button
                    size={buttonSize}
                    onClick={() => setVisible(true)}
                    variant="gradient"
                    className="w-full lg:w-auto text-sm lg:text-lg"
                >
                    <Icon
                        name="3-new"
                        className="h-[18px] w-[18px] mr-3 text-white"
                    />
                    <div className="">Add New Trader</div>
                </Button>
            </div>
            <AddTraderModal
                visible={visible}
                onClose={() => setVisible(false)}
            />
            <TradersList traders={traders} />
        </div>
    )
}

export default TradersPage
