/* eslint-disable no-unused-vars */
import React from 'react'
import { Popconfirm, Switch, Table as TableAntd } from 'antd'
import { formatMoneyNumber } from 'src/utils/formatter'
import { HEDGING, MESSAGE_BY_ACTION } from 'src/constants/phase.constants'
import Icon from 'src/components/Icons/Icon'
import { AiOutlineStop } from 'react-icons/ai'
import Button from 'src/components/Buttons/Button'
import { useTradesReducerState } from 'src/store/reducers/tradesReducer/trades.hook'

const ExpandedRowRender = ({
    record = {},
    pending,
    disabled,
    onActiveMM,
    onDeactiveMM,
    onEditSlTp,
    onClose,
}) => {
    // const inversedTradeId = React.useMemo(() => {
    //     const hasUndefined = Object.values(record.trades).some(
    //         (trade) => trade === undefined
    //     )
    //     if (hasUndefined) return undefined
    //     return Object.values(record.trades).reduce((acc, trade) => {
    //         if (!acc || (trade && trade.id < acc)) return trade.id
    //         return acc
    //     }, undefined)
    // }, [record])

    const isHedgingActive = React.useMemo(() => {
        return Object.values(record.trades).every(
            (trade) => trade !== undefined
        )
    }, [record.trades])

    return (
        <div className="flex flex-col">
            <table className="table-auto w-full">
                <thead>
                    <tr className="first:rounded-tl-md last:rounded-tr-md child:text-center">
                        <th className="px-4 py-2 border">Type</th>
                        <th className="px-4 py-2 border">Quantity</th>
                        <th className="px-4 py-2 border">Used Balance</th>
                        <th className="px-4 py-2 border">P&L (ROE)</th>
                        <th className="px-4 py-2 border">Take Profit</th>
                        <th className="px-4 py-2 border">Position Value</th>
                        <th className="px-4 py-2 border">Entry Price</th>
                        <th className="px-4 py-2 border">Liquidation Price</th>
                        <th className="px-4 py-2 border">Stage</th>
                        <th className="px-4 py-2 border">Next Stage Info</th>
                        <th className="px-4 py-2 border">SL/TP</th>
                        <th className="px-4 py-2 border">Money Management</th>
                        <th className="px-4 py-2 border">Close Market</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(record?.trades).map((key) => {
                        const trade = record?.trades?.[key]
                        console.log('trade', trade)
                        // Original trade is the trade that is not inversed this is the trade with the lower id

                        if (!trade)
                            return (
                                <tr>
                                    <td
                                        className="border px-4 py-2"
                                        colSpan={13}
                                    >
                                        {/* Message to show tell user that there is still no inversed trade */}
                                        N/A
                                    </td>
                                </tr>
                            )
                        return (
                            <tr key={key} className="child:text-center">
                                <td className="border px-4 py-2">
                                    <div className="flex flex-col">
                                        {key === 'Long' ? (
                                            <span className="text-green-600 font-bold tracking-wider text-sm">
                                                Long
                                            </span>
                                        ) : (
                                            <span className="text-experts-red-2 font-bold tracking-wider text-sm">
                                                Short
                                            </span>
                                        )}
                                        <span
                                            className={`text-experts-yellow-3 font-bold text-sm`}
                                        >
                                            {trade.leverage}x{' '}
                                            {trade.isolated
                                                ? 'Isolated'
                                                : 'Cross'}
                                        </span>
                                        {/* {inversedTradeId === trade.id && (
                                            <span className="text-experts-yellow-3 font-bold text-sm">
                                                Inversed
                                            </span>
                                        )} */}
                                    </div>
                                </td>
                                <td className="border px-4 py-2">
                                    {trade?.quantity}
                                </td>
                                <td className="border px-4 py-2">
                                    {trade?.balancepercentage}%
                                </td>
                                <td className="border px-4 py-2">
                                    {' '}
                                    <span className="flex flex-col">
                                        <span
                                            className={
                                                Number(trade.pnl) >= 0
                                                    ? 'text-green-600'
                                                    : 'text-experts-red-2'
                                            }
                                        >
                                            {Number(trade.pnl)}%
                                        </span>
                                        <span
                                            className={
                                                Number(trade.pnl) >= 0
                                                    ? 'text-green-600'
                                                    : 'text-experts-red-2'
                                            }
                                        >
                                            {formatMoneyNumber(
                                                Number(trade.pnlusd)
                                            )}
                                        </span>
                                    </span>
                                </td>
                                <td className="border px-4 py-2">
                                    {!trade.isMM || isHedgingActive ? (
                                        'N/A'
                                    ) : (
                                        <div className="">
                                            <div className="text-green-600 font-bold tracking-wider">
                                                {trade.takeProfitPercent} %
                                            </div>
                                            <div className="text-green-600 font-bold tracking-wider">
                                                ±{' '}
                                                {formatMoneyNumber(
                                                    trade.estimateProfit
                                                )}
                                            </div>
                                            <div className="text-black font-bold tracking-wider">
                                                (At:{' '}
                                                {formatMoneyNumber(
                                                    trade.takeProfitTrigger
                                                )}
                                                )
                                            </div>
                                        </div>
                                    )}
                                </td>
                                <td className="border px-4 py-2 text-experts-green-2 font-bold tracking-wider">
                                    {formatMoneyNumber(trade.positionValue)}
                                </td>
                                <td className="border px-4 py-2">
                                    {formatMoneyNumber(trade.entryprice)}
                                </td>
                                <td className="border px-4 py-2 text-experts-red-2 font-bold tracking-wider">
                                    {formatMoneyNumber(trade.liqPrice)}
                                </td>
                                <td className="border px-4 py-2">
                                    {!trade.isMM || isHedgingActive
                                        ? 'N/A'
                                        : trade.phase}
                                </td>
                                <td className="border px-4 py-2">
                                    {!trade.isMM || isHedgingActive ? (
                                        'N/A'
                                    ) : (
                                        <span className="flex flex-col">
                                            <span className="mb-1">
                                                {
                                                    MESSAGE_BY_ACTION[
                                                        trade.nextPhaseAction
                                                    ]
                                                }
                                            </span>
                                            <span>
                                                (At{' '}
                                                {formatMoneyNumber(
                                                    trade.nextPhaseSE
                                                )}
                                                )
                                            </span>
                                        </span>
                                    )}
                                </td>
                                <td className="border px-4 py-2">
                                    <div className="flex flex-row justify-center text-black">
                                        <span className="mr-2">
                                            {formatMoneyNumber(trade.sl)}/
                                            {formatMoneyNumber(trade.tp)}
                                        </span>
                                        {/* {enableEditSlTP && ( */}
                                        <button
                                            onClick={() => onEditSlTp(trade)}
                                        >
                                            <Icon
                                                name="3-edit"
                                                className="h-4 w-4 hover:text-experts-green-1 delay-150 duration-300"
                                            />
                                        </button>
                                        )
                                    </div>
                                </td>
                                <td className="border px-4 py-2 text-center align-middle">
                                    <Switch
                                        loading={pending}
                                        disabled={
                                            pending ||
                                            disabled ||
                                            (trade.isRatio &&
                                                trade.tp === '0') ||
                                            isHedgingActive
                                        }
                                        className="w-[74px] "
                                        size="small"
                                        onChange={() => {
                                            if (!trade.isMM) {
                                                onActiveMM(trade)
                                            } else {
                                                onDeactiveMM(trade)
                                            }
                                            // onAction(record)
                                        }}
                                        checked={trade.isMM && !isHedgingActive}
                                    />
                                </td>
                                <td className="border px-4 py-2 text-center align-middle">
                                    <Popconfirm
                                        placement="top"
                                        title="Are you sure you want to close this position?"
                                        onConfirm={() =>
                                            onClose({
                                                // orderId: record.order_id,
                                                symbol: trade.symbol,
                                            })
                                        }
                                    >
                                        <Button
                                            disabled={pending || disabled || isHedgingActive}
                                            size="small"
                                            variant="lightDanger"
                                            className="w-9"
                                            loading={pending}
                                        >
                                            {!pending ? (
                                                <AiOutlineStop />
                                            ) : null}
                                        </Button>
                                    </Popconfirm>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

const HedgingTrades = ({
    enableEditSlTP,
    disabled,
    pending,
    onClose = () => {},
    onActiveMM = () => {},
    onDeactiveMM = () => {},
    onEditSlTp = () => {},
}) => {
    const { bybitTrades, tradesMoney, activeOrders } = useTradesReducerState()

    const trades = React.useMemo(() => {
        const $trades = []
        const setTrade = ({ trade, isMM }) => {
            const index = $trades.findIndex(
                (item) => item.symbol === trade.symbol
            )
            const { symbol, side, ...restByTrade } = trade

            const newTrade = {
                symbol,
                trades: {
                    Long: undefined,
                    Short: undefined,
                    ...($trades[index]?.trades ?? {}),
                },
            }

            const tp = activeOrders?.find(
                (order) =>
                    order.symbol === symbol &&
                    order.side !== side &&
                    order.reduce_only
            )?.price
            newTrade.trades[side === 'Buy' ? 'Long' : 'Short'] = {
                ...restByTrade,
                symbol,
                side,
                isMM,
                tp,
            }

            if (index === -1) return $trades.push(newTrade)
            $trades[index] = newTrade
        }

        bybitTrades.forEach((trade) => setTrade({ trade, isMM: false }))
        tradesMoney.forEach((trade) => setTrade({ trade, isMM: true }))

        return $trades
    }, [bybitTrades, tradesMoney, activeOrders])

    console.log('trades', trades)
    const columns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (text) => (
                <span className="text-black font-bold text-xs xl:text-base">
                    {text}
                </span>
            ),
        },
        {
            title: 'P&L (ROE)',
            dataIndex: 'pnl',
            key: 'pnl',
            render: (_, record) => {
                const { pnlUsd, pnl } = Object.values(record?.trades)?.reduce(
                    (acc, trade) => {
                        if (!trade) return acc
                        return {
                            ...acc,
                            pnl: acc.pnl + Number(trade.pnl),
                            pnlUsd: acc.pnlUsd + Number(trade.pnlusd),
                        }
                    },
                    {
                        pnl: 0,
                        pnlUsd: 0,
                    }
                )

                return (
                    <span className="flex flex-col">
                        <span
                            className={
                                Number(pnl) >= 0
                                    ? 'text-green-600'
                                    : 'text-experts-red-2'
                            }
                        >
                            {Number(pnl)}%
                        </span>
                        <span
                            className={
                                Number(pnl) >= 0
                                    ? 'text-green-600'
                                    : 'text-experts-red-2'
                            }
                        >
                            {formatMoneyNumber(Number(pnlUsd))}
                        </span>
                    </span>
                )
            },
        },
    ]

    return (
        <div>
            <TableAntd
                columns={columns}
                rowKey={(record) => record.symbol}
                expandable={{
                    expandRowByClick: true,
                    expandedRowRender: (record) => (
                        <ExpandedRowRender
                            record={record}
                            onActiveMM={onActiveMM}
                            onDeactiveMM={onDeactiveMM}
                            onEditSlTp={onEditSlTp}
                            onClose={onClose}
                            enableEditSlTP={enableEditSlTP}
                            disabled={disabled}
                            pending={pending}
                        />
                    ),
                    // rowExpandable: (record) => record.name !== 'Not Expandable',
                }}
                dataSource={trades}
            />
        </div>
    )
}

export default HedgingTrades
