import React from 'react'
import {
    useEmisors,
    useFetchKeysEmisor,
} from 'src/store/reducers/dashboardReducer/dashboardReducer'
import { Select } from 'antd'
import { useSetSelectedEmisor } from './../store/reducers/dashboardReducer/dashboardReducer'
// import useMediaQuery from 'src/hooks/useMediaQuery'
import useResponsiveValue from './../hooks/useResponsiveValue'

const { Option } = Select

const EmisorSelect = () => {
    useFetchKeysEmisor()
    const { userEmisors, emisorSelected } = useEmisors()
    const setSelectedEmisor = useSetSelectedEmisor()

    const selectSize = useResponsiveValue({ base: 'medium', xl: 'large' })
    return (
        <div className="custom-select">
            <Select
                size={selectSize}
                defaultValue="all"
                className="w-32 lg:w-64 text-left text-sm xl:text-lg mt-2"
                onChange={(v) => {
                    setSelectedEmisor(v)
                }}
                // bordered={false}
                value={emisorSelected}
            >
                {userEmisors.map((key, index) => {
                    return (
                        <Option key={`${key.name}`} value={key.id}>
                            {key.name} {key.shared ? '(Shared)' : ''}
                        </Option>
                    )
                })}
            </Select>
        </div>
    )
}

export default EmisorSelect
