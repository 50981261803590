import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as authReducer from 'src/store/reducers/auth/authReducer'
import { getUserExtraInfo } from './../store/reducers/userInfoReducer/userInfoReducer'

import { setAuth } from './../store/reducers/auth/authReducer'

const useAuth = () => {
    const dispatch = useDispatch()
    const login = useCallback(
        async (data) => {
            try {
                await dispatch(authReducer.login(data)).unwrap()
                const res = await dispatch(getUserExtraInfo()).unwrap()

                const { is_superuser: isSuperuser, is_staff: isStaff } = res
                if (isSuperuser) dispatch(setAuth())

                // const emisors = await dispatch(
                //     getKeyEmisors(Number(res.id))
                // ).unwrap()

                if (!isStaff)
                    throw new Error('Account is not a member of staff')
                dispatch(setAuth())
            } catch (error) {
                console.log(error)
                throw error
            }
        },
        [dispatch]
    )

    const logout = useCallback(
        (data) => dispatch(authReducer.logout(data)),
        [dispatch]
    )

    return { login, logout }
}

export default useAuth
