import { Navigate, Route, Routes } from 'react-router-dom'

import './styles/App.less'
import LogIn from './views/AuthView/login'
// import SignUp from './views/singup'

import LoggedLayout from './components/layouts/loggedLayout'
import React from 'react'

// import ScanQrCode from './views/Steps/ScanQrCode'
// import VerifyCode from './views/Steps/VerifyCode'

import { useIsAuth } from './store/reducers/auth/authReducer'

import ForgetPassword from './views/ForgetPassword'
import SendEmailForgotPassword from './views/SendEmailForgotPassword'
import ToggleView from './views/ToggleView'
import LogoutView from './views/logout'
import Profile from './views/Profile'
import TraderStatistics from './views/TraderStatistics'
// import LiveTrades from './views/LiveTrades'
import MyConfig from './views/MyConfigView'
// import AuthLayout from './views/AuthView/AuthLayout'
import PlaceTradeView from './views/PlaceTradeView'
// import LandingView from './views/LandingView'
// import NoLoggedLayout from 'src/components/layouts/noLoggedLayout'
import AuthLayout from 'src/components/layouts/AuthLayout'
import AdminView from './views/AdminView'
import withClearCache from './HOC/ClearCache'

function MainApp() {
    const isAuth = useIsAuth()

    return (
        <Routes>
            {isAuth ? (
                <Route path="/" element={<LoggedLayout />}>
                    {' '}
                    <Route
                        path="/"
                        element={<Navigate to={'toggle'} replace />}
                    />{' '}
                    <Route path="toggle" element={<ToggleView />} />
                    {/* <Route path="livetrades" element={<LiveTrades />} /> */}
                    <Route path="config" element={<MyConfig />} />
                    <Route
                        path="traderstatistics/:id"
                        element={<TraderStatistics />}
                    />
                    <Route path="trade" element={<PlaceTradeView />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="admin" element={<AdminView />} />
                    <Route path="logout" element={<LogoutView />} />
                </Route>
            ) : (
                <React.Fragment>
                    <Route path="/">
                        <Route index element={<Navigate to={'auth'} />} />
                        {/* <Route path="/" element={<NoLoggedLayout />}>
                            <Route index element={<LandingView />} />
                        </Route> */}
                        <Route path="auth" element={<AuthLayout />}>
                            <Route index element={<LogIn />} />

                            <Route path="forgotpassword">
                                <Route index element={<ForgetPassword />} />
                                <Route
                                    path="emailSended"
                                    element={<SendEmailForgotPassword />}
                                />
                            </Route>
                        </Route>
                        {/* <Route path="login">
                            <Route index element={<LogIn />} />
                            <Route path="2fa">
                                <Route path="verify" element={<VerifyCode />} />
                            </Route>
                        </Route> */}
                        {/* <Route path="register">
                            <Route index element={<SignUp />} />
                            <Route path="steps">
                                <Route
                                    index
                                    element={<Navigate to={'scan'} replace />}
                                />
                                <Route path="scan" element={<ScanQrCode />} />
                                <Route path="verify" element={<VerifyCode />} />
                            </Route>
                        </Route> */}
                        <Route path="forgotpassword">
                            <Route index element={<ForgetPassword />} />
                            <Route
                                path="emailSended"
                                element={<SendEmailForgotPassword />}
                            />
                        </Route>
                    </Route>
                </React.Fragment>
            )}

            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}

const ClearCacheComponent = withClearCache(MainApp)

function App() {
    return <ClearCacheComponent />
}

export default App
