/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useMemo } from 'react'
import {
    IoCopyOutline,
    IoTrashOutline,
    IoHelpCircleOutline,
} from 'react-icons/io5'
import * as yup from 'yup'
import { useFormik } from 'formik'
import FormikAntInputPassword from './../components/FormikAntDInputs/FormikAntInputPassword'
import PasswordUpdate from 'src/components/Modals/PasswordModals/PasswordUpdate'
import { validations } from './../service/yupValidations'
import { sleep } from './../utils/promise'
import { updatePassword } from './../store/reducers/auth/authReducer'
import { useDispatch } from 'react-redux'
import useAuth from './../hooks/useAuth'
import SomethingWentWrong from 'src/components/Modals/ErrorModals/SomethingWentWrong'
import { useUserInfo } from './../store/reducers/userInfoReducer/userInfoReducer'
import { shortenIfAddress } from '@usedapp/core'

const ProfileInfo = ({ userName, address, dateJoined }) => {
    const joinedDate = useMemo(() => {
        const date = new Date(dateJoined)
        return date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
        })
    }, [dateJoined])

    return (
        <div className="p-3 lg:p-5 bg-white rounded-md shadow-experts-lg relative">
            <div className="font-bold  text-[25px]">{userName}</div>
            <div className="flex flex-row mt-4 gap-32">
                <div className="">
                    {/* <div className="text-[10px]">UID:</div>
                    <div className="mt-1  font-bold text-xs w-32 h-[25px] px-3 flex items-end bg-[#f5f5f5]">
                        A6f5d4
                    </div> */}
                </div>
                {/* <div className="">
                    <div className="text-[10px]">Metamask address:</div>

                    <div className="mt-1  font-bold text-xs w-32 h-[25px] px-3 flex items-end bg-[#f5f5f5]">
                        {address && shortenIfAddress(address)}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="text-[10px]">Joined:</div>
                    <div className="mt-auto  font-bold text-xs w-32 h-[25px] flex items-end">
                        {joinedDate}
                    </div>
                </div> */}
            </div>
        </div>
    )
}

const UserPersonalData = ({ email, firstName, lastName }) => {
    return (
        <div className="p-3 lg:p-5 bg-white rounded-md shadow-experts-lg relative">
            <div className=" text-sm font-medium">PERSONAL INFO</div>{' '}
            <div className="flex flex-row mt-4 gap-32 pb-5 border-b ">
                <div className="">
                    <div className="text-[10px]">EMAIL:</div>
                    <div className="mt-1  font-bold text-xs w-32 h-[25px] flex items-end">
                        {email}
                    </div>
                </div>{' '}
                <div className="">
                    <div className="text-[10px]">FIRST NAME:</div>
                    <div className="mt-1  font-bold text-xs w-32 h-[25px] flex items-end">
                        {firstName}
                    </div>
                </div>{' '}
                <div className="">
                    <div className="text-[10px]">LAST NAME:</div>
                    <div className="mt-1  font-bold text-xs w-32 h-[25px] flex items-end">
                        {lastName}
                    </div>
                </div>{' '}
            </div>{' '}
            <ChangePasswordForm />
            {/* <div className=" text-xs flex-1">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row w-full">
                        <div className="mr-4 w-[calc(65.31px_+_20px)] text-right">
                            EMAIL
                        </div>
                        <div className=" text-sm font-semibold">
                            {email}
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="mr-4 w-[calc(65.31px_+_20px)] text-right">
                            FIRST NAME
                        </div>
                        <div className=" text-sm font-semibold">
                            {firstName}
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="mr-4 w-[calc(65.31px_+_20px)] text-right">
                            LAST NAME
                        </div>
                        <div className=" text-sm font-semibold">
                            {lastName}
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

const schema = yup.object().shape({
    currentPassword: validations.generic,
    password: validations.password,
    passwordConfirm: validations.passwordConfirmation,
})

const ChangePasswordForm = () => {
    const [
        {
            edit,
            loading,
            modals: { confirmModal, error },
        },
        setState,
    ] = useState({
        edit: false,
        loading: false,
        modals: {
            confirmModal: false,
            error: false,
        },
    })

    const { logout } = useAuth()
    const dispatch = useDispatch()
    const enableEdit = () =>
        setState((state) => ({ ...state, edit: !state.edit }))

    const startLoading = () =>
        setState((state) => ({ ...state, loading: !state.loading }))

    const showModal = (name) =>
        setState((state) => ({
            ...state,
            modals: { ...state.modals, [name]: !state.modals[name] },
        }))

    const handleSubmit = async (values) => {
        showModal('confirmModal')
    }

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: '',
        },
        onSubmit: handleSubmit,
        validationSchema: schema,
    })

    const reset = useCallback(async () => {
        enableEdit()
        await sleep(100)
        formik.resetForm()
    }, [formik])

    const handleUpdatePassword = useCallback(async () => {
        startLoading()

        await sleep(1000)
        try {
            await dispatch(updatePassword(formik.values)).unwrap()
            await sleep(1000)
            logout()
        } catch (error) {
            await sleep(1000)
            formik.resetForm()
            enableEdit()
            startLoading()
            showModal('confirmModal')
            showModal('error')
        }
    }, [formik, dispatch])

    return (
        <React.Fragment>
            <PasswordUpdate
                visible={confirmModal}
                onConfirm={handleUpdatePassword}
                onClose={() => {
                    showModal('confirmModal')
                }}
                loading={loading}
            />
            <SomethingWentWrong
                visible={error}
                onClose={() => {
                    showModal('error')
                }}
            />{' '}
            <div className=" text-sm font-medium mt-5">CHANGE PASSWORD</div>
            <form
                className="flex flex-row  mt-4 gap-24 pb-4 h-[72px]"
                onSubmit={formik.handleSubmit}
            >
                {' '}
                <div className="w-36">
                    <div className="text-[10px]">CURRENT PASSWORD:</div>
                    {!edit ? (
                        <div className="mt-1  font-bold text-xs w-32 h-[25px] flex items-end">
                            *********
                        </div>
                    ) : (
                        <FormikAntInputPassword
                            name={'currentPassword'}
                            formik={formik}
                            placeholder="*********"
                            disabled={!edit}
                            className="h-[25px] mt-1  "
                            // size="none"
                        />
                    )}
                </div>
                <div className="w-36">
                    <div className="text-[10px]">PASSWORD:</div>
                    {!edit ? (
                        <div className="mt-1  font-bold text-xs w-32 h-[25px] flex items-end">
                            *********
                        </div>
                    ) : (
                        <FormikAntInputPassword
                            name={'password'}
                            formik={formik}
                            placeholder="*********"
                            className="h-[25px] mt-1  "
                            disabled={!edit}
                        />
                    )}
                </div>
                <div className="w-36 ">
                    <div className="text-[10px]">CONFIRM PASSWORD:</div>{' '}
                    {!edit ? (
                        <div className="mt-1  font-bold text-xs w-32 h-[25px] flex items-end">
                            *********
                        </div>
                    ) : (
                        <FormikAntInputPassword
                            name={'passwordConfirm'}
                            formik={formik}
                            placeholder="*********"
                            className="h-[25px] mt-1  "
                            disabled={!edit}
                        />
                    )}
                </div>
                <div className="h-[47.7px] flex items-end">
                    {!edit ? (
                        <button
                            onClick={reset}
                            className="  text-[11px] font-bold h-9 border  px-8 rounded-md"
                        >
                            CHANGE PASWORD
                        </button>
                    ) : (
                        <div className="flex flex-row gap-3">
                            <button
                                type="button"
                                onClick={reset}
                                className=" text-[11px] font-bold h-9 border  px-8 rounded-md"
                            >
                                CANCEL
                            </button>
                            <button
                                type="submit"
                                className=" text-[11px] font-bold h-9 border  px-8 rounded-md"
                            >
                                SAVE
                            </button>
                        </div>
                    )}
                </div>
            </form>
        </React.Fragment>
    )
}

const Profile = () => {
    const { username, address, dateJoined, email, firstName, lastName } =
        useUserInfo()

    return (
        <div className="pb-8 w-full max-w-[1480px] mx-auto">
            <div className=" text-lg font-medium">MY PROFILE</div>
            <div className="flex flex-col gap-4 mt-2">
                <ProfileInfo
                    userName={username}
                    address={address}
                    dateJoined={dateJoined}
                />
                <UserPersonalData
                    email={email}
                    firstName={firstName}
                    lastName={lastName}
                />
            </div>
        </div>
    )
}
export default Profile
