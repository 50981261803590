/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react'
import { FaUser, FaLock, FaSpinner } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import {
    login,
    LOG_IN_SCREEN,
    useScreenAccess,
} from 'src/store/reducers/auth/authReducer'
import { useDispatch } from 'react-redux'
import logoIgniite from 'src/assets/images/logo.png'
import { Input, Alert } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import NoLoggedLayout from 'src/components/layouts/AuthLayout'
import useAuth from '../../hooks/useAuth'
// import LogoIcon from '../../components/SVGComponents/LogoIcon'
import {
    useUpdateScreenAccess,
    useResetScreenAccess,
} from '../../store/reducers/auth/authReducer'
// import LogoText from 'src/components/SVGComponents/LogoText'
import UserIcon from 'src/components/SVGComponents/UserIcon'
import PasswordIcon from '../../components/SVGComponents/PasswordIcon'
import useMediaQuery from '../../hooks/useMediaQuery'
import Button from '../../components/Buttons/Button'
import Icon from './../../components/Icons/Icon'

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const LogIn = () => {
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [{ user, pass }, setAuth] = useState({ user: '', pass: '' })

    const { login } = useAuth()
    const updateScreenAccess = useUpdateScreenAccess()

    const loginSubmit = useCallback(
        async (e) => {
            e.preventDefault()
            setLoading(true)

            try {
                await sleep(500)
                await login({ user, password: pass })
                updateScreenAccess(LOG_IN_SCREEN)
                navigate('2fa/verify')
            } catch (error) {
                console.log('Error: ', { error })
                if (error?.message) {
                    setError(error.message)
                }
            } finally {
                setLoading(false)
            }
        },
        [user, pass, updateScreenAccess]
    )

    useResetScreenAccess(loading)

    const isMobile = useMediaQuery('(max-width: 1023px)')
    return (
        <div className="mt-[15%] lg:my-auto w-full px-9 lg:px-4">
            <div className="flex flex-col lg:items-center lg:bg-experts-gray-9 lg:bg-opacity-40 lg:border lg:border-opacity-40 lg:border-experts-gray-12 mx-auto my-auto w-full md:w-[460px] lg:w-[664px] lg:h-[628px] lg:px-40 lg:py-[52px]">
                <Icon name="logo" className="w-full h-16 lg:h-[62px]" />
                <div className="font-extrabold text-white text-[32px] tracking-widest mt-[72px] lg:mt-12">
                    WELCOME <span className="text-experts-green-1">BACK!</span>
                </div>
                <div className="text-lg text-experts-gray-5 tracking-widest mt-3 lg:mt-6 mb-11 lg:mb-7">
                    Login your account
                </div>
                <div className="flex justify-center w-full">
                    <form
                        className="flex flex-col w-full"
                        onSubmit={loginSubmit}
                    >
                        <div className="flex flex-col space-y-4 lg:space-y-0">
                            <div className="w-full custom-input-login">
                                <Input
                                    id={'user'}
                                    name={'user'}
                                    placeholder={'User'}
                                    size="large"
                                    onChange={(e) => {
                                        e.preventDefault()
                                        setAuth((state) => ({
                                            ...state,
                                            user: e.target.value,
                                        }))
                                    }}
                                    prefix={
                                        <div className="w-[20px] h-[30px] rounded-md flex justify-center items-center mr-3">
                                            <Icon
                                                name="1-user"
                                                className="w-[0.9rem]"
                                            />
                                        </div>
                                    }
                                />
                            </div>
                            <div>
                                <div className="w-full mt-6 lg:mt-8 custom-input-login">
                                    <Input.Password
                                        id={'password'}
                                        name={'password'}
                                        placeholder={'Password'}
                                        size="large"
                                        // className={`h-[60px] rounded-sm w-full bg-white shadow-experts-lg  px-6`}
                                        onChange={(e) => {
                                            e.preventDefault()
                                            setAuth((state) => ({
                                                ...state,
                                                pass: e.target.value,
                                            }))
                                        }}
                                        iconRender={(visible) =>
                                            visible ? (
                                                <EyeOutlined
                                                    style={{ color: '#8A8A8A' }}
                                                />
                                            ) : (
                                                <EyeInvisibleOutlined
                                                    style={{ color: '#8A8A8A' }}
                                                />
                                            )
                                        }
                                        prefix={
                                            <div className="w-[20px] h-[30px] rounded-md flex justify-center items-center mr-3">
                                                <Icon
                                                    name="1-password"
                                                    className="w-[0.9rem]"
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                                {error && (
                                    <Alert
                                        className="mt-1"
                                        showIcon
                                        message={error}
                                        closable
                                        type="error"
                                        onClose={() => setError('')}
                                    />
                                )}
                            </div>
                        </div>

                        {/* <div className="ml-auto my-6">
                        <a
                            className="cursor-pointer "
                            onClick={() => navigate('/forgotpassword')}
                        >
                            Forgot your password?
                        </a>
                    </div> */}
                        <Button
                            type="submit"
                            loading={loading}
                            className="text-sm mt-24 lg:mt-12 w-48 lg:w-56 mx-auto"
                            variant="warning"
                        >
                            LOG IN
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LogIn
