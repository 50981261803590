import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { FaSpinner } from 'react-icons/fa'

const classes = {
    base: 'btn',
    disabled: 'opacity-50 cursor-not-allowed',
    pill: 'rounded-full',
    size: {
        small: 'btn-small',
        normal: 'btn-normal',
        large: 'btn-lg',
    },
    variant: {
        gradient: 'btn-gradient',
        primary: 'btn-primary',
        warning: 'btn-warning',
        light: 'btn-light',
        lightPrimary: 'btn-lightPrimary',
        lightDanger: 'btn-lightDanger',
        gray: 'btn-gray',
        danger: 'btn-danger',
    },
    spinnerSize: {
        small: 'text-xs',
        normal: 'text-2xl ',
        large: 'text-3xl',
    },
}

const Button = forwardRef(
    (
        {
            children,
            className,
            loading = false,
            disabled,
            type = 'button',
            size = 'normal',
            variant = 'primary',
            pill,
            spinnerSize = 'normal',
            ...restProps
        },
        ref
    ) => {
        return (
            <button
                ref={ref}
                disabled={disabled}
                type={type}
                className={classNames(`${classes.base}`, {
                    [className]: !!className,
                    [classes.size[size]]: !!classes.size[size],
                    [classes.variant[variant]]: !!classes.variant[variant],
                    [classes.pill]: pill,
                    [classes.disabled]: disabled,
                })}
                {...restProps}
            >
                {loading && (
                    <FaSpinner
                        className={classNames(
                            `animate-spin ${children ? 'mr-2' : ''}`,
                            {
                                [classes.spinnerSize[spinnerSize]]:
                                    !!classes.spinnerSize[spinnerSize],
                            }
                        )}
                    />
                )}
                {children}
            </button>
        )
    }
)

Button.displayName = 'Button'

export default Button
