import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../services/axiosConfig'
import { sleep } from 'src/utils/promise'
import { saveOrder } from './trades.slice'

export const closePosition = createAsyncThunk(
    'placeTrade/closePosition',
    async ({ keyId, symbol }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post(
                `bybit/manager/closeposition/`,
                {
                    keyId,
                    symbol,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const closeActiveOrder = createAsyncThunk(
    'placeTrade/closeActiveOrder',
    async ({ keyId, orderId, symbol, side }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post(`bybit/manager/cancelorder/`, {
                keyId,
                orderId,
                symbol,
            })
            return { data: res.data, symbol, side }
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)
export const placeLimitOrder = createAsyncThunk(
    'placeTrade/placeLimitOrder',
    async (
        {
            side,
            symbol,
            entryPrice,
            keyId,
            leverage,
            percentage,
            maxaccbalance = 0,
            takeProfit = 0,
            stopLoss = 0,
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/manager/placelimitorder/`,
                {
                    side,
                    symbol,
                    entryPrice,
                    keyId,
                    percentage,
                    maxaccbalance,
                    takeProfit,
                    stopLoss,
                    leverage,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)
export const placeMarketRatio = createAsyncThunk(
    'placeTrade/placeMarketRatio',
    async (
        {
            side,
            symbol,
            entryPrice,
            keyId,
            leverage,
            percentage,
            maxaccbalance = 0,
            takeProfit = 0,
            stopLoss = 0,
        },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/manager/placemarketratio/`,
                {
                    side,
                    symbol,
                    keyId,
                    percentage,
                    maxaccbalance,
                    takeProfit,
                    stopLoss,
                    leverage,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const repurchaseLimitOrder = createAsyncThunk(
    'placeTrade/repurchaselimit',
    async (
        { side, symbol, entryPrice, keyId, percentage, qty },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/manager/repurchaselimit/`,
                {
                    side,
                    symbol,
                    entryPrice,
                    keyId,
                    percentage,
                    qty,
                    takeProfit: 0,
                    stopLoss: 0,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const placeMarketOrder = createAsyncThunk(
    'placeTrade/placeMarketOrder',
    async (
        { side, symbol, keyId, percentage, qty },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/manager/placemarketorder/`,
                {
                    keyId,
                    side,
                    symbol,
                    percentage,
                    qty,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const getTrades = createAsyncThunk(
    'trades/getTrades',
    async ({ id, cancelToken }, { rejectWithValue, getState }) => {
        try {
            const res = await Promise.all([
                await axiosInstance.get(`bybit/gettrades/${id}`, {
                    cancelToken,
                }),
                await axiosInstance.get(`bybit/trade/${id}`, { cancelToken }),
            ])
                .then(([bybitTrades, trades]) => {
                    const $bybitTrades = bybitTrades.data.reduce(
                        (acc, trade) => {
                            const index = trades.data?.findIndex(
                                (v) =>
                                    v.symbol === trade.symbol &&
                                    v.side === trade.side
                            )
                            if (typeof index !== 'undefined' && index !== -1)
                                return acc
                            return [...acc, trade]
                        },
                        []
                    )

                    const $trades = trades.data.map((trade) => {
                        const index = bybitTrades.data?.findIndex(
                            (v) =>
                                v.symbol === trade.symbol &&
                                v.side === trade.side
                        )
                        if (index !== -1) {
                            return {
                                ...trade,
                                leverage: bybitTrades.data[index].leverage,
                                balancepercentage:
                                    bybitTrades.data[index].balancepercentage,
                                pnlusd: bybitTrades.data[index].pnlusd,
                                positionValue:
                                    bybitTrades.data[index].positionValue,
                                sl: bybitTrades.data[index].sl,
                                tp: bybitTrades.data[index].tp,
                                liqPrice: bybitTrades.data[index].liqPrice,
                            }
                        }
                        return trade
                    })

                    return {
                        trades: $trades,
                        bybitTrades: $bybitTrades,
                        symbols: bybitTrades.data.map((v) => [
                            v.symbol,
                            v.side,
                        ]),
                        amountPercentInTrades: bybitTrades.data.reduce(
                            (acc, v) => acc + Number(v.balancepercentage),
                            0
                        ),
                    }
                })
                .catch(([errB, err]) => {
                    console.log({ errB, err })
                })

            return res
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const validateTradesActive = createAsyncThunk(
    'placeTrade/validateTradesActive',
    async ({ side, symbol, keyId }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post(
                `bybit/tradingmanager/validatetradesactive/`,
                {
                    keyId,
                    side,
                    symbol,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const validateTradesClosed = createAsyncThunk(
    'placeTrade/validateTradesClosed',
    async ({ side, symbol, keyId }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post(
                `bybit/tradingmanager/validatetradesclosed/`,
                {
                    keyId,
                    side,
                    symbol,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const setManualTp = createAsyncThunk(
    'placeTrade/setManualTp',
    async (
        { side, symbol, keyId, qty, entryPrice },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/tradingmanager/manualtp/`,
                {
                    keyId,
                    side,
                    symbol,
                    qty,
                    entryPrice,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)
export const placeCloseLimit = createAsyncThunk(
    'placeTrade/placeCloseLimit',
    async (
        { side, symbol, keyId, qty, entryPrice },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/tradingmanager/placecloselimit/`,
                {
                    keyId,
                    side,
                    symbol,
                    qty,
                    entryPrice,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const setStopLoss = createAsyncThunk(
    'placeTrade/setSL',
    async (
        { side, symbol, keyId, qty, stopLoss },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/manager/settakeprofit/`,
                {
                    keyId,
                    side,
                    symbol,
                    qty,
                    takeProfit: 0,
                    stopLoss,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)
// Reduceonly si es true quiere decir que es una orden de cierre
// Create

export const getOrders = createAsyncThunk(
    'placeTrade/getOrders',
    async (
        { id, symbols, cancelToken },
        { rejectWithValue, getState, dispatch }
    ) => {
        try {
            // const response = []
            // const strategies = state.strategiesReducer.strategies
            // const symbolsStrategy = state.symbolsStrategyReducer

            for (let index = 0; index < symbols.length; index++) {
                const symbol = symbols[index]
                const res = await axiosInstance.post(
                    `bybit/manager/getactiveorders/`,
                    {
                        keyId: id,
                        symbol,
                    },
                    { cancelToken }
                )
                const $saveOrder = (payload) => dispatch(saveOrder(payload))
                if (res.data.result.length > 0) {
                    // Delete Residual orders in active orders
                    const ordersId = res.data.result.map((v) => v.order_id)
                    const residualActiveOrders = getState()
                        .tradesReducer.activeOrders.map((v, index) => ({
                            ...v,
                            index,
                        }))
                        .reduce((acc, v) => {
                            if (v.symbol === symbol) {
                                acc.push(v)
                            }
                            return acc
                        }, [])
                        .filter((v) => !ordersId.includes(v.order_id))

                    residualActiveOrders.forEach((v) => {
                        $saveOrder({
                            index: v.index,
                            type: 'REMOVE',
                        })
                    })

                    // Update, insert or add orders
                    for (let i = 0; i < res.data.result.length; i++) {
                        const order = res.data.result[i]
                        console.log({ order })
                        // Update active orders
                        const activeOrders =
                            getState().tradesReducer.activeOrders.map(
                                (v, index) => ({ ...v, index })
                            )
                        console.log({ activeOrders })

                        // Check if the order is already in the active orders
                        const activeOrderIndex = activeOrders.findIndex(
                            (v) => v.order_id === order.order_id
                        )
                        const lastOrderIndex = activeOrders.findIndex(
                            (v) =>
                                v.order_id === res.data.result[i - 1]?.order_id
                        )

                        if (activeOrderIndex !== -1) {
                            $saveOrder({
                                order,
                                index: activeOrderIndex,
                                type: 'UPDATE',
                            })
                        } else if (
                            activeOrders.length - 1 !== lastOrderIndex &&
                            i > 0
                        ) {
                            $saveOrder({
                                index: lastOrderIndex + 1,
                                order,
                                type: 'INSERT',
                            })
                        } else {
                            $saveOrder({ order, type: 'ADD' })
                        }
                    }
                } else if (
                    getState().tradesReducer.activeOrders.filter(
                        (v) => v.symbol === symbol
                    ).length > 0
                ) {
                    dispatch(saveOrder({ symbol, type: 'REMOVEALL' }))
                }

                await sleep(200)
            }

            return {}
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)
// export const getMoneyTrades = createAsyncThunk(
//     'trades/getMoneyTrades',
//     async (id, { rejectWithValue, getState }) => {
//         try {
//             const res = await axiosInstance.get(`bybit/trade/${id}`)
//             return res.data
//         } catch (error) {
//             if (!error.response) throw error
//             return rejectWithValue(error.response.data)
//         }
//     }
// )

export const subscribePosition = createAsyncThunk(
    'trades/subscribePosition',
    async ({ keyId, symbol, side }, { rejectWithValue, getState }) => {
        try {
            const res = await axiosInstance.post('bybit/subscribeposition/', {
                symbol,
                side,
                id: keyId,
            })

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const unsubscribePosition = createAsyncThunk(
    'trades/unsubscribePosition',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.delete(
                `bybit/unsubscribeposition/${payload}`
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const preSubscribePosition = createAsyncThunk(
    'trades/presubscribeposition',
    async (
        { keyId, symbol, side, takeProfit },
        { rejectWithValue, getState }
    ) => {
        console.log('keyId', keyId)
        try {
            const res = await axiosInstance.post(
                'bybit/presubscribeposition/',
                {
                    symbol,
                    side,
                    id: keyId,
                    takeProfit,
                }
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)
