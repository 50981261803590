/* eslint-disable no-unused-vars */
import React, {
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import useArray from 'src/hooks/useArray'
import MyConfigSimulator from './MyConfigSimulator'
import Stages from './Stages'
import useDeepCompareEffect from './../../../hooks/useDeepCompareEffect'
import Button from './../../Buttons/Button'
import { sleep } from './../../../utils/promise'
import { useSetStages } from './../../../store/reducers/strategies/strategiesReducer'
import useResponsiveValue from './../../../hooks/useResponsiveValue'
import StagesRatio from './StagesRatio/index'
import { transformValues } from 'src/utils/transformUtilities'

const defaultState = {
    saved: false,
    name: '',
    action: 'Leverage',
    condition: '50',
    size: '50',
    takeProfit: '100',
}

const convertPhaseData = (stages) => {
    return stages.map((value, index) => {
        const $value = transformValues(
            [
                'condition',
                'size',
                'takeProfit',
                'stopLoss',
                'conditionSecondary',
                'sizeSecondary',
                'takeProfitSecondary',
                'stopLossSecondary',
            ],
            value ?? {},
            (v) => {
                console.log(v)
                return v.toString().replace('-', '')
            }
        )
        return {
            ...$value,
            saved: true,
        }
    })
}

const MyConfig = React.forwardRef(
    (
        {
            onToggle,
            id: strategyId,
            active: activeMM,
            name: strategyName,
            ratioStrategy,
            initialTP,
            initialLeverage,
            phases = [],
            onEdited,
        },
        ref
    ) => {
        const backupPhases = useRef()
        const simulatorRef = useRef(null)
        const {
            array: stages,
            push,
            remove,
            update,
            set,
        } = useArray([...convertPhaseData(phases)])

        const [editedStage, setEditedStage] = useState(false)
        const { setStages, loading, error } = useSetStages()

        const handleSetInitialData = useCallback(() => {
            const convertedPhase = convertPhaseData(phases)

            if (backupPhases.current !== convertedPhase) {
                backupPhases.current = convertedPhase
                set(convertedPhase)
            }
        }, [phases])

        const handleOnAddStage = async () => {
            push({
                // index: stages[stages.length - 1].index + 1,
                ...defaultState,
                saved: false,
            })
        }

        const handleUpdateStage = useCallback(
            (index, values) => {
                console.log('values', values)
                update(index, { ...values, saved: true })
            },
            [update]
        )

        const handleSaveStages = useCallback(async () => {
            await sleep(600)

            await setStages(
                strategyId,
                stages.map(
                    (
                        {
                            condition,
                            size,
                            takeProfit,
                            stopLoss,
                            action,
                            conditionSecondary,
                            sizeSecondary,
                            takeProfitSecondary,
                            actionSecondary,
                        },
                        index
                    ) => ({
                        condition: Number(`-${condition}`),
                        size: Number(size),
                        takeProfit:
                            takeProfit === 'N/A' ? 0 : Number(takeProfit),
                        stopLoss: 0,
                        stopLossSecondary: 0,
                        name: `Stage ${index + 1}`,
                        action,
                        conditionSecondary: conditionSecondary
                            ? Number(conditionSecondary)
                            : null,
                        sizeSecondary: sizeSecondary
                            ? Number(sizeSecondary)
                            : null,
                        takeProfitSecondary: takeProfitSecondary
                            ? Number(takeProfitSecondary)
                            : null,
                        actionSecondary: actionSecondary ?? null,
                    })
                )
            ).then(() => {
                backupPhases.current = stages
                setEditedStage(false)
                onEdited(false)
            })
        }, [stages, strategyId, setStages])

        const handleRemoveStage = (index) => remove(index)

        const handleCompareStages = useCallback(() => {
            let edited = false
            if (backupPhases.current.length !== stages.length) edited = true

            if (!edited) {
                for (let index = 0; index < stages.length; index++) {
                    for (const key in stages[index]) {
                        if (
                            stages[index][key] !==
                            backupPhases.current[index][key]
                        ) {
                            edited = true
                            break
                        }
                    }
                }
            }

            onEdited(edited)
            setEditedStage(edited)
        }, [stages])

        useDeepCompareEffect(() => {
            handleSetInitialData()
        }, [phases])

        useDeepCompareEffect(() => {
            handleCompareStages()
        }, [stages])

        useImperativeHandle(ref, () => ({
            reset: () => {
                set(backupPhases.current)
            },
            simulator: simulatorRef.current,
        }))

        const showSaveButton = useMemo(() => {
            let show = true
            if (loading) return show
            if (!editedStage) show = false

            for (const phase of stages) {
                if (!phase.saved) {
                    show = false
                    break
                }
            }

            return show
        }, [editedStage, stages, loading])

        const buttonSize = useResponsiveValue({ base: 'small', lg: 'normal' })

        return (
            <React.Fragment>
                {!ratioStrategy ? (
                    <Stages
                        strategyName={strategyName}
                        activeMM={activeMM}
                        data={stages}
                        onAddStage={handleOnAddStage}
                        onSaveStage={handleUpdateStage}
                        onRemoveStage={handleRemoveStage}
                    />
                ) : (
                    <StagesRatio
                        strategyName={strategyName}
                        activeMM={activeMM}
                        data={stages}
                        onAddStage={handleOnAddStage}
                        onSaveStage={handleUpdateStage}
                        onRemoveStage={handleRemoveStage}
                    />
                )}

                <div className="pt-2 pb-5 lg:pb-6 flex">
                    {showSaveButton && (
                        <div className="my-auto w-full">
                            <Button
                                disabled={loading}
                                loading={loading}
                                onClick={handleSaveStages}
                                className="w-full"
                                size={buttonSize}
                                variant="primary"
                            >
                                Save
                            </Button>
                        </div>
                    )}
                </div>
                <div className="relative -mx-5 lg:mx-0 h-[1px] flex bg-experts-gray-4"></div>
                <div className="mt-5 lg:mt-6">
                    <MyConfigSimulator
                        ref={simulatorRef}
                        initialLeverage={initialLeverage}
                        initialTP={initialTP}
                        stages={stages}
                    />
                </div>
            </React.Fragment>
        )
    }
)

MyConfig.displayName = 'MyConfig'

export default MyConfig
