/* eslint-disable no-unused-vars */
import React from 'react'
import ReactPlayer from 'react-player'
import { Outlet } from 'react-router-dom'
import background from 'src/assets/videos/background.mp4'
import Icon from 'src/components/Icons/Icon'

const Brand = () => {
    return (
        <div className="flex items-center justify-center bg-igniite-black-800 mt-8 mr-2">
            <a>
                <Icon name="emblem" className="w-16 h-8 lg:h-12" />
            </a>
        </div>
    )
}

const DefaultNavbar = ({ acountName, paymentStatusId }) => {
    return (
        <div className="flex w-full mx-3 lg:mx-5 2xl:mx-auto max-w-[1720px]">
            <Brand />
        </div>
    )
}

const AuthLayout = ({ children }) => {
    return (
        <div className="flex flex-col h-screen min-w-[375px] relative overflow-hidden">
            {' '}
            <div className="absolute inset-0 overflow-hidden">
                <ReactPlayer
                    className="custom-react-player"
                    width="100%"
                    height="100%"
                    controls={false}
                    playing={true}
                    url={background}
                    playsinline
                    muted={true}
                    loop
                />
            </div>
            <div className="absolute inset-0 bg-experts-gray-9 bg-opacity-50  lg:hidden"></div>
            <header className="relative">
                <DefaultNavbar />
            </header>
            <div className="max-w-[1450px] w-full flex-1 flex flex-row mx-auto  relative">
                <main className="flex w-full ">
                    <Outlet />
                </main>
            </div>
        </div>
    )
}

export default AuthLayout
