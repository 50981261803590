import React from 'react'

import { Navigate, useNavigate } from 'react-router-dom'
import { TbArrowLeft } from 'react-icons/tb'
import Button from './../components/Buttons/Button'
import {
    useScreenAccess,
    FORGOT_PASSWORD_SCREEN,
} from 'src/store/reducers/auth/authReducer'

const SendEmailForgotPassword = () => {
    const screen = useScreenAccess()

    if (screen !== FORGOT_PASSWORD_SCREEN)
        return <Navigate to={'/forgotpassword'} replace />
    const navigate = useNavigate()
    return (
        <div className="w-full max-w-[450px] mx-auto ">
            <div className="mt-8 bg-experts-black-800 flex justify-center items-center rounded-sm py-16 px-3 lg:px-0 text-sm text-center">
                We sent you an email with the steps to recover your password
            </div>{' '}
            <div className="flex flex-row mt-8 mb-6 gap-3">
                <Button
                    variant="light"
                    onClick={() => {
                        navigate('/login', { replace: true })
                    }}
                    className="h-[68px] w-full"
                >
                    {' '}
                    <div className="flex flex-row justify-center items-center gap-2">
                        <TbArrowLeft size={'1.6rem'} className="" />
                        <div>GO BACK TO LOGIN</div>
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default SendEmailForgotPassword
