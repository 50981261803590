import React, { useState } from 'react'

const VerticalTabs = ({
    tabs = [
        { index: 'subAcc', label: 'SubAccounts' },
        // { index: 'shareKeys', label: 'Share Keys' },
        { index: 'traders', label: 'Traders' },
        { index: 'cryptoCurrencies', label: 'Crypto Currencies' },
    ],
    onSelect,
}) => {
    const [selected, setSelected] = useState(tabs[0]?.index)
    return (
        <div className="border-r pr-8 w-fit h-full space-y-9 pt-5">
            {tabs?.map((tab, index) => {
                return (
                    <div
                        key={`tab-${tab.index}`}
                        className={`relative w-[138px] max-w-[130px] pr-2 pb-1 break-words cursor-pointer text-sm ${
                            selected === tab.index
                                ? "font-extrabold before:content-[''] before:absolute before:bottom-0 before:w-full before:h-[2px] before:bg-experts-yellow-3"
                                : 'font-medium'
                        }`}
                        onClick={() => {
                            setSelected(tab.index)
                            onSelect(tab.index)
                        }}
                    >
                        {tab.label}
                    </div>
                )
            })}
        </div>
    )
}

export default VerticalTabs
