import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useCallback, useState, useRef } from 'react'
import { useEmisors } from 'src/store/reducers/dashboardReducer/dashboardReducer'
import useRefresh from 'src/hooks/useRefresh'
import { sleep } from 'src/utils/promise'
import dayjs from 'dayjs'
import axios from 'axios'
import { notification } from 'antd'
import customAntNotifications from '../../../service/customAntNotifications'
import { useActivedStrategies } from '../strategies/strategiesReducer'
import { useSymbolStrategyState } from '../strategies/symbolsStrategy'
import useDeepCompareEffect from 'src/hooks/useDeepCompareEffect'
import {
    getTrades,
    getOrders,
    closeActiveOrder,
    closePosition,
    placeLimitOrder,
    placeMarketOrder,
    repurchaseLimitOrder,
    validateTradesActive,
    validateTradesClosed,
    subscribePosition,
    unsubscribePosition,
    placeMarketRatio,
    setManualTp,
    placeCloseLimit,
    setStopLoss,
    preSubscribePosition,
} from './trades.actions'
import {
    selectSelf,
    selectedSymbolSelect,
    symbolsUsedSelect,
} from './trades.selectors'
import {
    setSymbol,
    reloadTrades,
    setSide,
    clearPendingSymbols,
    clearOrderDeletedSymbols,
    setChildsActiveTrades,
    setChildsActiveOrders,
    addPendingSymbol,
} from './trades.slice'
import { useRetryCallback } from 'src/hooks/useRetryCallback'
import { DELAY } from 'src/constants/delay.constants'

export const useSetSymbol = () => {
    const dispatch = useDispatch()
    return useCallback((symbol) => dispatch(setSymbol(symbol)), [dispatch])
}

export const useReloadTrades = () => {
    const dispatch = useDispatch()
    return useCallback(() => dispatch(reloadTrades()), [dispatch])
}

export const useTradesReducerState = () => {
    return useSelector(selectSelf)
}

export const useSelectedSymbol = () => {
    return useSelector(selectedSymbolSelect)
}

export const useSymbolsUsed = () => {
    return useSelector(symbolsUsedSelect)
}

export const useSetSide = () => {
    const dispatch = useDispatch()
    const { selectedSide } = useTradesReducerState()

    const $setSide = useCallback((side) => dispatch(setSide(side)), [dispatch])
    return { setSide: $setSide, side: selectedSide }
}

export const useClearPendingSymbols = () => {
    const dispatch = useDispatch()
    const {
        fetchedOrders,
        fetchedTrades,
        requestFetchOrderTime,
        requestFetchTradeTime,
        lastOrderExecutedTime,
        symbolsPending,
        activeOrders,
        symbolsUsed,
    } = useTradesReducerState()

    const $clear = useCallback(() => {
        if (symbolsPending.length > 0) {
            if (
                fetchedOrders &&
                !fetchedTrades &&
                dayjs(requestFetchOrderTime) > dayjs(lastOrderExecutedTime)
            ) {
                dispatch(
                    clearPendingSymbols(
                        symbolsPending.filter(
                            (v) => !activeOrders.find((a) => a.symbol === v)
                        )
                    )
                )
            }

            if (
                fetchedTrades &&
                !fetchedOrders &&
                dayjs(requestFetchOrderTime) > dayjs(lastOrderExecutedTime)
            ) {
                dispatch(
                    clearPendingSymbols(
                        symbolsPending.filter(
                            (v) => !symbolsUsed.find((a) => a[0] === v)
                        )
                    )
                )
            }
            if (
                (!lastOrderExecutedTime && fetchedOrders && fetchedTrades) ||
                (lastOrderExecutedTime &&
                    fetchedOrders &&
                    fetchedTrades &&
                    dayjs(requestFetchOrderTime) >
                        dayjs(lastOrderExecutedTime) &&
                    dayjs(requestFetchTradeTime) > dayjs(lastOrderExecutedTime))
            ) {
                dispatch(clearPendingSymbols())
            }
        }
    }, [
        fetchedOrders,
        fetchedTrades,
        requestFetchOrderTime,
        requestFetchTradeTime,
        symbolsPending,
        activeOrders,
        lastOrderExecutedTime,
        dispatch,
    ])

    useEffect(() => {
        if (fetchedOrders || fetchedTrades) $clear()
    }, [fetchedOrders, fetchedTrades])
}

export const useClearDeletedSymbols = () => {
    const dispatch = useDispatch()
    const {
        fetchedOrders,
        requestFetchOrderTime,
        requestFetchTradeTime,
        lastDeletedOrderExecutedTime,
        symbolsOrderDeleted,
        activeOrders,
    } = useTradesReducerState()

    const $clear = useCallback(() => {
        if (
            symbolsOrderDeleted.length > 0 &&
            dayjs(requestFetchOrderTime) > dayjs(lastDeletedOrderExecutedTime)
        ) {
            if (activeOrders.length > 0) {
                dispatch(
                    clearOrderDeletedSymbols(
                        symbolsOrderDeleted.filter(
                            (v) =>
                                !activeOrders.find(
                                    (a) => a.symbol === v[0] && a.side === v[1]
                                )
                        )
                    )
                )
            }
            if (activeOrders.length === 0) {
                dispatch(clearOrderDeletedSymbols())
            }
        }
    }, [
        requestFetchOrderTime,
        requestFetchTradeTime,
        lastDeletedOrderExecutedTime,
        symbolsOrderDeleted,
        activeOrders,
        dispatch,
    ])

    useEffect(() => {
        if (fetchedOrders) $clear()
    }, [fetchedOrders])
}

export const useSetChildActiveTrades = () => {
    const dispatch = useDispatch()
    return useCallback(
        (value) => dispatch(setChildsActiveTrades(value)),
        [dispatch]
    )
}

export const useSetChildActiveOrders = () => {
    const dispatch = useDispatch()
    return useCallback(
        (value) => dispatch(setChildsActiveOrders(value)),
        [dispatch]
    )
}

export const useValidateTradesActive = () => {
    const dispatch = useDispatch()
    return useCallback(
        (side, symbol, keyId) =>
            dispatch(
                validateTradesActive({
                    side,
                    symbol,
                    keyId,
                })
            ),
        [dispatch]
    )
}

export const useValidateTradesClosed = () => {
    const dispatch = useDispatch()
    return useCallback(
        (side, symbol, keyId) =>
            dispatch(
                validateTradesClosed({
                    side,
                    symbol,
                    keyId,
                })
            ),
        [dispatch]
    )
}

export const useSubscribePosition = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const validateActiveTrades = useValidateTradesActive()
    const fetch = useCallback(
        async ({ keyId, symbol, side }) => {
            try {
                setLoading(true)
                // const {
                // result: { data },
                // } = await validateActiveTrades(side, symbol, keyId).unwrap()
                // if (data.ok === data.suscriptors && data.details.length === 0) {
                await dispatch(
                    subscribePosition({ keyId, symbol, side })
                ).unwrap()
                dispatch(reloadTrades())
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Subscribed position',
                    description: 'You successfully subscribe your position',
                    placement: 'bottomRight',
                })
                // } else {
                // This is for the case when the user has active trades show a modal to the user
                // dispatch(setChildsActiveOrders(true))
                // }
            } catch (err) {
                notification.error({
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                })
                console.log({ error })
                setError(err)
            }
            setLoading(false)
        },
        [validateActiveTrades, dispatch]
    )
    return { subscribePosition: fetch, loading, error, setError }
}

export const useUnsubscribePosition = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const fetch = useCallback(
        async ({ symbol }) => {
            try {
                setLoading(true)
                await dispatch(unsubscribePosition(symbol)).unwrap()
                dispatch(reloadTrades())
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Unsubscribed position',
                    description: 'You successfully unsubscribe your position',
                    placement: 'bottomRight',
                })
            } catch (error) {
                notification.error({
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                })
                console.log({ error })
            }
            setLoading(false)
        },
        [dispatch]
    )
    return { unsubscribePosition: fetch, loading }
}

export const useFetchTrades = () => {
    const { fastRefresh } = useRefresh()
    const { reload, requestFetchTradeTime } = useTradesReducerState()
    const cancelCall = useRef(null)
    const [init, setInit] = useState(false)
    const [{ fetching, reloading }, setStatus] = useState({
        fetching: false,
        reloading: false,
    })
    // const [fetching, setFetching] = useState(false)
    // const [reloading, setReloading] = useState(false)
    const lastReloadValue = useRef(null)
    const { emisorSelected } = useEmisors()
    const dispatch = useDispatch()

    const fetch = useCallback(async () => {
        try {
            if (
                dayjs() <= dayjs(requestFetchTradeTime).add(10, 'seconds') &&
                reload === lastReloadValue.current
            )
                return
            // if (init && reload && reload !== lastReloadValue.current) {
            if (reload !== lastReloadValue.current) {
                if (fetching) {
                    console.log('Cancel Order')
                    cancelCall.current.cancel('Reload Executed')
                }
                if (init) {
                    setStatus((state) => ({ ...state, reloading: true }))
                }
            }
            if (!init) setInit(true)

            setStatus((state) => ({ ...state, fetching: true }))
            cancelCall.current = axios.CancelToken.source()

            await dispatch(
                getTrades({
                    id: emisorSelected,
                    cancelToken: cancelCall.current.token,
                })
            ).unwrap()
        } catch (error) {
            console.log({ error })
        }

        setStatus((state) => ({ ...state, fetching: false, reloading: false }))
    }, [
        emisorSelected,
        reload,
        reloading,
        lastReloadValue,
        init,
        requestFetchTradeTime,
        dispatch,
    ])

    useEffect(() => {
        if (emisorSelected) fetch()
        if (reload !== lastReloadValue.current) lastReloadValue.current = reload
    }, [emisorSelected, reload, fastRefresh])

    return { reloading }
}

export const useGetOrders = () => {
    const dispatch = useDispatch()
    return useCallback(
        (keyId, symbols, cancelToken) =>
            dispatch(getOrders({ id: keyId, symbols, cancelToken })),
        [dispatch]
    )
}

export const useFetchOrders = () => {
    const selectedSymbol = useSelectedSymbol()
    const activeStrategies = useActivedStrategies()
    const symbolsStrategy = useSymbolStrategyState()
    const { fastRefresh } = useRefresh()
    const { reload, requestFetchOrderTime } = useTradesReducerState()
    const lastReloadValue = useRef(null)
    const cancelCall = useRef(null)
    const { emisorSelected } = useEmisors()
    const [fetching, setFetching] = useState(false)
    const $getOrders = useGetOrders()

    const fetch = useCallback(async () => {
        if (fetching && reload !== lastReloadValue.current) {
            console.log('Cancel Order')
            cancelCall.current.cancel('Reload Executed')
        }
        if (
            dayjs() <= dayjs(requestFetchOrderTime).add(10, 'seconds') &&
            reload === lastReloadValue.current
        )
            return
        setFetching(true)
        cancelCall.current = axios.CancelToken.source()

        const idStrategies = activeStrategies.map((v) => v.id)
        const symbols = idStrategies
            .reduce((acc, v) => [...acc, ...(symbolsStrategy?.[v] ?? [])], [])
            .reduce((acc, v) => {
                console.log({
                    acc,
                    indexof: acc.indexOf(v.symbol),
                    s: v,
                })
                return acc.indexOf(v.symbol) !== -1 ? acc : [...acc, v.symbol]
            }, [])
        try {
            await $getOrders(
                emisorSelected,
                symbols,
                cancelCall.current.token
            ).unwrap()
        } catch (error) {
            console.log({ error })
        }
        setFetching(false)
    }, [
        emisorSelected,
        fetching,
        $getOrders,
        selectedSymbol,
        requestFetchOrderTime,
        reload,
        activeStrategies,
        symbolsStrategy,
    ])

    useDeepCompareEffect(() => {
        if (
            emisorSelected &&
            activeStrategies.length > 0 &&
            Object.keys(symbolsStrategy).length > 0
        )
            fetch()
        if (reload !== lastReloadValue.current) lastReloadValue.current = reload
    }, [
        emisorSelected,
        fastRefresh,
        reload,
        selectedSymbol,
        activeStrategies,
        symbolsStrategy,
    ])
}

export const usePlaceLimitOrder = (preSubscribe = false) => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    // const validateTradesClosed = useValidateTradesClosed()
    const setTp = useSetTP()

    const $preSubscribePosition = usePreSubscribePosition()

    const dispatch = useDispatch()

    const $placeLimitOrder = useCallback(
        async ({
            keyId,
            side,
            symbol,
            entryPrice,
            percentage,
            maxaccbalance,
            ratio,
            stopLoss,
            leverage = 0,
            takeProfit,
        }) => {
            try {
                setError(null)
                setLoading(true)
                const $side = side === 'Short' ? 'Sell' : 'Buy'
                // await validateTradesClosed($side, symbol, keyId).unwrap()

                if (preSubscribe) {
                    await sleep(DELAY.SLOW)
                    await $preSubscribePosition({
                        takeProfit,
                        symbol,
                        keyId,
                        side: $side,
                    })
                }

                await dispatch(
                    placeLimitOrder({
                        side: $side,
                        symbol,
                        entryPrice,
                        keyId,
                        leverage,
                        percentage,
                        maxaccbalance,
                        stopLoss,
                        takeProfit: 0,
                    })
                ).unwrap()
                dispatch(addPendingSymbol(symbol))
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Placed Order',
                    description: 'You successfully open a new trade.',
                    placement: 'bottomRight',
                })
            } catch (err) {
                setError(err)

                const errorArgs = {
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
            }
            setLoading(false)
        },
        [dispatch, setTp, preSubscribe]
    )
    return { placeLimitOrder: $placeLimitOrder, loading, error, setError }
}

export const usePlaceMarketRatio = (preSubscribe) => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    // const validateTradesClosed = useValidateTradesClosed()
    const setTp = useSetTP()

    const dispatch = useDispatch()
    const $preSubscribePosition = usePreSubscribePosition()

    const $placeMarketRatio = useCallback(
        async ({
            keyId,
            side,
            symbol,
            percentage,
            maxaccbalance,
            stopLoss,
            leverage = 0,
            takeProfit,
        }) => {
            try {
                setError(null)
                setLoading(true)
                const $side = side === 'Short' ? 'Sell' : 'Buy'
                // await validateTradesClosed($side, symbol, keyId).unwrap()

                if (preSubscribe) {
                    await sleep(DELAY.SLOW)
                    await $preSubscribePosition({
                        takeProfit,
                        symbol,
                        keyId,
                        side: $side,
                    })
                }

                await dispatch(
                    placeMarketRatio({
                        side: $side,
                        symbol,
                        keyId,
                        leverage,
                        percentage,
                        maxaccbalance,
                        stopLoss,
                        takeProfit: 0,
                    })
                ).unwrap()

                dispatch(addPendingSymbol(symbol))
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Placed Order',
                    description: 'You successfully open a new trade.',
                    placement: 'bottomRight',
                })
            } catch (err) {
                setError(err)

                const errorArgs = {
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
            }
            setLoading(false)
        },
        [dispatch, setTp, preSubscribe]
    )
    return { placeMarketRatio: $placeMarketRatio, loading, error, setError }
}

export const useRepurchaseLimitOrder = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const validateTradesActive = useValidateTradesActive()

    const dispatch = useDispatch()

    const $repurchaseLimitOrder = useCallback(
        async ({
            side,
            symbol,
            entryPrice,
            keyId,
            leverage,
            percentage,
            qty,
        }) => {
            try {
                setError(null)
                setLoading(true)
                const $side = side === 'Short' ? 'Sell' : 'Buy'
                // const {
                //     result: { data },
                // } = await validateTradesActive($side, symbol, keyId).unwrap()
                // if (data.ok === data.suscriptors && data.details.length === 0) {
                await dispatch(
                    repurchaseLimitOrder({
                        side: $side,
                        symbol,
                        entryPrice,
                        keyId,
                        percentage,
                        qty,
                    })
                ).unwrap()
                dispatch(addPendingSymbol(symbol))
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Placed Order',
                    description: 'You successfully open a new trade.',
                    placement: 'bottomRight',
                })
                // } else {
                //     dispatch(setChildsActiveTrades(true))
                // }
            } catch (err) {
                setError(err)
                console.log({ error })

                setError(err)

                const errorArgs = {
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
            }
            setLoading(false)
        },
        [validateTradesActive, dispatch]
    )
    return {
        repurchaseLimitOrder: $repurchaseLimitOrder,
        loading,
        error,
        setError,
    }
}

export const usePlaceMarketOrder = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const validateTradesActive = useValidateTradesActive()
    // const validateTradesClosed = useValidateTradesClosed()

    const dispatch = useDispatch()

    /**
     * If isRepurchase is true, validate if the user has active trades
     * If isRepurchase is false, validate if the user has closed trades
     * Returns a boolean if the user has active or closed trades
     */
    const validateTrades = useCallback(
        async ({ side, symbol, keyId, isRepurchase }) => {
            if (isRepurchase) {
                // console.log(side, symbol, keyId)
                // const {
                //     result: { data },
                // } = await validateTradesActive(side, symbol, keyId).unwrap()
                // console.log({ data })
                // if (data.ok === data.suscriptors && data.details.length === 0) {
                //     return true
                // } else {
                //     dispatch(setChildsActiveTrades(true))
                //     return false
                // }
                return true
            } else {
                // await validateTradesClosed(side, symbol, keyId).unwrap()
                return true
            }
        },
        [validateTradesActive, dispatch]
    )

    const $placeMarketOrder = useCallback(
        async ({
            isRepurchase,
            side,
            symbol,
            keyId,
            percentage,
            qty,
            maxaccbalance,
        }) => {
            try {
                setError(null)
                setLoading(true)

                const $side = side === 'Short' ? 'Sell' : 'Buy'

                const isValid = await validateTrades({
                    side: $side,
                    symbol,
                    keyId,
                    isRepurchase,
                })

                if (isValid) {
                    let baseBody = {
                        side: $side,
                        symbol,
                        keyId,
                        percentage,
                    }

                    const placeMarket = isRepurchase
                        ? placeMarketOrder
                        : placeMarketRatio

                    if (isRepurchase) {
                        baseBody = { ...baseBody, qty }
                    } else {
                        baseBody = { ...baseBody, maxaccbalance, leverage: 0 }
                    }

                    await dispatch(placeMarket(baseBody)).unwrap()

                    dispatch(addPendingSymbol(symbol))
                    notification.success({
                        icon: customAntNotifications.sucess,
                        message: 'Placed Order',
                        description: 'You successfully open a new trade.',
                        placement: 'bottomRight',
                    })
                }
            } catch (err) {
                setError(err)

                const errorArgs = {
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
            }
            setLoading(false)
        },
        [dispatch]
    )
    return { placeMarketOrder: $placeMarketOrder, loading, error, setError }
}

export const useCloseActiveOrder = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const { emisorSelected } = useEmisors()
    const $closeActiveOrder = useCallback(
        async ({ orderId, symbol, side }) => {
            try {
                setError(null)
                setLoading(true)
                await dispatch(
                    closeActiveOrder({
                        orderId,
                        symbol,
                        keyId: emisorSelected,
                        side,
                    })
                ).unwrap()
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Order Closed',
                    description: 'You successfully close your order',
                    placement: 'bottomRight',
                })
            } catch (err) {
                notification.error({
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                })
                console.log({ error })
                setError(err)
            }
            setLoading(false)
        },
        [dispatch, emisorSelected]
    )
    return { closeActiveOrder: $closeActiveOrder, loading, error }
}

export const useClosePosition = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const { emisorSelected } = useEmisors()
    const $closePosition = useCallback(
        async ({ orderId, symbol }) => {
            try {
                setError(null)
                setLoading(true)
                await dispatch(
                    closePosition({
                        symbol,
                        keyId: emisorSelected,
                    })
                ).unwrap()
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Close Position',
                    description: 'You successfully close your position',
                    placement: 'bottomRight',
                })
            } catch (err) {
                notification.error({
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                })
                console.log({ error })
                setError(err)
            }
            setLoading(false)
        },
        [dispatch, emisorSelected]
    )
    return { closePosition: $closePosition, loading, error }
}

export const useGetTotalAmountUsed = () => {
    const { amountPercentInTrades } = useTradesReducerState()

    return amountPercentInTrades
}

export const useSetManualTP = () => {
    const dispatch = useDispatch()
    return useCallback(
        async ({ entryPrice, ...payload }) => {
            try {
                await dispatch(setManualTp({ entryPrice, ...payload })).unwrap()
                notification.success({
                    icon: customAntNotifications.sucess,
                    message:
                        entryPrice > 0
                            ? 'Setted take profit'
                            : 'Removed take profit',
                    description:
                        entryPrice > 0
                            ? 'You successfully set a take profit to your position'
                            : 'You successfully remove your take profit from your position',
                    placement: 'bottomRight',
                })
                dispatch(reloadTrades())
            } catch (error) {
                notification.error({
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description: 'Failed to set a take profit. Try again',
                    placement: 'bottomRight',
                })
            }
        },
        [dispatch]
    )
}

export const useSetTP = () => {
    const dispatch = useDispatch()
    const { emisorSelected } = useEmisors()
    const { activeOrders } = useTradesReducerState()

    return useCallback(
        async ({ entryPrice, side, symbol, ...payload }) => {
            try {
                const activeOrder = activeOrders?.find(
                    (v) =>
                        v.side !== side && v.symbol === symbol && v.reduce_only
                )
                if (activeOrder) {
                    await dispatch(
                        closeActiveOrder({
                            orderId: activeOrder.order_id,
                            symbol,
                            keyId: emisorSelected,
                            side: side === 'Buy' ? 'Sell' : 'Buy',
                        })
                    ).unwrap()
                }
                await dispatch(
                    placeCloseLimit({ entryPrice, side, symbol, ...payload })
                ).unwrap()
                notification.success({
                    icon: customAntNotifications.sucess,
                    message:
                        entryPrice > 0
                            ? 'Setted take profit'
                            : 'Removed take profit',
                    description:
                        entryPrice > 0
                            ? 'You successfully set a take profit to your position'
                            : 'You successfully remove your take profit from your position',
                    placement: 'bottomRight',
                })
                dispatch(reloadTrades())
            } catch (error) {
                notification.error({
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description: 'Failed to set a take profit. Try again',
                    placement: 'bottomRight',
                })
            }
        },
        [activeOrders, emisorSelected, dispatch]
    )
}

export const useSetSL = () => {
    const dispatch = useDispatch()
    return useCallback(
        async ({ stopLoss, ...payload }) => {
            try {
                await dispatch(setStopLoss({ stopLoss, ...payload })).unwrap()
                notification.success({
                    icon: customAntNotifications.sucess,
                    message:
                        stopLoss > 0 ? 'Setted stop loss' : 'Removed stop loss',
                    description:
                        stopLoss > 0
                            ? 'You successfully set a stop loss to your position'
                            : 'You successfully remove your stop loss from your position',
                    placement: 'bottomRight',
                })
                dispatch(reloadTrades())
            } catch (error) {
                notification.error({
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description: 'Failed to set stop loss. Try again',
                    placement: 'bottomRight',
                })
            }
        },
        [dispatch]
    )
}

export const usePreSubscribePosition = () => {
    const dispatch = useDispatch()

    return useRetryCallback(
        async ({ symbol, side, takeProfit, keyId }) => {
            await dispatch(
                preSubscribePosition({
                    symbol,
                    side,
                    keyId,
                    takeProfit,
                })
            ).unwrap()
        },
        [dispatch]
    )
}
