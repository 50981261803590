import React, {
    useImperativeHandle,
    useCallback,
    useState,
    useMemo,
} from 'react'
import { Select, Checkbox } from 'antd'
import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'
import { numberValidation } from 'src/service/inputValidations'
import useArray from '../../../hooks/useArray'
import CapsuleField from '../../CapsuleField'
import * as yup from 'yup'
import { validations } from 'src/service/yupValidations'
import { useFormik } from 'formik'
import useDeepCompareEffect from './../../../hooks/useDeepCompareEffect'
import { ActiveButton } from './ActiveButton'
import { btcicon } from 'src/assets/images'
import {
    useAdminSymbols,
    useFetchSymbols,
} from 'src/store/reducers/adminReducer/adminReducer'

const Option = Select

const FormStrategy = React.forwardRef(
    ({ onSubmit, data, enableReinitialize = false }, ref) => {
        const symbolsList = useAdminSymbols()
        const [side, setSide] = useState('Buy')
        const [ratioStrategy, setEnableRatioStrategy] = useState(false)
        const { array: symbols, set: setSymbols } = useArray([])
        const {
            array: leverages,
            push: addLeverage,
            set: setLeverages,
            remove: removeLeverage,
        } = useArray([50])
        useFetchSymbols()
        const {
            array: tradeBalances,
            push: addTradeBalances,
            set: setTradeBalances,
            remove: removeTradeBalances,
        } = useArray([40])

        const {
            array: tradePercentages,
            push: addTradePercentages,
            set: setTradePercentages,
            remove: removeTradePercentages,
        } = useArray([20])

        const _initialState = data
            ? {
                  leverage: data?.leverage,
                  takeProfit: data?.takeProfit,
                  name: data?.name,
              }
            : {
                  leverage: '50',
                  takeProfit: '100',
                  name: '',
              }

        const schema = useMemo(() => {
            const schemaObj = {
                takeProfit: validations.noZero,
                name: validations.alphaNumericName,
            }

            if (ratioStrategy) return schemaObj
            return { ...schemaObj, leverage: validations.noZero }
        }, [ratioStrategy])

        const activeSymbols = data?.activeSymbols || []

        useDeepCompareEffect(() => {
            if (activeSymbols.length === 0) return
            setSymbols(activeSymbols.map((symbol) => symbol.id))
        }, [activeSymbols])

        const yupSchema = yup.object().shape(schema)

        const handleSubmit = useCallback(
            (values) => {
                if (ratioStrategy && leverages.length === 0) {
                    return window.alert('You have to set leverages options')
                }
                if (tradeBalances.length === 0) {
                    return window.alert(
                        'You have to set trade balances options'
                    )
                }
                if (tradePercentages.length === 0) {
                    return window.alert(
                        'You have to set trade percentages options'
                    )
                }
                if (symbols.length === 0) {
                    return window.alert('You have to set symbols options')
                }
                onSubmit({
                    ...values,
                    side,
                    tradeBalances,
                    tradePercentages,
                    leverages,
                    ratioStrategy,
                    symbols,
                })
            },
            [
                side,
                tradeBalances,
                tradePercentages,
                leverages,
                ratioStrategy,
                symbols,
                onSubmit,
            ]
        )

        const formik = useFormik({
            enableReinitialize,
            initialValues: _initialState,
            validationSchema: yupSchema,
            onSubmit: handleSubmit,
        })

        const handleSetStatesFromInitialState = (data) => {
            if (!data) return
            const {
                side,
                leverages,
                tradeBalances,
                tradePercentages,
                ratioStrategy,
                ...restInitialState
            } = data
            setSide(side)
            setLeverages(leverages)
            setTradeBalances(tradeBalances)
            setTradePercentages(tradePercentages)
            setEnableRatioStrategy(ratioStrategy)
            formik.resetForm(restInitialState)
        }

        const handleResetForm = useCallback((intialState = {}) => {
            if (data && Object.keys(data).length > 0) {
                handleSetStatesFromInitialState(data)
            } else {
                setSide('Buy')
                setLeverages([50])
                setTradeBalances([40])
                setTradePercentages([20])
                setEnableRatioStrategy(false)
                setSymbols([])
                formik.resetForm(_initialState)
            }
        }, [])

        useDeepCompareEffect(() => {
            handleSetStatesFromInitialState(data)
        }, [data])

        useImperativeHandle(ref, () => ({
            submitForm: () => formik.submitForm(),
            resetForm: handleResetForm,
        }))
        return (
            <div className="flex flex-row h-full">
                <div className="flex-auto pr-8  ">
                    <form onSubmit={formik.handleSubmit} className="pb-3">
                        <div>
                            <div className="mb-1">Strategy Name</div>
                            <FormikAntInput
                                formik={formik}
                                name="name"
                                placeholder="Strategy"
                            />
                        </div>
                        <div className="flex flex-row mt-4 mb-6">
                            <div className="">Enable Ratio Risk Strategy</div>
                            <Checkbox
                                className="ml-auto relative -mt-1"
                                checked={ratioStrategy}
                                onChange={(e) => {
                                    setEnableRatioStrategy(e.target.checked)
                                }}
                            />
                        </div>
                        {!ratioStrategy && (
                            <div className="pb-6">
                                <div className="mb-1">Side</div>
                                <Select
                                    className="w-full "
                                    value={side}
                                    onChange={(v) => {
                                        setSide(v)
                                    }}
                                >
                                    <Option value="Buy">Long</Option>
                                    <Option value="Sell">Short</Option>
                                </Select>
                            </div>
                        )}
                        <div className="flex flex-row gap-8">
                            {!ratioStrategy ? (
                                <div className="flex-1">
                                    <div className="mb-1">Leverage</div>
                                    <FormikAntInput
                                        formik={formik}
                                        name="leverage"
                                        placeholder="Leverage"
                                        showError={false}
                                        onChange={(e) => {
                                            const {
                                                target: { value, name },
                                            } = e
                                            const _value = numberValidation(
                                                value,
                                                1,
                                                0,
                                                100
                                            )

                                            if (_value)
                                                formik.setValues({
                                                    ...formik.values,
                                                    [name]: _value,
                                                })
                                        }}
                                        onBlur={(e) => {
                                            const { name, value } = e.target

                                            formik.setTouched({ [name]: true })
                                            if (value === '0') {
                                                formik.setValues({
                                                    ...formik.values,
                                                    [name]: '10',
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="flex-1">
                                    <div className="mb-1">Side</div>
                                    <Select
                                        className="w-full "
                                        value={side}
                                        onChange={(v) => {
                                            setSide(v)
                                        }}
                                    >
                                        <Option value="Buy">Long</Option>
                                        <Option value="Sell">Short</Option>
                                    </Select>
                                </div>
                            )}
                            <div className="flex-1">
                                <div className="mb-1">Take Profit</div>
                                <FormikAntInput
                                    formik={formik}
                                    name="takeProfit"
                                    placeholder="Take Profit"
                                    showError={false}
                                    onChange={(e) => {
                                        const {
                                            target: { value, name },
                                        } = e
                                        const _value = numberValidation(
                                            value,
                                            4,
                                            0
                                        )
                                        if (_value)
                                            formik.setValues({
                                                ...formik.values,
                                                [name]: _value,
                                            })
                                    }}
                                    onBlur={(e) => {
                                        const { name, value } = e.target

                                        formik.setTouched({ [name]: true })
                                        if (value === '0') {
                                            formik.setValues({
                                                ...formik.values,
                                                [name]: '100',
                                            })
                                        }
                                    }}
                                />{' '}
                            </div>
                        </div>

                        {ratioStrategy && (
                            <div className="mt-4">
                                <div className="mb-1">Leverage</div>
                                <CapsuleField
                                    name={'leverage'}
                                    capsules={leverages}
                                    onChange={(val) => addLeverage(val)}
                                    onDelete={removeLeverage}
                                />
                            </div>
                        )}
                        <div className="mt-4">
                            <div className="mb-1">Trade Balance</div>
                            <CapsuleField
                                name={'tradeBalance'}
                                capsules={tradeBalances}
                                onChange={(val) => addTradeBalances(val)}
                                onDelete={removeTradeBalances}
                            />
                        </div>
                        <div className="mt-4">
                            <div className="mb-1">Trade Percentage</div>{' '}
                            <CapsuleField
                                name={'tradesPercentage'}
                                capsules={tradePercentages}
                                onChange={(val) => addTradePercentages(val)}
                                onDelete={removeTradePercentages}
                            />
                        </div>
                    </form>
                </div>
                <div className="w-[350px] flex items-start border-l-2 border-l-gray-200 pl-8 pr-2 ">
                    <div className="w-full grid grid-cols-2 gap-3">
                        {symbolsList.map(({ id, symbol }, index) => (
                            <ActiveButton
                                key={`symbol${id}`}
                                selected={symbols.some(($id) => $id === id)}
                                onClick={() => {
                                    if (symbols.some(($id) => $id === id)) {
                                        setSymbols(
                                            symbols.filter(($id) => $id !== id)
                                        )
                                    } else {
                                        setSymbols([...symbols, id])
                                    }
                                }}
                                icon={
                                    <img
                                        src={btcicon}
                                        className="h-auto w-7 mr-3"
                                    />
                                }
                                text={symbol}
                            />
                        ))}
                    </div>
                </div>
            </div>
        )
    }
)
FormStrategy.displayName = 'FormStrategy'

export default FormStrategy
