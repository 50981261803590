// Function to add zero if the value is empty string or undefined
export function addZeroOrDefaultIfEmpty(value, defaultValue) {
    if (!value) {
        if (defaultValue !== undefined) {
            return defaultValue
        }
        return '0'
    }
    return value
}

// function to remove dot if it is the last character
export function removeDotIfLastCharacter(value) {
    if (value && value.endsWith('.')) {
        return value.slice(0, -1)
    }
    return value
}

// Function to use in the onBlure event of the input to format the value to a number
export function formatValueToNumber(value, defaultValue) {
    let newValue = addZeroOrDefaultIfEmpty(value, defaultValue)
    newValue = removeDotIfLastCharacter(newValue)

    return Number(newValue)
}

// Function to limit the minimum value of the input and if current value has a dot don't remove it unless the value is less than the minimum
export function limitMinValue(value, min, enableEmptyValue = false) {
    if ((value && Number(value) < min) || value === '') {
        if (value.includes('.')) {
            return value
        }
        if (enableEmptyValue && value === '') {
            return value
        }
        return min.toString()
    }
    return value
}

// Function to limit the maximum value of the input and if current value has a dot don't remove it unless the value is greater than the maximum
export function limitMaxValue(value, max) {
    if (value && Number(value) > max) {
        if (value.includes('.')) {
            return value
        }
        // When the value is greater than the maximum we will remove the last character of the value to avoid the value to be greater than the maximum
        return value.slice(0, -1)
    }
    return value
}

// Function to remove the dot if exists when the input is an integer
export function removeDotIfInteger(value) {
    if (value && value.includes('.')) {
        return value.slice(0, -1)
    }
    return value
}

// Function to remove extra decimals from a string number if it has more than the allowed decimals
export function removeExtraDecimals(value, decimals) {
    const valueArray = value.split('.')
    if (valueArray.length > 1) {
        const decimalsString = valueArray[1]
        if (decimalsString.length > decimals) {
            return valueArray[0] + '.' + decimalsString.slice(0, decimals)
        }
    }
    return value
}

// Function to remove non numeric characters from a string except the dot
export function removeNonNumericCharacters(value) {
    return value.replace(/[^0-9.]/g, '')
}

// Function to remove zero of the first character if it is not a decimal number (ex: 0.5) and if it is the only character of the string don't remove it (ex: 0)
export function removeZeroIfFirstCharacter(value) {
    if (
        value &&
        value.startsWith('0') &&
        !value.startsWith('0.') &&
        value.length > 1
    ) {
        return value.slice(1)
    }
    return value
}

const VALIDATIONS_KEYS = {
    MIN: 'min',
    MAX: 'max',
    ONLY_INTEGER: 'onlyInteger',
    DECIMALS: 'decimals',
}
const VALIDATIONS = {
    [VALIDATIONS_KEYS.MIN]: (value, options = {}) =>
        limitMinValue(value, options.min, options.enableEmptyValue),
    [VALIDATIONS_KEYS.MAX]: (value, options = {}) =>
        limitMaxValue(value, options.max),
    [VALIDATIONS_KEYS.ONLY_INTEGER]: (value) => removeDotIfInteger(value),
    [VALIDATIONS_KEYS.DECIMALS]: (value, options = {}) =>
        removeExtraDecimals(value, options.decimals),
}

/**
 * Options
 * min: number
 * max: number
 * onlyInteger: boolean
 * decimals: number
 * enableEmptyValue: boolean
 */
// Function to use in the onChange event of the input to format the value to a number
export function formatValueToNumberOnChange(value, options = {}) {
    let newValue = removeNonNumericCharacters(value)
    newValue = removeZeroIfFirstCharacter(newValue)

    const validations = Object.values(VALIDATIONS_KEYS).reduce((acc, key) => {
        /**
         *  1. Check if option is not undefined
         *  2. Check if option[key] exists
         *  3. Check option[key] typeof is not undefined
         *  4. If typeof is boolean check if it is true to add the validation to the array
         *  5. If typeof is number check if it is 0 or greater to add the validation to the array
         **/
        if (
            options &&
            typeof options[key] !== 'undefined' &&
            (typeof options[key] === 'boolean'
                ? options[key]
                : options[key] >= 0)
        ) {
            acc.push(key)
        }
        return acc
    }, [])

    validations.forEach((key) => {
        newValue = VALIDATIONS[key](newValue, options)
    })

    return newValue
}
