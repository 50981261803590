import { Checkbox, Input } from 'antd'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import CustomModal from './../../Modals/GenericModals/CustomModal'
// import { numberValidation } from 'src/service/inputValidations'
import { formatMoneyNumber } from 'src/utils/formatter'
import { useSetTP } from '../../../store/reducers/tradesReducer/trades.hook'
import {
    useSetManualTP,
    useTradesReducerState,
    useSetSL,
} from 'src/store/reducers/tradesReducer/trades.hook'
import { formatValueToNumberOnChange } from 'src/utils/inputsUtilities'
import { formatValueToNumber } from './../../../utils/inputsUtilities'

const initialValues = { sl: undefined, tp: undefined }

const SetSLTPModal = ({
    isManual,
    tradeData,
    visible,
    emisorSelected,
    onCancel,
    ...props
}) => {
    const { activeOrders } = useTradesReducerState()
    const [{ sl, tp, partialClose }, setValues] = useState(initialValues)
    const [manual, setManual] = useState(false)
    const [enablePartialClose, setEnablePartialClose] = useState(false)

    const setManualTp = useSetManualTP()
    const setTp = useSetTP()
    const setSL = useSetSL()

    const handleSetValues = (name, value) =>
        setValues((state) => ({ ...state, [name]: value }))

    const { entryprice, liqPrice, symbol, manualTP } = tradeData ?? {}

    const tradeManualTP = useMemo(() => {
        return activeOrders?.find((v) => v.symbol === symbol && v.reduce_only)
            ?.price
    }, [activeOrders, symbol])

    /**
     * Update state when modal is visible
     */
    const updateState = useCallback(() => {
        if (!visible) {
            setValues(initialValues)
            setManual(false)
            setEnablePartialClose(false)
        } else {
            setValues((state) => ({
                ...state,
                sl: tradeData.sl !== '0' ? tradeData.sl : undefined,
                tp: tradeManualTP ?? undefined,
            }))
            if (isManual) setManual(manualTP)
        }
    }, [tradeData, tradeManualTP, visible, manualTP, isManual])

    /**
     * Update state when user press confirm button
     */
    const setSLTP = useCallback(async () => {
        const { side, symbol, quantity } = tradeData
        const config = {
            side,
            symbol,
            keyId: emisorSelected,
        }
        if (isManual) {
            if (Number(tradeManualTP) !== Number(tp) && manual && tp)
                setManualTp({
                    ...config,
                    qty: Number(quantity),
                    entryPrice: Number(tp),
                })
        } else {
            if (Number(tradeManualTP) !== Number(tp) && tp)
                setTp({
                    ...config,
                    qty: Number(quantity),
                    entryPrice: Number(tp),
                })
        }
        if (
            Number(sl) !== Number(tradeData.sl) &&
            (sl || (!sl && Number(tradeData.sl) > 0))
        )
            setSL({
                ...config,
                stopLoss: sl ? Number(sl) : 0,
            })
        setValues(initialValues)
        onCancel()
    }, [
        isManual,
        manual,
        sl,
        tp,
        tradeManualTP,
        tradeData,
        emisorSelected,
        setManualTp,
        setSL,
    ])

    /**
     * Update state when modal is visible
     */
    useEffect(() => {
        updateState()
    }, [visible])

    /**
     * Reset TP when user change manual
     **/
    const resetTP = useCallback(() => {
        if (!manual && tp)
            setValues((state) => ({
                ...state,
                tp: undefined,
            }))
    }, [manual, tp])

    /**
     * Reset TP when user change manual
     */
    useEffect(() => {
        resetTP()
    }, [manual])

    return (
        <CustomModal
            visible={visible}
            width={400}
            title="TP/SL"
            onOk={setSLTP}
            onCancel={onCancel}
            {...props}
        >
            <form className="space-y-4">
                <div>
                    <div className="flex flex-row">
                        <div>Entry Price</div>
                        <div className="ml-auto font-medium">
                            {formatMoneyNumber(entryprice)}
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div>Liquidation Price</div>
                        <div className="ml-auto font-medium text-experts-red-3">
                            {formatMoneyNumber(liqPrice)}
                        </div>
                    </div>
                </div>

                {isManual && (
                    <div className="flex flex-row">
                        <div className="">Enable Manual Take Profit</div>
                        <Checkbox
                            className="ml-auto relative -mt-1"
                            checked={manual}
                            onChange={(e) => {
                                if (!manualTP) setManual(e.target.checked)
                            }}
                        />
                    </div>
                )}
                <div className="flex flex-row">
                    <div className="">Enable Partial Close</div>
                    <Checkbox
                        className="ml-auto relative -mt-1"
                        checked={enablePartialClose}
                        onChange={(e) => {
                            setEnablePartialClose(e.target.checked)
                        }}
                    />
                </div>

                <div className="">
                    <div className="">Take Profit</div>
                    <Input
                        name="tp"
                        id="tp"
                        value={tp}
                        disabled={!manual && isManual}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e

                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 6,
                                min: 0,
                            })
                            handleSetValues(name, $value)
                        }}
                        onBlur={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            handleSetValues(name, formatValueToNumber(value))
                        }}
                    />
                </div>

                <div className="">
                    <div className="">Stop Loss</div>
                    <Input
                        name="sl"
                        id="sl"
                        value={sl}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 6,
                                min: 0,
                                enableEmptyValue: true,
                            })
                            handleSetValues(name, $value)
                        }}
                        onBlur={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            if (value !== '')
                                handleSetValues(
                                    name,
                                    formatValueToNumber(value)
                                )
                        }}
                    />
                </div>
                <div className="">
                    <div className="">Quantity Percentage (%)</div>
                    <Input
                        name="partialClose"
                        id="partialClose"
                        value={partialClose}
                        disabled={!enablePartialClose}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e

                            const $value = formatValueToNumberOnChange(value, {
                                min: 0,
                                max: 100,
                                decimals: 2,
                            })
                            handleSetValues(name, $value)
                        }}
                        onBlur={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            handleSetValues(name, formatValueToNumber(value))
                        }}
                    />
                </div>
            </form>
        </CustomModal>
    )
}

export default SetSLTPModal
