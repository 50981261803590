import React from 'react'
// import useResponsiveValue from 'src/hooks/useResponsiveValue'
// import Button from 'src/components/Buttons/Button'
// import Icon from 'src/components/Icons/Icon'
import AddKey from './AddKey'
import {
    useEmisors,
    useFetchKeysEmisor,
} from 'src/store/reducers/dashboardReducer/dashboardReducer'
import ActiveKeys from './ActiveKeys'
import { useFetchShareKeys } from 'src/store/reducers/adminReducer/adminReducer'

const SubAccountsPage = (props) => {
    useFetchKeysEmisor()
    useFetchShareKeys()
    const { userEmisors } = useEmisors()
    // const [visible, setVisible] = useState(false)

    // const buttonSize = useResponsiveValue({
    //     base: 'small',
    //     lg: 'normal',
    // })

    // console.log({ userEmisors, visible })
    return (
        <div className="w-full h-full">
            <div className="flex flex-row items-end justify-between">
                <div className="text-lg font-bold">Add Keys</div>
                {/* <Button
                    size={buttonSize}
                    onClick={() => setVisible(true)}
                    variant="gradient"
                    className="w-full lg:w-auto text-sm lg:text-lg"
                >
                    <Icon name="linkAcc" className="h-[18px] w-[18px] mr-3" />
                    <div className="">Add</div>
                </Button> */}
            </div>{' '}
            <AddKey firstAccount={userEmisors.length === 0} />
            <ActiveKeys />
        </div>
    )
}

export default SubAccountsPage
