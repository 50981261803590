import React, { useCallback, useRef } from 'react'

import { useCreateStrategy } from '../../../store/reducers/strategies/strategiesReducer'
import CustomModal from 'src/components/Modals/GenericModals/CustomModal'
import FormStrategy from './FormStrategy'

const AddStrategyModal = ({ visible = false, onClose, onCreateStrategy }) => {
    const { createStrategy, loading } = useCreateStrategy()
    const formRef = useRef(null)

    const handleClose = () => {
        formRef.current.resetForm()
        onClose()
    }

    const handleSubmit = useCallback(
        async ({
            name,
            leverage,
            takeProfit,
            side,
            tradeBalances,
            tradePercentages,
            leverages,
            ratioStrategy,
            symbols,
        }) => {

            createStrategy(
                name,
                side,
                ratioStrategy ? leverages : [Number(leverage)],
                takeProfit,
                tradeBalances,
                tradePercentages,
                Math.max(...tradeBalances),
                ratioStrategy,
                symbols
            ).then((res) => {
                handleClose()
                onCreateStrategy(res)
            })
        },
        [createStrategy]
    )

    const handleOkay = useCallback(() => {
        formRef.current.submitForm()
    }, [])

    return (
        <CustomModal
            loading={loading}
            visible={visible}
            title={'Add New Strategy'}
            width={750}
            onCancel={handleClose}
            onOk={handleOkay}
        >
            <FormStrategy ref={formRef} onSubmit={handleSubmit} />
        </CustomModal>
    )
}

export default AddStrategyModal
