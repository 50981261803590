import React from 'react'
import { Modal } from 'antd'

import Button from './../../Buttons/Button'

const ConfirmModal = ({
    visible,
    onClose,
    onConfirm,
    confirmButtonText = 'CONFIRM',
    children,
    loading,
    width = 526,
}) => {
    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={width}
        >
            <div className="w-full pt-8">
                {children}
                {!loading ? (
                    <div className="flex justify-center w-full gap-4 mt-6">
                        <Button
                            className="w-32"
                            variant="light"
                            onClick={onClose}
                        >
                            CANCEL
                        </Button>
                        <Button className="w-32" onClick={onConfirm}>
                            {confirmButtonText}
                        </Button>
                    </div>
                ) : (
                    <div className="flex justify-center gap-4 mt-6">
                        <Button
                            loading
                            className="w-32"
                            onClick={onConfirm}
                        ></Button>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default ConfirmModal
