import React from 'react'

import GenericModal from './../Modals/GenericModals/GenericModal'

const ActiveMMStrategyModal = ({ strategyName, message = '', ...props }) => {
    return (
        <GenericModal {...props}>
            <div className="text-center">
                <div className="text-2xl mb-2">{message}</div>
                <div className="text-4xl font-semibold text-experts-green-2">
                    {strategyName}
                </div>
            </div>
        </GenericModal>
    )
}

export default ActiveMMStrategyModal
