import React, { useImperativeHandle, useCallback } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Select } from 'antd'

const CryptoCurrenciesForm = React.forwardRef(({ onSubmit, symbols }, ref) => {
    const _initialState = {
        crypto: '',
    }

    const yupSchema = yup.object().shape({
        crypto: yup.string(),
    })

    const handleSubmit = useCallback(
        (values) => {
            onSubmit({
                ...values,
            })
        },
        [onSubmit]
    )

    const formik = useFormik({
        initialValues: _initialState,
        validationSchema: yupSchema,
        onSubmit: handleSubmit,
    })

    useImperativeHandle(ref, () => ({
        submitForm: () => formik.submitForm(),
        resetForm: () => formik.resetForm(_initialState),
    }))

    return (
        <form onSubmit={formik.handleSubmit} className="pb-3">
            <div className="mb-1">Select Crypto Currency</div>
            <div>
                <Select
                    showSearch
                    className="w-full"
                    onSelect={formik.handleChange}
                    onChange={(value) => {
                        formik.setFieldValue('crypto', value)
                    }}
                    value={formik.values?.crypto}
                    placeholder="Select Crypto"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={symbols.map((symbol) => {
                        return { value: symbol, label: symbol }
                    })}
                />
            </div>
        </form>
    )
})
CryptoCurrenciesForm.displayName = 'CryptoCurrenciesForm'

export default CryptoCurrenciesForm
