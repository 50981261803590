import React from 'react'
import CustomModal from 'src/components/Modals/GenericModals/CustomModal'

const AddKeyModal = ({ loading, visible = false, onClose, onSave, data }) => {
    return (
        <CustomModal
            loading={loading}
            visible={visible}
            title={'Add New Key'}
            width={450}
            onCancel={onClose}
            onOk={onSave}
        >
            {' '}
            <div className="flex flex-col gap-2 text-center">
                <div className="mx-auto text-base  font-semibold ">
                    Make sure that all information entered is correct before
                    proceeding.
                </div>
            </div>
        </CustomModal>
    )
}

export default AddKeyModal
