import React from 'react'
import Button from 'src/components/Buttons/Button'
import numeral from 'numeral'
import Icon from 'src/components/Icons/Icon'
import { TITLE_BY_TYPE } from 'src/constants/phase.constants'

const StageHeader = ({ children }) => (
    <div className=" font-medium text-[8px] lg:text-xs text-experts-gray-5 tracking-wider min-w-fit">
        {children}
    </div>
)

const StageItem = ({
    index = 0,
    disabledButtons,
    data,
    hideDelete,
    onRemoveStage,
    onEdit,
}) => {
    const { action, condition, size, takeProfit } = data

    return (
        <div className="py-2 lg:py-5 px-3 lg:px-6 shadow-experts-lg bg-white rounded-lg">
            <div className="grid grid-cols-6">
                <div className="flex flex-col justify-between gap-2 lg:gap-3">
                    <StageHeader>Action</StageHeader>
                    <div className="font-bold text-black tracking-wider text-xs lg:text-base">
                        Stage {index + 1}
                    </div>
                </div>
                <div className="flex flex-col justify-between gap-2 lg:gap-3">
                    <StageHeader>Action to take</StageHeader>

                    <div className="font-medium text-black tracking-wider text-[9px] lg:text-sm">
                        {TITLE_BY_TYPE[action]}
                    </div>
                </div>
                <div className="flex flex-col justify-between gap-2 lg:gap-3">
                    <StageHeader>Trigger at negative %</StageHeader>
                    <div className="font-medium text-black tracking-wider text-[10px] lg:text-sm">
                        {condition}
                    </div>
                </div>
                <div className="flex flex-col justify-between gap-2 lg:gap-3">
                    <StageHeader>Change To</StageHeader>
                    <div className="font-medium text-black tracking-wider text-[10px] lg:text-sm">
                        {numeral(size).format('0.00')}x
                    </div>
                </div>
                <div className="flex flex-col justify-between gap-2 lg:gap-3">
                    <StageHeader>Take Profit %</StageHeader>
                    <div className="font-medium text-black tracking-wider text-[10px] lg:text-sm">
                        {takeProfit}
                    </div>
                </div>
                <div className="flex justify-end items-end gap-3 lg:gap-6">
                    <Button
                        variant="lightPrimary"
                        size=""
                        className="w-6 h-6 lg:w-10 lg:h-10"
                        onClick={onEdit}
                        disabled={disabledButtons}
                    >
                        <Icon
                            name="3-edit"
                            className="w-3 h-3 lg:w-5 lg:h-5 text-experts-green-7"
                        />
                    </Button>
                    {!hideDelete && (
                        <Button
                            variant="lightDanger"
                            size=""
                            onClick={onRemoveStage}
                            disabled={disabledButtons}
                            className="w-6 h-6 lg:w-10 lg:h-10 text-xs lg:text-lg"
                        >
                            <Icon
                                name="3-trash"
                                className="w-3 h-3 lg:w-5 lg:h-5"
                            />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default StageItem
