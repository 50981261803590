import {
    createReducer,
    createAsyncThunk,
    createAction,
    createSelector,
} from '@reduxjs/toolkit'
import axiosInstance from './../../services/axiosConfig'
import globalTypes from '../globalTypes'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { sleep } from './../../../utils/promise'
import useAuth from './../../../hooks/useAuth'
import { notification } from 'antd'
import customAntNotifications from 'src/service/customAntNotifications'

const uuid = require('uuid')

export const updateScreenAccess = createAction('auth/updateScreenAccess')
export const logout = createAction(globalTypes.LOG_OUT)
export const updateSignInSteps = createAction('auth/updateSignInSteps')
export const updateStaked = createAction('auth/setStaked')

export const setAuth = createAction('auth/setAut')

export const login = createAsyncThunk(
    'auth/login',
    async ({ user, password }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post('bybit/login/', {
                username: user,
                password,
            })
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const signup = createAsyncThunk(
    'auth/signUp',
    async ({ user, password, email, address }, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('bybit/register/', {
                username: user,
                email,
                password,
                address,
            })

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const createQr2fa = createAsyncThunk(
    'auth/2fa/createQr',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get('accounts/totp/create/')

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const verify2fa = createAsyncThunk(
    'auth/2fa/verifyQr',
    async (token, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post(
                `accounts/totp/login/${token}/`
            )

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const sendEmailForgotPassord = createAsyncThunk(
    'auth/forgotpassword/sendEmail',
    async (email, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post(`bybit/recoverPassword/`, {
                email,
            })

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const updatePassword = createAsyncThunk(
    'auth/forgotpassword/update',
    async ({ currentPassword, password }, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post(`bybit/changePassword/`, {
                currentPassword,
                password,
            })

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

const selectSelf = (state) => state.authReducer

const selectAuth = createSelector(selectSelf, (self) => self.auth)
const selectScreenAccess = createSelector(selectSelf, (self) => self.screen)

const selectVerifiedCode = createSelector(
    selectSelf,
    (self) => self.totp.verifiedCode
)

const selectQrBase64 = createSelector(selectSelf, (self) => self.totp.qrBase64)
const selectSignInSteps = createSelector(selectSelf, (self) => self.signInSteps)

export const useScreenAccess = () => useSelector(selectScreenAccess)
export const useSignInSteps = () => useSelector(selectSignInSteps)

export const useIsLoggedIn = () => {
    return useSelector(selectAuth)
}

export const useIsAuth = () => {
    const verifiedCode = useSelector(selectVerifiedCode)
    const auth = useSelector(selectAuth)

    return useMemo(() => {
        return auth && !verifiedCode
    }, [verifiedCode, auth])
}

export const useQrBase64 = () => useSelector(selectQrBase64)

export const useSignUp = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const action = useCallback(
        async ({ userName, password, email }) => {
            setLoading(true)
            if (loading) return

            try {
                await sleep(600)
                await dispatch(
                    signup({
                        user: userName,
                        password,
                        email,
                        address: uuid.v4(),
                    })
                ).unwrap()
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Create New Account',
                    description: 'You successfully create a new account',
                    placement: 'bottomRight',
                })
            } catch (err) {
                setError(err)
                const errorArgs = {
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
                throw error
            } finally {
                setLoading(false)
            }
        },
        [loading, dispatch]
    )

    return { signUp: action, loading, error }
}

export const useGenerate2faQR = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const base64 = useQrBase64()
    const dispatch = useDispatch()

    const createQr = useCallback(async () => {
        setLoading(true)
        if (loading) return
        try {
            await sleep(1000)
            await dispatch(createQr2fa()).unwrap()
        } catch (error) {
            console.log('Error Generate QR:', error)
            setError(error)
        } finally {
            setLoading(false)
        }
    }, [dispatch])

    useEffect(() => {
        createQr()
    }, [])

    return { base64, loading, error, setError, reload: createQr }
}

export const useVerifyCode = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const verifyCode = useCallback(
        async (code) => {
            setLoading(true)
            if (loading) return
            try {
                await sleep(1000)
                await dispatch(verify2fa(code)).unwrap()
            } catch (error) {
                console.log('Error Generate QR:', error)
                setError('Invalid Code')
            } finally {
                setLoading(false)
            }
        },
        [dispatch]
    )

    return { loading, error, verifyCode }
}

export const useUpdateScreenAccess = () => {
    const dispatch = useDispatch()

    return useCallback(
        (screen) => dispatch(updateScreenAccess(screen)),
        [dispatch]
    )
}

export const useResetScreenAccess = (cancelReset) => {
    const screen = useScreenAccess()
    const { logout } = useAuth()

    useEffect(() => {
        if (screen > 0 && !cancelReset) {
            logout()
        }
    }, [screen, cancelReset])
}

export const useUpdateSignInSteps = () => {
    const dispatch = useDispatch()

    return useCallback(
        (payload) => dispatch(updateSignInSteps(payload)),
        [dispatch]
    )
}

export const SIGN_IN_SCREEN = 1
export const LOG_IN_SCREEN = 2
export const FORGOT_PASSWORD_SCREEN = 3

export const initialState = {
    token: '',
    auth: false,
    screen: 0,

    isSigningUp: false,
    isLogingIn: false,
    signInSteps: {
        stake: false,
        scan: false,
    },
    totp: {
        qrBase64: '',
        verifiedCode: false,
    },
}

const authReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(login.fulfilled, (state, { payload }) => {
            localStorage.setItem('token', payload.token)
            state.token = payload.token
        })
        .addCase(setAuth, (state, { payload }) => {
            state.auth = true
        })
        .addCase(updateScreenAccess, (state, { payload }) => {
            state.screen = payload
        })
        .addCase(
            createQr2fa.fulfilled,
            (state, { payload: { imageBase64 } }) => {
                const data = JSON.parse(imageBase64)

                state.totp.qrBase64 = data.res
            }
        )
        .addCase(createQr2fa.pending, (state) => {
            state.totp.qrBase64 = ''
        })
        .addCase(verify2fa.fulfilled, (state) => {
            localStorage.setItem('verified', true)
            state.totp.qrBase64 = ''
            state.totp.verifiedCode = true
            state.screen = 0
            state.signInSteps = {
                stake: false,
                scan: false,
            }
        })
        .addCase(updateSignInSteps, (state, { payload }) => {
            const incomingUpdate = Object.keys(payload).reduce((acc, key) => {
                if (typeof state.signInSteps[key] !== 'undefined') {
                    return { ...acc, [key]: payload[key] }
                }
                return acc
            }, {})

            state.signInSteps = {
                ...state.signInSteps,
                ...incomingUpdate,
            }
        })
})

export default authReducer
