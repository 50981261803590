import React, { useEffect, useMemo, useState } from 'react'
import { Alert } from 'antd'
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets'
import EmisorSelect from 'src/components/EmisorSelect'
import { useSelectedEmisorInfo } from './../store/reducers/dashboardReducer/dashboardReducer'
// import { useUserInfo } from './../store/reducers/userInfoReducer/userInfoReducer'
// import { Navigate } from 'react-router-dom'
import TokenTrade from 'src/components/PlaceTradeComponents/TokenTrade'
import TradesAndOrders from 'src/components/PlaceTradeComponents/TradesAndOrders'
import SelectSymbol from './../components/PlaceTradeComponents/SelectSymbol'
import {
    useGetTotalAmountUsed,
    useSelectedSymbol,
    useSetSide,
    useTradesReducerState,
} from '../store/reducers/tradesReducer/trades.hook'
import Button from 'src/components/Buttons/Button'
import { useStrategieState } from 'src/store/reducers/strategies/strategiesReducer'
import useInterval from 'src/hooks/useInterval'
import TokenTrade2 from 'src/components/PlaceTradeComponents/TokenTrade2'

const ShowAlert = () => {
    const emisorSelectedInfo = useSelectedEmisorInfo()
    return emisorSelectedInfo?.shared && !emisorSelectedInfo?.sharedActive ? (
        <Alert
            type="warning"
            message="Shared account is not active at this moment, enable it in order to start trading."
        />
    ) : null
}

const CardsTrades = ({ symbol }) => {
    const { setSide, side } = useSetSide()
    const [reload, setReload] = useState(false)
    const { sharedActive, shared } = useSelectedEmisorInfo() ?? {}
    const { executing } = useTradesReducerState()
    const disabledTrade = executing || (shared && !sharedActive)

    const totalBalanceUsed = useGetTotalAmountUsed()
    const { strategies } = useStrategieState()

    const selectedStrategy = useMemo(() => {
        if (strategies.length === 0) return undefined
        const _side = side === 'Long' ? 'Buy' : 'Sell'
        return strategies.find((v) => v.side === _side && v.active)
    }, [strategies, side])

    useEffect(() => {
        setSide('Long')
    }, [])

    useInterval(
        () => {
            setReload(false)
        },
        reload ? 50 : null
    )

    return (
        <div className="">
            <div className="flex flex-row space-x-2 mb-3">
                <Button
                    className="rounded-none w-full shadow-none"
                    size="small"
                    variant={side === 'Long' ? 'primary' : 'gray'}
                    onClick={() => {
                        if (side !== 'Long') {
                            setReload(true)
                            setSide('Long')
                        }
                    }}
                >
                    Long
                </Button>
                <Button
                    className="rounded-none w-full shadow-none"
                    size="small"
                    variant={side === 'Short' ? 'danger' : 'gray'}
                    onClick={() => {
                        if (side === 'Long') {
                            setReload(true)
                            setSide('Short')
                        }
                    }}
                >
                    Short
                </Button>
            </div>
            <div className="">
                {!reload && selectedStrategy ? (
                    !selectedStrategy?.ratioStrategy ? (
                        <TokenTrade
                            orderType={side}
                            disabled={disabledTrade}
                            symbol={symbol}
                            totalBalanceUsed={totalBalanceUsed}
                            selectedStrategy={selectedStrategy}
                        />
                    ) : (
                        <TokenTrade2
                            orderType={side}
                            disabled={disabledTrade}
                            symbol={symbol}
                            totalBalanceUsed={totalBalanceUsed}
                            selectedStrategy={selectedStrategy}
                        />
                    )
                ) : null}
            </div>
        </div>
    )
}

const PlaceTradeView = (props) => {
    const selectedSymbol = useSelectedSymbol()

    // Componente de trading view se recarga cada vez que el componente papa recibe una actualizacion de los estados que consulta
    return (
        <div className="w-full flex flex-col md:flex-row gap-4 lg:gap-10  pb-16">
            <div className="w-full lg:pb-6 flex-grow flex flex-col gap-4 xl:gap-6">
                <div className="flex flex-row text-lg xl:text-2xl font-extrabold w-full">
                    <div className="">
                        <EmisorSelect />
                    </div>
                    <div className="ml-auto">
                        <SelectSymbol />
                    </div>
                </div>

                <ShowAlert />
                <div className="flex justify-start font-bold text-experts-gray-9 text-[16px] pl-2">
                    Trades
                </div>
                <div className="flex flex-col lg:flex-row gap-5">
                    <div className="h-[45vh] md:h-[50vh] xl:h-[55vh] mb-8 bg-[#131622] w-full lg:w-[77%]">
                        <AdvancedRealTimeChart
                            theme="dark"
                            autosize
                            symbol={`BYBIT:${selectedSymbol}.P`}
                            allow_symbol_change={false}
                        />
                    </div>
                    <div className="w-full lg:w-[23%]">
                        <CardsTrades symbol={selectedSymbol} />
                    </div>
                </div>
                <TradesAndOrders />
            </div>
        </div>
    )
}

export default PlaceTradeView
