/* eslint-disable camelcase */
import {
    createReducer,
    createAsyncThunk,
    createAction,
    isAnyOf,
} from '@reduxjs/toolkit'
import axiosInstance from '../../services/axiosConfig'
import { useSelector, useDispatch } from 'react-redux'
import { useUserInfo } from './../userInfoReducer/userInfoReducer'
import { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import { updateArrayPosition } from 'src/utils/arrays'
import useRefresh from 'src/hooks/useRefresh'
import { sleep } from 'src/utils/promise'
import { useIsAuth } from 'src/store/reducers/auth/authReducer'
import axios from 'axios'
import { notification } from 'antd'
import customAntNotifications from 'src/service/customAntNotifications'

export const setEmisors = createAction('dashboard/setEmisors')
export const setSelectedEmisor = createAction('dashboard/selectedEmisor')
export const setEmisorSubs = createAction('dashboard/setEmisorSubs')

export const activateTrading = createAsyncThunk(
    'dashboard/activateTrading',
    async (keyId, { rejectWithValue, getState, dispatch }) => {
        try {
            const res = await axiosInstance.post(
                `bybit/tradingmanager/activatetrading/`,
                {
                    id: keyId,
                }
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)
export const deactivateTrading = createAsyncThunk(
    'dashboard/deactivateTrading',
    async (keyId, { rejectWithValue, getState, dispatch }) => {
        try {
            const res = await axiosInstance.post(
                `bybit/tradingmanager/deactivatetrading/`,
                {
                    id: keyId,
                }
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const saveKeys = createAsyncThunk(
    'dashboard/savekeys',
    async (
        { name, key, secret, maxTrades, btcEth, altcoin, riskPorc },
        { rejectWithValue }
    ) => {
        try {
            const res = await axiosInstance.post(
                'bybit/tradingmanager/createtraderkeys/',
                {
                    name: name.trim(),
                    key: key.trim(),
                    secret: secret.trim(),
                    trades: maxTrades,
                    highCapLev: btcEth,
                    lowCapLev: altcoin,
                    maxRisk: riskPorc,
                    linkedAccount: 0,
                }
            )
            return res.data
        } catch (error) {
            if (!error.response) throw error

            return rejectWithValue(error.response.data)
        }
    }
)

export const getKeyEmisors = createAsyncThunk(
    'dashboard/keyemisors',
    async (cancelToken, { rejectWithValue, getState, dispatch }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/tradingmanager/bybitkeysshared/`,
                { cancelToken }
            )
            const emisors = res.data.map((v, index) => ({ ...v, index }))
            let data = res.data.map((v, index) => ({ ...v, index }))
            if (data.filter((v) => v.shared && v.sharedActive).length > 0)
                data = data.filter((v) => v.shared && v.sharedActive)
            // const emisors = data.filter((v) => v.user !== userId)
            // const userEmisors = data.filter((v) => v.user === userId)

            dispatch(setEmisors({ emisors, userEmisors: data }))

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

const getKeyEmisorsSubs = createAsyncThunk(
    'dashboard/keyemisorssubs',
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/config/keysemisorsubs/${id}`
            )

            dispatch(setEmisorSubs({ id, data: res.data }))
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

const switchTradingManager = createAsyncThunk(
    'dashboard/keyemisorssubs',
    async ({ oldAccount, newAccount }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post(
                `bybit/tradingmanager/switchtradingmanager/`,
                { linkedAccountOld: oldAccount, linkedAccountNew: newAccount }
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

const selectSelf = (state) => state.dashboardReducer

export const useDashboardReducerState = () => {
    return useSelector(selectSelf)
}

// const emisorsSelect = createSelector(selectSelf, (self) => self.emisors)

export const useSwitchTradingManager = () => {
    const dispatch = useDispatch()

    return useCallback(
        (oldAccount, newAccount) =>
            dispatch(switchTradingManager({ oldAccount, newAccount })),
        [dispatch]
    )
}

export const useFetchKeysEmisor = () => {
    const { fastRefresh } = useRefresh()
    const { reload } = useDashboardReducerState()
    const [fetching, setFetching] = useState(false)
    const auth = useIsAuth()
    const lastReloadValue = useRef(null)
    const cancelCall = useRef(null)
    const userInfo = useUserInfo()
    const dispatch = useDispatch()

    const $getKeysEmisor = useCallback(async () => {
        if (fetching && reload !== lastReloadValue.current) {
            console.log('Cancel Order')
            cancelCall.current.cancel('Reload Executed')
        }
        setFetching(true)
        cancelCall.current = axios.CancelToken.source()

        dispatch(getKeyEmisors(cancelCall.current.token))
    }, [dispatch, userInfo, fetching])

    useEffect(() => {
        if (auth) $getKeysEmisor()
        if (reload !== lastReloadValue.current) lastReloadValue.current = reload
    }, [auth, fastRefresh, reload])
}

export const useEmisors = () => {
    const { emisors, userEmisors, emisorSelected } = useDashboardReducerState()

    return { emisors, userEmisors, emisorSelected }
}

export const useFetchSubFromSelectedEmisor = (keyId) => {
    // const { userEmisors } = useEmisors()
    const dispatch = useDispatch()

    const handleGetKeyEmisorSubs = useCallback(async () => {
        if (keyId !== undefined) {
            await dispatch(getKeyEmisorsSubs(keyId)).unwrap()
        }
    }, [keyId])

    useEffect(() => {
        handleGetKeyEmisorSubs()
    }, [keyId])

    return { reload: handleGetKeyEmisorSubs }
}
export const useSelectedEmisorInfo = () => {
    const { userEmisors, emisorSelected } = useEmisors()

    return useMemo(() => {
        return userEmisors.find((v) => v.id === emisorSelected)
    }, [userEmisors, emisorSelected])
}

export const useSetSelectedEmisor = (index) => {
    const dispatch = useDispatch()

    return useCallback((id) => dispatch(setSelectedEmisor(id)), [dispatch])
}

export const useEnableTrading = (active) => {
    const [executing, setExecuting] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const action = useCallback(
        async (keyId) => {
            try {
                setError(null)
                setExecuting(true)
                await sleep(600)
                if (active) await dispatch(deactivateTrading(keyId)).unwrap()
                else await dispatch(activateTrading(keyId)).unwrap()
            } catch (error) {
                console.log(error)
                window.alert(error?.error ?? 'Something went wrong try again')
                setError(error)
            }
            setExecuting(false)
        },
        [active]
    )
    return {
        action,
        executing,
        error,
    }
}

export const useSaveKey = (active) => {
    const [executing, setExecuting] = useState(false)
    const [error, setError] = useState(null)

    const dispatch = useDispatch()

    const action = useCallback(
        async (payload) => {
            setError(null)
            setExecuting(true)

            try {
                await sleep(600)
                await dispatch(saveKeys(payload)).unwrap()
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Saved Key',
                    description: 'You successfully save a new key',
                    placement: 'bottomRight',
                })
            } catch (err) {
                setError(err)
                const errorArgs = {
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
                throw err
            } finally {
                setExecuting(false)
            }
        },
        [dispatch]
    )

    return { saveKey: action, executing, error }
}

export const initialState = {
    emisorSelected: null,
    userEmisors: [],
    emisors: [],
    reload: false,
}

const dashboardReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setSelectedEmisor, (state, { payload }) => {
            state.emisorSelected = payload
        })
        .addCase(setEmisorSubs, (state, { payload: { id, data } }) => {
            const index = state.userEmisors.findIndex((v) => v.id === id)
            const userEmisors = updateArrayPosition(state.userEmisors, index, {
                ...state.userEmisors[index],
                subs: data !== '' ? data : 0,
            })
            return { ...state, userEmisors }
        })
        .addCase(setEmisors, (state, { payload: { emisors, userEmisors } }) => {
            let emisorSelected = null
            if (
                (!state.emisorSelected && userEmisors.length > 0) ||
                (state.emisorSelected &&
                    userEmisors.filter((v) => v.id === state.emisorSelected)
                        .length === 0)
            ) {
                emisorSelected = userEmisors[0].id
            } else emisorSelected = state.emisorSelected

            return {
                ...state,
                emisorSelected,
                emisors,
                userEmisors: userEmisors.reduce((acc, v) => {
                    const index = state.userEmisors.findIndex(
                        (i) => i.id === v.id
                    )

                    if (index === -1) {
                        return [...acc, v]
                    }
                    return [...acc, { ...state.userEmisors[index], ...v }]
                }, []),
            }
        })
        .addMatcher(
            isAnyOf(
                activateTrading.fulfilled,
                deactivateTrading.fulfilled,
                saveKeys.fulfilled
            ),
            (state) => {
                state.reload = !state.reload
            }
        )
})

export default dashboardReducer
