import React from 'react'
import CryptoCurrencyCard from './CryptoCurrencyCard'

const CryptoCurrenciesList = ({ symbols }) => {
    return (
        <div className="grid sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-y-3 gap-x-10 mt-4">
            {symbols?.map((symbol, index) => {
                return (
                    <CryptoCurrencyCard
                        key={`symbols/${symbol.id}`}
                        data={symbol}
                    />
                )
            })}
        </div>
    )
}

export default CryptoCurrenciesList
