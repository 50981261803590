/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useMemo } from 'react'

import {
    useEmisors,
    useFetchSubFromSelectedEmisor,
    useEnableTrading,
    useSelectedEmisorInfo,
} from '../store/reducers/dashboardReducer/dashboardReducer'
// import {} from 'antd/lib/mentions'

import EmisorSelect from 'src/components/EmisorSelect'
import SwitchEmisorOwner from 'src/components/ToggleComponents/SwitchEmisorOwner'

import TableActiveTraders from 'src/components/ToggleComponents/TableActiveTraders'
import { Switch } from 'antd'
import { useUserInfo } from 'src/store/reducers/userInfoReducer/userInfoReducer'
import SectionCalculator from 'src/components/ToggleComponents/SectionCalculator'

// const ShareCode = ({ profitFee }) => {
//     return (
//         <div className="flex flex-col md:flex-row  lg:flex-col gap-4 xl:gap-6">
//             <div className="w-full bg-white rounded-lg shadow-experts-lg px-8 lg:px-5 xl:px-10 py-7">
//                 <div className="flex flex-col gap-4">
//                     <div className="flex flex-col justify-end items-center">
//                         <div className="font-medium text-xs xl:text-sm  tracking-wider">
//                             ACTIVATION CODE
//                         </div>
//                         <div className="mt-5 xl:mt-7 text-2xl xl:text-3xl  font-extrabold leading-none tracking-wider">
//                             asdasd
//                         </div>
//                     </div>

//                     <Button>Copy</Button>
//                 </div>
//             </div>
//         </div>
//     )
// }

const ToggleView = (props) => {
    const [showModal, setShowModal] = useState(false)

    const [selectedKey, setSelectedKey] = useState(null)

    const { userEmisors, emisors, emisorSelected } = useEmisors()
    const { isSuperuser } = useUserInfo()
    const { reload } = useFetchSubFromSelectedEmisor(emisorSelected)

    const handleOnSelect = useCallback((trader) => {
        setSelectedKey(trader.index)
        setShowModal(true)
    }, [])

    const emisorSelectedInfo = useSelectedEmisorInfo()

    const { action: enableTrading, executing } = useEnableTrading(
        emisorSelectedInfo?.sharedActive
    )

    const sharedKey = useMemo(() => {
        return userEmisors.find((v) => v?.shared)
    }, [userEmisors])

    return (
        <div className="flex flex-col md:flex-row gap-4 lg:gap-10 xl:pr-12">
            <div className="lg:pb-6 flex-grow flex flex-col gap-4 xl:gap-6">
                <div className="flex flex-row w-full pb-4 xl:pb-6">
                    <div className="text-lg xl:text-2xl font-extrabold border-0 flex flex-row items-end gap-8">
                        <EmisorSelect />
                    </div>
                    <div className="ml-auto flex items-end">
                        {/* <div className="mt-auto flex items-center mr-8 xl:mr-16 bg-red-100">
                            <div className="h-9 xl:h-12 flex flex-row items-center px-4 xl:px-6 shadow-experts-lg bg-white rounded-lg">
                                <div className="w-[18px] xl:w-[21px] h-[18px] xl:h-[21px] bg-experts-purple-1 border border-experts-gray-2 shadow-inner shadow-[#00000029] rounded-full flex mr-4 xl:mr-6">
                                    <div className="h-2 xl:w-[11px] w-2  xl:h-[11px] bg-experts-green-2 my-auto mx-auto rounded-full"></div>
                                </div>
                                <div className="text-xs xl:text-sm font-medium text-experts-gray-7 tracking-wider">
                                    Using MM
                                </div>
                            </div>
                        </div> */}

                        {sharedKey && (
                            <div className="">
                                <div className="text-xs xl:text-sm">
                                    {sharedKey?.sharedActive
                                        ? 'Stop Trading'
                                        : 'Start Trading'}
                                </div>
                                <div className="mt-1">
                                    <Switch
                                        loading={executing}
                                        className="w-16"
                                        size="small"
                                        onChange={() => {
                                            enableTrading(sharedKey.id)
                                        }}
                                        checked={sharedKey.sharedActive}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isSuperuser && <SectionCalculator />}
                {/* <div>
                    <TableActiveTraders
                        emisorSelected={userEmisors[selectedKey]}
                        emisors={emisors.map((v) => ({
                            ...v,
                            status: 'active',
                        }))}
                        onSelect={handleOnSelect}
                    />
                </div>

                <SwitchEmisorOwner
                    visible={showModal}
                    selectedKey={selectedKey}
                    currentEmisor={
                        userEmisors ? userEmisors[emisorSelected] : null
                    }
                    reloadSubs={reload}
                    onClose={() => setShowModal(false)}
                /> */}
            </div>{' '}
            {/* <div className="w-full md:w-[250px] xl:w-[392px] pb-6">
                <ShareCode />
            </div> */}
        </div>
    )
}

ToggleView.propTypes = {}

export default ToggleView
