import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { Input, Radio } from 'antd'
import {
    usePlaceLimitOrder,
    useTradesReducerState,
    usePlaceMarketOrder,
    useSymbolsUsed,
    useSetChildActiveTrades,
    useRepurchaseLimitOrder,
} from 'src/store/reducers/tradesReducer/trades.hook'
import useResponsiveValue from 'src/hooks/useResponsiveValue'

import { useEmisors } from './../../store/reducers/dashboardReducer/dashboardReducer'
import Button from 'src/components/Buttons/Button'
import { numberValidation } from 'src/service/inputValidations'
import ActiveChildsTrades from './ActiveChildsTrades'

const initialStateRepurchase = {
    repurchase: 50,
    customRepurchase: '10',
    repurchaseOption: 'default',
}

const handleSetListPercent = (maxCap, tradeBalance) => {
    const ratio = 100 / maxCap

    return tradeBalance.reduce((acc, v) => ({ ...acc, [v]: v * ratio }), {})
}

const TokenTrade = ({
    symbol = '',
    orderType = '',
    disabled,
    totalBalanceUsed,
    selectedStrategy,
}) => {
    const {
        bybitTrades,
        tradesMoney,
        activeOrders,
        symbolsPending,
        childsActiveTrades,
    } = useTradesReducerState()
    const symbolsUsed = useSymbolsUsed()
    const { emisorSelected } = useEmisors()
    const currentSide = orderType === 'Long' ? 'Buy' : 'Sell'

    const { maxCap, tradeBalance, tradePercentage } = selectedStrategy ?? {
        maxCap: 0,
        tradeBalance: [],
        tradePercentage: [],
    }

    const initialState = {
        maxaccbalance: maxCap,
        maxRisk: Math.min(...tradePercentage),
        typeOrder: 'limit',
        limitPrice: '',
    }
    const maxPercent = 140

    const [{ maxaccbalance, maxRisk, typeOrder, limitPrice }, setState] =
        useState(initialState)

    const [{ repurchase, customRepurchase, repurchaseOption }, setRepurchase] =
        useState(initialStateRepurchase)

    const { placeLimitOrder, loading: loadingLimit } = usePlaceLimitOrder()
    const { repurchaseLimitOrder, loading: loadingRepurchaseLimit } =
        useRepurchaseLimitOrder()
    const { placeMarketOrder, loading: loadingMarket } = usePlaceMarketOrder()
    const setChildsActiveTrades = useSetChildActiveTrades()

    const enableRepurchase = useMemo(() => {
        return (
            symbolsUsed.length > 0 &&
            symbolsUsed.filter((v) => v[0] === symbol && v[1] === currentSide)
                .length > 0
        )
    }, [bybitTrades, symbol, currentSide])

    const tradeData = useMemo(() => {
        if (!enableRepurchase) return undefined
        return (
            bybitTrades.find(
                (v) => v.symbol === symbol && v.side === currentSide
            ) ??
            tradesMoney.find(
                (v) => v.symbol === symbol && v.side === currentSide
            )
        )
    }, [enableRepurchase, symbol, currentSide, bybitTrades, tradesMoney])

    const isTradeOpen = useMemo(() => {
        return (
            symbolsUsed.length > 0 &&
            symbolsUsed.filter((v) => v[0] === symbol && v[1] !== currentSide)
                .length > 0
        )
    }, [symbolsUsed, symbol, currentSide])

    const handlePlaceOrder = useCallback(() => {
        let baseBody = {
            keyId: emisorSelected,
            symbol,
            side: orderType,
        }
        if (!enableRepurchase) {
            baseBody = {
                ...baseBody,
                maxaccbalance,
                percentage: maxRisk,
            }
            if (typeOrder === 'limit') {
                placeLimitOrder({
                    entryPrice: Number(limitPrice),
                    ...baseBody,
                })
            } else {
                placeMarketOrder(baseBody)
            }
        } else {
            const percentage =
                repurchaseOption === 'default'
                    ? repurchase
                    : Number(customRepurchase)

            baseBody = {
                ...baseBody,
                percentage,
                qty: Number(tradeData.quantity),
            }
            if (typeOrder === 'limit') {
                repurchaseLimitOrder({
                    ...baseBody,
                    entryPrice: Number(limitPrice),
                })
            } else {
                placeMarketOrder({ isRepurchase: true, ...baseBody })
            }
        }
    }, [
        enableRepurchase,
        typeOrder,
        orderType,
        repurchase,
        repurchaseOption,
        customRepurchase,
        emisorSelected,
        symbol,
        limitPrice,
        tradeData,
        maxRisk,
        // leverage,
    ])

    const LIST_PERCENT = handleSetListPercent(maxCap, tradeBalance)

    const disabledMarginWhenPercentageIsGreater = (
        percent,
        balanceUsedPercent
    ) => {
        const truePercent = LIST_PERCENT[percent]

        let enable = true
        for (let index = 0; index < tradePercentage.length; index++) {
            const p = truePercent * (tradePercentage[index] / 100)
            if (balanceUsedPercent + p <= maxPercent) {
                enable = false
                break
            }
        }
        return enable
    }

    const disabledTradeWhenPercentageIsGreater = (
        percent,
        balanceUsedPercent,
        percentage
    ) => {
        const truePercent = LIST_PERCENT[percent]
        const p = truePercent * (percentage / 100)

        return balanceUsedPercent + p > maxPercent
    }

    const optionAccountBalance = tradeBalance
        .map((v) => ({ label: `${v}%`, value: v }))
        .map((v) => ({
            ...v,
            disabled: disabledMarginWhenPercentageIsGreater(
                v.value,
                totalBalanceUsed
            ),
        }))

    const optionsMaxRisk = tradePercentage
        .map((v) => ({
            label: `${v}%`,
            value: v,
        }))
        .map((v) => ({
            ...v,
            disabled: disabledTradeWhenPercentageIsGreater(
                maxaccbalance,
                totalBalanceUsed,
                v.value
            ),
        }))

    const disabledAll = useMemo(() => {
        return (
            activeOrders.filter((v) => v.symbol === symbol && !v.reduce_only)
                .length > 0 ||
            symbolsPending.filter((v) => v === symbol).length > 0 ||
            disabled ||
            loadingRepurchaseLimit ||
            loadingLimit ||
            loadingMarket ||
            isTradeOpen ||
            (!enableRepurchase &&
                optionAccountBalance.reduce((acc, m) => {
                    if (!acc) return acc
                    return m.disabled
                }, true))
        )
    }, [
        symbol,
        activeOrders,
        symbolsPending,
        disabled,
        loadingLimit,
        loadingRepurchaseLimit,
        loadingMarket,
        isTradeOpen,
        optionAccountBalance,
    ])

    const disabledButton = useMemo(() => {
        return (
            (!enableRepurchase &&
                ((typeOrder === 'limit' && !limitPrice) ||
                    disabledTradeWhenPercentageIsGreater(
                        maxaccbalance,
                        totalBalanceUsed,
                        maxRisk
                    ))) ||
            (enableRepurchase && typeOrder === 'limit' && !limitPrice)
        )
    }, [
        // leverage,
        limitPrice,
        maxaccbalance,
        totalBalanceUsed,
        maxRisk,
        enableRepurchase,
        typeOrder,
    ])

    const handleSetInitialState = useCallback(() => {
        setState((state) => ({
            maxaccbalance: maxCap,
            maxRisk: Math.min(...tradePercentage),
            typeOrder: 'limit',
            limitPrice: '',
        }))
    }, [symbol, maxCap, tradePercentage])

    useEffect(() => {
        setState((state) => ({ ...state, limitPrice: '' }))
        if (enableRepurchase) {
            setRepurchase(initialStateRepurchase)
        } else {
            setState(initialState)
        }
    }, [enableRepurchase])

    useEffect(() => {
        handleSetInitialState()
    }, [symbol, selectedStrategy])

    const buttonSize = useResponsiveValue({ base: 'small', xl: 'normal' })

    return (
        <React.Fragment>
            <ActiveChildsTrades
                visible={childsActiveTrades}
                onClose={() => {
                    setChildsActiveTrades(false)
                }}
            />
            <div className="bg-white rounded-md border py-3 px-3 shadow-experts-lg">
                <div className="space-y-3">
                    <div className="flex">
                        <div className="text-base font-extrabold tracking-widest">
                            {symbol}
                        </div>
                        <div className="ml-auto">
                            <div
                                className={`text-sm font-semibold tracking-wider rounded-full w-16 text-center border ${
                                    orderType === 'Long'
                                        ? 'text-white bg-experts-green-2'
                                        : 'text-white bg-experts-red-2'
                                }`}
                            >
                                {orderType}
                            </div>
                        </div>
                    </div>{' '}
                    <div className="">
                        {enableRepurchase && <div>Repurchase</div>}
                        <div className="custom-radio">
                            <Radio.Group
                                options={[
                                    { label: 'Limit', value: 'limit' },
                                    { label: 'Market', value: 'market' },
                                ]}
                                disabled={disabledAll}
                                name="typeOrder"
                                onChange={({ target: { value, name } }) => {
                                    setState((state) => ({
                                        ...state,
                                        [name]: value,
                                    }))
                                }}
                                value={typeOrder}
                                optionType="button"
                            />
                        </div>
                    </div>
                    {!enableRepurchase && (
                        <div className="">
                            <div>Max Account Balance</div>{' '}
                            <div className="custom-radio">
                                <Radio.Group
                                    options={optionAccountBalance}
                                    disabled={disabledAll}
                                    name="maxaccbalance"
                                    value={maxaccbalance}
                                    optionType="button"
                                    size={'middle'}
                                    onChange={({ target: { value, name } }) => {
                                        setState((state) => ({
                                            ...state,
                                            [name]: value,
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {typeOrder === 'limit' && (
                        <div className="">
                            <div>Limit/Price</div>
                            <Input
                                id="limitPrice"
                                name="limitPrice"
                                placeholder="Limit/Price"
                                value={limitPrice}
                                disabled={disabledAll}
                                onChange={({ target: { value, name } }) => {
                                    const _value = numberValidation(value, 6, 0)

                                    if (_value)
                                        setState((state) => ({
                                            ...state,
                                            [name]: _value,
                                        }))
                                }}
                                onBlur={({ target: { value, name } }) => {
                                    if (value === '0')
                                        setState((state) => ({
                                            ...state,
                                            [name]: '1',
                                        }))
                                }}
                            />
                        </div>
                    )}{' '}
                    {/* {!enableRepurchase && (
                        <div className="">
                            <div>Leverage</div>
                            <Input
                                id="leverage"
                                name="leverage"
                                placeholder="Leverage"
                                value={leverage}
                                disabled={disabledAll}
                                onChange={({ target: { value, name } }) => {
                                    const _value = numberValidation(
                                        value,
                                        6,
                                        0,
                                        100
                                    )

                                    if (_value)
                                        setState((state) => ({
                                            ...state,
                                            [name]: _value,
                                        }))
                                }}
                                onBlur={({ target: { value, name } }) => {
                                    if (value === '0')
                                        setState((state) => ({
                                            ...state,
                                            [name]: '1',
                                        }))
                                }}
                            />
                        </div>
                    )}{' '} */}
                    {!enableRepurchase ? (
                        <div>
                            <div>Trade Max Risk</div>{' '}
                            <div className="custom-radio">
                                <Radio.Group
                                    options={optionsMaxRisk}
                                    disabled={disabledAll}
                                    name="maxRisk"
                                    onChange={({ target: { value, name } }) => {
                                        setState((state) => ({
                                            ...state,
                                            [name]: value,
                                        }))
                                    }}
                                    value={maxRisk}
                                    optionType="button"
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-row">
                            {' '}
                            <div>
                                <div>Qty Percentage</div>{' '}
                                <div className="custom-radio">
                                    <Radio.Group
                                        options={[
                                            { label: '50%', value: 50 },
                                            { label: '100%', value: 100 },
                                        ]}
                                        disabled={disabledAll}
                                        onChange={({ target: { value } }) => {
                                            setRepurchase((state) => ({
                                                ...state,
                                                repurchase: value,
                                                repurchaseOption: 'default',
                                            }))
                                        }}
                                        value={
                                            repurchaseOption === 'default'
                                                ? repurchase
                                                : undefined
                                        }
                                        optionType="button"
                                    />
                                </div>
                            </div>
                            <div
                                className={`custom-input-risk ml-2 mt-auto ${
                                    repurchaseOption === 'custom'
                                        ? 'custom-input-risk-selected'
                                        : ''
                                }`}
                            >
                                <Input
                                    id="customRepurchase"
                                    name="customRepurchase"
                                    className="w-12"
                                    value={customRepurchase}
                                    disabled={disabledAll}
                                    onFocus={() => {
                                        setRepurchase((state) => ({
                                            ...state,
                                            repurchaseOption: 'custom',
                                        }))
                                    }}
                                    onChange={({ target: { value, name } }) => {
                                        const _value = numberValidation(
                                            value,
                                            3,
                                            0,
                                            100
                                        )
                                        if (_value)
                                            setRepurchase((state) => ({
                                                ...state,
                                                [name]: _value,
                                            }))
                                    }}
                                    onBlur={({ target: { value } }) => {
                                        if (value === '0')
                                            setRepurchase((state) => ({
                                                ...state,
                                                [name]: '1',
                                            }))
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <Button
                        loading={
                            loadingLimit ||
                            loadingMarket ||
                            loadingRepurchaseLimit
                        }
                        onClick={handlePlaceOrder}
                        disabled={disabledAll || disabledButton}
                        size={buttonSize}
                        className="w-full text-xs xl:text-lg h-8"
                        spinnerSize="normal"
                    >
                        {loadingLimit || loadingMarket || loadingRepurchaseLimit
                            ? ''
                            : !enableRepurchase
                            ? 'Open Trade'
                            : 'Repurchase'}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TokenTrade
