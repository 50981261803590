/* eslint-disable camelcase */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../services/axiosConfig'

import { useEmisors } from './../dashboardReducer/dashboardReducer'
import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sleep } from './../../../utils/promise'
import dayjs from 'dayjs'
import axios from 'axios'

export const getMMStrategies = createAsyncThunk(
    'trades/getmmStrategies',
    async ({ keyId, cancelToken }, { rejectWithValue, getState }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/moneymanagement/getstrategies/${keyId}`,
                { cancelToken }
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const addPhases = createAsyncThunk(
    'trades/addPhaseMMStrategies',
    async ({ strategyId, phases }, { rejectWithValue, getState }) => {
        try {
            const res = await axiosInstance.post(
                `bybit/moneymanagement/addphasebylist/`,
                { strategyId, data: phases }
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const createNewStrategy = createAsyncThunk(
    'trades/createMMStrategy',
    async (
        {
            name,
            side,
            maxCap,
            activePhases,
            maxTrades,
            initialLeverage,
            initialTP,
            tradeBalances,
            tradePercentages,
            ratioStrategy,
            symbols,
        },
        { rejectWithValue, getState, dispatch }
    ) => {
        try {
            const state = getState()
            const { emisorSelected } = state.dashboardReducer
            const resStrategy = await axiosInstance.post(
                `bybit/moneymanagement/addstrategy/`,
                {
                    name,
                    side,
                    maxCap,
                    activePhases,
                    maxTrades,
                    keyId: emisorSelected,
                    initialTP,
                    initialLeverage: JSON.stringify(initialLeverage),
                    tradeBalance: JSON.stringify(tradeBalances),
                    tradePercentage: JSON.stringify(tradePercentages),
                    ratioStrategy,
                }
            )

            const { strategyId } = resStrategy.data.result

            await axiosInstance.post(`bybit/moneymanagement/addphase/`, {
                strategyId,
                action: 'Leverage',
                condition: -50,
                size: 25,
                takeProfit: 100,
                stopLoss: 0,
                name: 'Stage 1',
                actionSecondary: null,
                conditionSecondary: null,
                sizeSecondary: null,
                takeProfitSecondary: null,
                stopLossSecondary: 0,
            })

            await axiosInstance.post(`bybit/admin/symbolstrategyset/`, {
                strategyId,
                symbols,
            })

            return resStrategy.data.result
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const updateStrategy = createAsyncThunk(
    'trades/updateMMStrategy',
    async (
        {
            name,
            side,
            maxCap,
            activePhases,
            maxTrades,
            strategyId,
            initialLeverage,
            initialTP,
            tradeBalances,
            tradePercentages,
            ratioStrategy,
            symbols,
        },
        { rejectWithValue, getState, dispatch }
    ) => {
        try {
            const resStrategy = await axiosInstance.put(
                `bybit/moneymanagement/updatestrategy/`,
                {
                    strategyId,
                    name,
                    side,
                    activePhases,
                    maxTrades,
                    initialLeverage: JSON.stringify(initialLeverage),
                    initialTP,
                    maxCap,
                    tradeBalance: JSON.stringify(tradeBalances),
                    tradePercentage: JSON.stringify(tradePercentages),
                    ratioStrategy,
                }
            )

            await axiosInstance.post(`bybit/admin/symbolstrategyset/`, {
                strategyId,
                symbols,
            })

            return resStrategy.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const removeStrategy = createAsyncThunk(
    'trades/removeMMStrategy',
    async (id, { rejectWithValue, getState, dispatch }) => {
        try {
            const resStrategy = await axiosInstance.delete(
                `bybit/moneymanagement/removestrategy/${id}`
            )

            return resStrategy.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const activateStrategy = createAsyncThunk(
    'trades/activateStrategy',
    async (strategyId, { rejectWithValue, getState, dispatch }) => {
        try {
            const resStrategy = await axiosInstance.put(
                `bybit/moneymanagement/updatestatusstrategy/`,
                { strategyId }
            )

            return resStrategy.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const deactivateStrategy = createAsyncThunk(
    'trades/deactivateStrategy',
    async (strategyId, { rejectWithValue, getState, dispatch }) => {
        try {
            const resStrategy = await axiosInstance.put(
                `bybit/moneymanagement/updatestatusstrategy/`,
                { strategyId }
            )

            return resStrategy.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const initialState = {
    strategies: [],
    requestFetchStrategies: null,
    fetchedStrategies: false,
    reload: false,
}

const strategiesSlice = createSlice({
    name: 'strategies',
    initialState,
    reducers: {
        reloadStrategies(state) {
            state.reload = !state.reload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMMStrategies.fulfilled, (state, { payload }) => {
                state.fetchedStrategies = true
                state.strategies = payload.map((v) => ({
                    ...v,
                    tradeBalance: JSON.parse(v.tradeBalance),
                    tradePercentage: JSON.parse(v.tradePercentage),
                    initialLeverage: JSON.parse(v.initialLeverage),
                }))
            })
            .addCase(getMMStrategies.pending, (state, { payload }) => {
                state.fetchedStrategies = false
                state.requestFetchStrategies = dayjs().toString()
            })
    },
})
export const { reloadStrategies } = strategiesSlice.actions

const selectSelf = (state) => state.strategiesReducer

export const useStrategieState = () => useSelector(selectSelf)

export const useActivedStrategies = () => {
    const { strategies } = useStrategieState()
    return useMemo(() => {
        return strategies?.filter((v) => v.active) ?? []
    }, [strategies])
}

export const useFetchMMStrategies = () => {
    const dispatch = useDispatch()
    const [fetching, setFetching] = useState(false)
    const cancelCall = useRef(null)
    const { reload } = useStrategieState()
    const lastReloadValue = useRef(null)
    const { emisorSelected } = useEmisors()

    const fetch = useCallback(async () => {
        if (fetching && reload !== lastReloadValue.current) {
            console.log('Cancel Fetching')
            cancelCall.current.cancel('Reload Executed')
        }

        try {
            setFetching(true)
            cancelCall.current = axios.CancelToken.source()
            await dispatch(
                getMMStrategies({
                    keyId: emisorSelected,
                    cancelToken: cancelCall.current.token,
                })
            ).unwrap()
        } catch (error) {
            console.log({ error })
        }
        setFetching(false)
    }, [reload, emisorSelected, fetching, dispatch])

    useEffect(() => {
        if (emisorSelected > 0) fetch()
        if (reload !== lastReloadValue.current) lastReloadValue.current = reload
    }, [emisorSelected, reload])
}

export const useSetStages = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const fetch = useCallback(
        async (strategyId, phases) => {
            try {
                setLoading(true)
                await sleep(600)
                await dispatch(addPhases({ strategyId, phases })).unwrap()
                dispatch(reloadStrategies())
            } catch (error) {
                setError(error)
                throw error
            }
            setLoading(false)
        },
        [dispatch]
    )

    return { setStages: fetch, error, loading }
}

export const useActivateStrategy = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const activate = useCallback(
        async (strategyId) => {
            setLoading(true)
            try {
                await sleep(600)
                await dispatch(activateStrategy(strategyId)).unwrap()
                dispatch(reloadStrategies())
            } catch (error) {
                console.log({ error })
            }
            setLoading(false)
        },
        [dispatch]
    )

    return { activate, loading }
}

export const useDeactivateStrategy = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const deactivate = useCallback(
        async (strategyId) => {
            setLoading(true)
            try {
                await sleep(600)
                await dispatch(deactivateStrategy(strategyId)).unwrap()
                dispatch(reloadStrategies())
            } catch (error) {
                console.log({ error })
            }
            setLoading(false)
        },
        [dispatch]
    )

    return { deactivate, loading }
}

export const useCreateStrategy = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const fetch = useCallback(
        async (
            name,
            side,
            initialLeverage,
            initialTP,
            tradeBalances,
            tradePercentages,
            maxCap,
            ratioStrategy,
            symbols
        ) => {
            setLoading(true)
            try {
                await sleep(600)
                const res = await dispatch(
                    createNewStrategy({
                        name,
                        side,
                        maxCap,
                        activePhases: 10,
                        maxTrades: 5,
                        initialLeverage,
                        initialTP,
                        tradeBalances,
                        tradePercentages,
                        ratioStrategy,
                        symbols,
                    })
                ).unwrap()
                dispatch(reloadStrategies())
                console.log({ res })
                return res.strategyId
            } catch (error) {
                setError(error)
                throw error
            } finally {
                setLoading(false)
            }
        },
        [dispatch]
    )

    return { createStrategy: fetch, error, loading }
}

export const useUpdateStrategy = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const fetch = useCallback(
        async (
            strategyId,
            name,
            side,
            initialLeverage,
            initialTP,
            tradeBalances,
            tradePercentages,
            maxCap,
            ratioStrategy,
            symbols
        ) => {
            try {
                setLoading(true)
                await sleep(600)
                await dispatch(
                    updateStrategy({
                        strategyId,
                        name,
                        side,
                        activePhases: 10,
                        maxTrades: 5,
                        initialTP,
                        maxCap,
                        initialLeverage,
                        tradeBalances,
                        tradePercentages,
                        ratioStrategy,
                        symbols,
                    })
                ).unwrap()
                dispatch(reloadStrategies())
            } catch (error) {
                setError(error)
                throw error
            }
            setLoading(false)
        },
        [dispatch]
    )

    return { updateStrategy: fetch, error, loading }
}

export const useRemoveStrategy = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const fetch = useCallback(
        async (strategyId) => {
            try {
                setLoading(true)
                await sleep(600)
                await dispatch(removeStrategy(strategyId)).unwrap()
                dispatch(reloadStrategies())
            } catch (error) {
                setError(error)
                throw error
            }
            setLoading(false)
        },
        [dispatch]
    )

    return { removeStrategy: fetch, error, loading }
}

export default strategiesSlice.reducer
