import React, { useRef, useCallback } from 'react'

import CustomModal from 'src/components/Modals/GenericModals/CustomModal'
import AddTraderForm from './AddTraderForm'
import { useSignUp } from './../../../../store/reducers/auth/authReducer'
import { useReloadAdmin } from 'src/store/reducers/adminReducer/adminReducer'

const AddTraderModal = ({ visible = false, onClose, onCreate }) => {
    const reloadAdmin = useReloadAdmin()
    const formRef = useRef(null)
    const { signUp, loading } = useSignUp()

    const handleClose = () => {
        formRef.current.resetForm()
        onClose()
    }
    const handleOkay = useCallback(() => {
        formRef.current.submitForm()
    }, [])

    const handleSubmit = useCallback(
        async (values) => {
            signUp({ ...values })
                .then(() => {
                    handleClose()
                    reloadAdmin()
                })
                .catch((err) => {
                    console.log('Error', err)
                })
        },
        [signUp, reloadAdmin]
    )

    return (
        <CustomModal
            loading={loading}
            visible={visible}
            title={'Add New Trader'}
            width={450}
            onCancel={handleClose}
            onOk={handleOkay}
        >
            <AddTraderForm ref={formRef} onSubmit={handleSubmit} />
        </CustomModal>
    )
}

export default AddTraderModal
