export const API_REFRESH_RATE = {
    FAST: 15000,
    MEDIUM: 30000,
    SLOW: 60000,
}

/**
 * Enum with the delay in milliseconds (ms) to be used in the intervals, timeouts and debounces
 * of the application
 */

export const DELAY = {
    FASTER: 250,
    FAST: 500,
    MEDIUM: 1000,
    SLOW: 2000,
    SLOWER: 3000,
    SLOWEST: 5000,
}
