import Icon from 'src/components/Icons/Icon'

const customAntNotifications = {
    sucess: <Icon name="4-check" className="w-6 h-6" />,
    error: <Icon name="4-wrong" className="w-6 h-6" />,
    warning: <Icon name="4-alert" className="w-6 h-6" />,
    info: <Icon name="4-info" className="w-6 h-6" />,
}

export default customAntNotifications
