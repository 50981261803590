/**
 * Create a function that will receive an array of keys names and an object
 * then we will use the keys names to trasform the values of the object to numbers
 */

export const transformToNumber = (keys = [], obj = {}) => {
    const newObj = { ...obj }
    keys.forEach((key) => {
        // If key exists in the object then we will transform the value to number type
        if (newObj[key]) newObj[key] = Number(newObj[key])
    })
    return newObj
}

export const transformValues = (
    keys = [],
    obj = {},
    transform = (value) => {
        return value
    }
) => {
    const newObj = { ...obj }
    keys.forEach((key) => {
        if (newObj[key]) newObj[key] = transform(newObj[key])
    })
    return newObj
}
