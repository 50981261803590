/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import EmisorSelect from '../components/EmisorSelect'
import Tabs from '../components/MyConfigComponents/Tabs'
import MyConfig from '../components/MyConfigComponents/MyConfig/index'

import { useFetchMMStrategies } from 'src/store/reducers/strategies/strategiesReducer'
import { useStrategieState } from './../store/reducers/strategies/strategiesReducer'
import AddStrategyModal from 'src/components/MyConfigComponents/StrategyModal/AddStrategyModal'

import InfoStage from 'src/components/MyConfigComponents/InfoStage'
import UpdateStrategyModal from 'src/components/MyConfigComponents/StrategyModal/UpdateStrategyModal'
import CustomModal from 'src/components/Modals/GenericModals/CustomModal'
import RemoveStrategyModal from 'src/components/MyConfigComponents/StrategyModal/RemoveStrategyModal'
import { useGetSymbolsByStrategy } from 'src/store/reducers/strategies/symbolsStrategy'

const MyConfigView = (props) => {
    const [{ addStrategy, updateStrategy, removeStrategy }, setOpenModal] =
        useState({
            addStrategy: false,
            updateStrategy: false,
            removeStrategy: false,
        })

    const configRef = useRef(null)
    const [editedStage, setEditedStage] = useState(false)
    const [init, setInit] = useState(false)
    const [selectedMenu, setSelectedMenu] = useState(null)
    const { strategySymb } = useGetSymbolsByStrategy(selectedMenu)

    const { strategies } = useStrategieState()

    const handleSelect = (menu) => setSelectedMenu(menu)

    const initComponent = useCallback(() => {
        if (!init) {
            setInit(true)
            setSelectedMenu(strategies[0].id)
        }
        if (!strategies.find((v) => v.id === selectedMenu)) {
            setSelectedMenu(strategies[0].id)
        }
    }, [init, strategies, selectedMenu])

    const handleResetStages = useCallback(() => {
        configRef.current.reset()
    }, [])

    const handleShowModal = (modalName) =>
        setOpenModal((state) => ({ ...state, [modalName]: !state[modalName] }))

    const handleDelete = (id) => {}

    useEffect(() => {
        if (strategies.length > 0) initComponent()
    }, [strategies])

    const configData = useMemo(() => {
        if (!selectedMenu || strategies.length === 0) return undefined
        const strategy = strategies.find((v) => v.id === selectedMenu)
        return strategy
    }, [selectedMenu, strategies])

    return (
        <div className="w-full flex flex-col md:flex-row gap-4 lg:gap-10">
            <div className="lg:pb-6 flex-grow flex flex-col w-full gap-4 xl:gap-6">
                <div className="text-lg xl:text-2xl font-extrabold border-0 w-full flex flex-row items-end gap-8">
                    <EmisorSelect />
                </div>
                <AddStrategyModal
                    visible={addStrategy}
                    onClose={() => handleShowModal('addStrategy')}
                    onCreateStrategy={(id) => {
                        setSelectedMenu(id)
                    }}
                />
                {configData && (
                    <React.Fragment>
                        <RemoveStrategyModal
                            visible={removeStrategy}
                            data={
                                configData
                                    ? {
                                          strategyId: configData?.id,
                                          name: configData.name,
                                      }
                                    : {}
                            }
                            strategyId={configData?.id ?? null}
                            onClose={() => handleShowModal('removeStrategy')}
                        />
                        <UpdateStrategyModal
                            data={
                                configData
                                    ? {
                                          name: configData.name,
                                          leverage:
                                              configData
                                                  ?.initialLeverage?.[0] ?? '',

                                          takeProfit: configData?.initialTP,
                                          strategyId: configData?.id,
                                          side: configData?.side,
                                          ratioStrategy:
                                              configData?.ratioStrategy,
                                          tradeBalances: Array.isArray(
                                              configData?.tradeBalance
                                          )
                                              ? configData?.tradeBalance
                                              : [],
                                          tradePercentages: Array.isArray(
                                              configData?.tradePercentage
                                          )
                                              ? configData?.tradePercentage
                                              : [],
                                          leverages: Array.isArray(
                                              configData?.initialLeverage
                                          )
                                              ? configData?.initialLeverage
                                              : [],
                                          activeSymbols: strategySymb,
                                      }
                                    : {}
                            }
                            visible={updateStrategy}
                            onClose={() => handleShowModal('updateStrategy')}
                        />
                    </React.Fragment>
                )}
                <Tabs
                    menus={strategies}
                    selected={selectedMenu}
                    onSelect={handleSelect}
                />
                <InfoStage
                    strategies={strategies}
                    editedStage={editedStage}
                    configData={configData ?? null}
                    onReset={handleResetStages}
                    onAddStrategy={() => handleShowModal('addStrategy')}
                    onEditStrategy={() => handleShowModal('updateStrategy')}
                    onClickCalculator={() => {
                        configRef.current.simulator.goToCalculator()
                    }}
                    onDelete={() => handleShowModal('removeStrategy')}
                />
                <div className="w-full max-w-full">
                    {configData && (
                        <MyConfig
                            ref={configRef}
                            onEdited={(edited) => setEditedStage(edited)}
                            {...configData}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default MyConfigView
