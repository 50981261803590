/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useMemo } from 'react'
import { Table as TableAnt } from 'antd'

import { roi } from 'src/assets/images'

// import ProfileIcon from 'src/components/SVGComponents/ProfileIcon'
import locale from 'antd/es/date-picker/locale/en_US'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import Button from 'src/components/Buttons/Button'
// import useMediaQuery from 'src/hooks/useMediaQuery'

import dayjs from 'dayjs'
import { useFetchSimulatorTrades } from 'src/store/reducers/simulatorReducer/simulatorReducer'
import { useSimulator } from '../../store/reducers/simulatorReducer/simulatorReducer'
import DatePicker from '../AntWithDaysJs/DatePicker'
import { formatMoneyNumber, formatAmountBySymbol } from 'src/utils/formatter'
// import { formatMoneyNumber} from 'src/utils/formatter'
import useResponsiveValue from './../../hooks/useResponsiveValue'
import { calculateConditionPrice } from 'src/service/simulatorServices'
import Icon from 'src/components/Icons/Icon'

import {
    PieChart,
    Pie,
    Cell,
    ResponsiveContainer,
    Legend,
    Tooltip,
} from 'recharts'
import numeral from 'numeral'
import { FaSpinner } from 'react-icons/fa'

const { RangePicker } = DatePicker

const COLORS = [
    '#4D4D4D',
    '#808080',
    '#B2B2B2',
    '#D9D9D9',
    '#1FA857',
    '#1F7757',
    '#006569',
    '#00423D',
    '#F9E1A3',
    '#F1B51A',
    '#C59017',
]

const renderLegend = (props) => {
    const { payload } = props
    return (
        <div className="grid grid-cols-3">
            {payload.map((entry, index) => (
                <div
                    key={`item-${index}`}
                    className="text-left text-xs flex flex-row items-center"
                >
                    {' '}
                    <div
                        style={{ backgroundColor: entry.color }}
                        className="rounded-full w-2 h-2 mr-2"
                    ></div>
                    <div>{entry.value}</div>
                </div>
            ))}
        </div>
    )
}

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-white border-experts-gray-6 border p-2">
                {`${payload[0].name} : ${payload[0].payload.label}`}
            </div>
        )
    }

    return null
}

const PieGraphStats = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const onPieEnter = (_, index) => {
        setActiveIndex(index)
    }
    return (
        <ResponsiveContainer
            width={'100%'}
            height={'100%'}
            className="text-center"
        >
            <PieChart width={500} height={500}>
                {' '}
                <Tooltip content={<CustomTooltip />} />
                <Legend
                    layout="horizontal"
                    verticalAlign="bottom"
                    iconSize={10}
                    iconType="circle"
                    align="left"
                    content={renderLegend}
                />
                <Pie
                    activeIndex={activeIndex}
                    // activeShape={renderActiveShape}
                    data={data.map((val) => ({
                        ...val,
                        value: Math.abs(val.value),
                        label: val.value,
                    }))}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    // outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                    // label
                    onMouseEnter={onPieEnter}
                >
                    {data.map((entry, index) => (
                        <Cell
                            stroke={COLORS[index % COLORS.length]}
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}

// eslint-disable-next-line no-unused-vars
const AccountsCalculator = ({ data, loading }) => {
    const $data = useMemo(() => {
        if (!data || Object.keys(data).length === 0 || loading)
            return {
                totalTrades: [],
                keyEmisor: {},
                tradesWins: 0,
                tradesLosses: 0,
                pnlWins: '0.00',
                pnlLossses: '0.00',
                pnlBySymbol: [],
            }

        return data
    }, [data, loading])

    const {
        keyEmisor,
        tradesWins,
        pnlWins,
        tradesLosses,
        pnlLosses,
        pnlBySymbol,
    } = $data

    return (
        <div className="relative w-full h-full">
            {loading && (
                <div className="absolute inset-0 flex justify-center items-center z-10 bg-black bg-opacity-25">
                    <FaSpinner className="animate-spin text-white text-2xl" />
                </div>
            )}
            <div className="w-full h-full flex flex-col-reverse md:flex-row bg-white border-experts-gray-2 shadow-experts-lg border rounded-md p-7 divide-x-2">
                <div className="w-6/12 flex flex-col mt-4 md:mt-0">
                    <div className="flex flex-row items-end">
                        <div className="">
                            <div className="font-jura text-xl xl:text-2xl text-oraxio-blue-300 font-bold">
                                {keyEmisor?.name}
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-experts-gray-5 tex-[9px] xl:text-[11px] font-semibold mt-5 xl:mt-8">
                    Initial amount
                </div> */}
                    <div className="tracking-wide text-xl xl:text-2xl font-bold">
                        {/* {formatMoneyNumber(pnlWins, 2, 2)} USD */}
                    </div>
                    <div className="flex flex-row divide-x-2 mt-4">
                        <div className="flex flex-col pr-6">
                            <div className=" text-experts-gray-5">
                                Total Earnings
                            </div>
                            <div className="text-experts-green-2">
                                {formatMoneyNumber(pnlWins, 2, 2)}
                            </div>
                        </div>
                        <div className="flex flex-col pl-6">
                            <div className=" text-experts-gray-5 mr-2">
                                Total Losses
                            </div>
                            <div className="text-experts-red-2">
                                {formatMoneyNumber(pnlLosses, 2, 2)}
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full mt-auto">
                        <div className="mt-5 xl:mt-7 text-sm font-semibold flex flex-col gap-2 relative">
                            <div className="flex flex-row ">
                                <div className="w-28 text-experts-gray-5 mr-2">
                                    Total Trades
                                </div>
                                <div className=" font-maven">
                                    {typeof tradesWins === 'number' &&
                                    typeof tradesLosses === 'number'
                                        ? Number(tradesWins) +
                                          Number(tradesLosses)
                                        : 0}
                                </div>
                            </div>
                            <div className="flex flex-row ">
                                <div className="w-28 text-experts-gray-5 mr-2">
                                    Won Trades
                                </div>
                                <div className=" font-maven">
                                    {tradesWins ?? 0}
                                </div>
                            </div>
                            <div className="flex flex-row ">
                                <div className="w-28 text-experts-gray-5 mr-2">
                                    Lost Trades
                                </div>
                                <div className=" font-maven">
                                    {tradesLosses ?? 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-auto w-6/12 flex justify-center items-center px-2">
                    <div className="h-80 w-80">
                        <PieGraphStats data={pnlBySymbol} />
                    </div>
                    {/* <div className="w-40 h-40 lg:w-44 lg:h-44 xl:w-52 xl:h-52 rounded-full bg-gray-100"></div> */}
                </div>
            </div>
        </div>
    )
}

// eslint-disable-next-line no-unused-vars
const ContainerInvest = ({ data, loading }) => {
    const $data = useMemo(() => {
        if (!data || Object.keys(data).length === 0)
            return {
                pnl: '0',
                pnlUsd: '0',
                side: 'N/A',
                symbol: 'N/A',
                entryPrice: 'N/A',
                exitPrice: 'N/A',
                leverage: 'N/A',
            }
        return data
    }, [data])

    const { pnl, pnlUsd, side, symbol, entryPrice, exitPrice, leverage } = $data
    return (
        <div className="relative w-full h-full overflow-hidden  p-6 pb-7  bg-gradient-to-b from-[#0C301F] to-[#0B0C0C] border border-[#74747433] rounded-md shadow-experts-lg">
            {loading && (
                <div className="absolute inset-0 flex justify-center items-center z-10 bg-black bg-opacity-25">
                    <FaSpinner className="animate-spin text-white text-2xl" />
                </div>
            )}
            <div className="absolute inset-0 opacity-80">
                <img className="w-auto h-full" src={roi} />
            </div>
            <div className="flex flex-row items-start relative">
                <div className="flex flex-col">
                    <div className="text-[#b2b2b2] text-[9px] xl:text-[11px]">
                        SYMBOL
                    </div>
                    <div className="flex flex-row">
                        <div className="text-base xl:text-lg font-semibold text-white mr-2 tracking-widest">
                            {symbol}
                        </div>
                    </div>
                </div>
                {side !== 'N/A' && (
                    <div className="ml-auto font-semibold text-xs text-white rounded-full bg-experts-green-2 py-[4px] px-5">
                        {side === 'Buy' ? 'Long' : 'Short'}
                    </div>
                )}
            </div>
            <div className="mt-6 relative">
                <div className="text-[11px] text-[#CFCFCF] tracking-widest">
                    ROI
                </div>
                <div
                    className={`text-xl xl:text-2xl text-white font-bold tracking-wider font-oxanium ${
                        Number(pnl) >= 0
                            ? 'text-green-600'
                            : 'text-experts-red-2'
                    }`}
                >
                    {pnl} %
                </div>
                <div
                    className={`text-xs text-white font-semibold tracking-wider font-oxanium`}
                >
                    {formatAmountBySymbol(pnlUsd, symbol)} USD
                </div>
            </div>
            <div className="mt-8 text-xs flex flex-col gap-2 relative">
                <div className="flex flex-row ">
                    <div className="w-20 text-[#CFCFCF] text-xs tracking-widest">
                        Entry price
                    </div>
                    <div className="text-experts-yellow-6 font-semibold tracking-wider text-xs">
                        {entryPrice !== 'N/A'
                            ? formatAmountBySymbol(entryPrice, symbol)
                            : entryPrice}
                    </div>
                </div>
                <div className="flex flex-row ">
                    <div className="w-20 text-[#CFCFCF] text-xs tracking-widest">
                        Exit price
                    </div>
                    <div className="text-experts-yellow-6 font-semibold tracking-wider text-xs">
                        {' '}
                        {exitPrice !== 'N/A'
                            ? formatAmountBySymbol(exitPrice, symbol)
                            : exitPrice}
                    </div>
                </div>
                <div className="flex flex-row ">
                    <div className="w-20 text-[#CFCFCF] text-xs tracking-widest">
                        Leverage
                    </div>
                    <div className="text-experts-yellow-6 font-semibold tracking-wider text-xs">
                        {' '}
                        {leverage !== 'N/A' ? leverage + 'x' : leverage}{' '}
                    </div>
                </div>
            </div>
        </div>
    )
}

const columns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        render: (value, record) => (
            <div className="flex flex-col items-center">
                <div>
                    <div className="text-black font-bold text-xs xl:text-base">
                        {value}
                    </div>
                    <div className="flex flex-row">
                        <span
                            className={`mr-2 font-bold tracking-wider text-sm ${
                                record.side === 'Buy'
                                    ? 'text-green-600'
                                    : 'text-experts-red-2'
                            }`}
                        >
                            {record.side === 'Buy' ? 'Long' : 'Short'}
                        </span>
                        <span
                            className={`text-experts-yellow-3 font-bold text-sm`}
                        >
                            {record.leverage}x
                        </span>
                    </div>
                </div>
            </div>
        ),
    },
    {
        title: 'Strategy',
        dataIndex: 'account',
        key: 'account',
    },

    // {
    //     title: 'Date',
    //     dataIndex: 'date',
    //     key: 'date',
    //     render: (value) => {
    //         // console.log({ values })
    //         return <span>{dayjs(value).format('YYYY-MM-DD')}</span>
    //     },
    // },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
        render: (value, values) => {
            // console.log({ values })
            return (
                <span
                    className={
                        values.side !== 'Buy'
                            ? 'text-experts-red-2'
                            : 'text-experts-green-2'
                    }
                >
                    {value}
                </span>
            )
        },
    },

    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        render: (value, record) => {
            // console.log({ values })
            return (
                <span>{formatAmountBySymbol(value, record.symbol)} USDT</span>
            )
        },
    },
    {
        title: 'Entry Price',
        dataIndex: 'entryPrice',
        key: 'entryPrice',
        render: (value, record) => {
            // console.log({ values })
            return (
                <span>{formatAmountBySymbol(value, record.symbol)} USDT</span>
            )
        },
    },
    {
        title: 'Exit Price',
        dataIndex: 'exitPrice',
        key: 'exitPrice',
        render: (value, record) => {
            // console.log({ values })
            return (
                <span>{formatAmountBySymbol(value, record.symbol)} USDT</span>
            )
        },
    },
    {
        title: 'Position Margin',
        dataIndex: 'positionMargin',
        key: 'positionMargin',
        render: (value, record) => {
            // console.log({ values })
            return (
                <span>{formatAmountBySymbol(value, record.symbol)} USDT</span>
            )
        },
    },
    {
        title: 'P&L(%)',
        key: 'pnl',
        render: (_, values) => {
            return (
                <div className="">
                    <div
                        className={
                            Number(values.pnlUsd) >= 0
                                ? 'text-experts-green-2'
                                : 'text-experts-red-2'
                        }
                    >
                        {`${numeral(values.pnlUsd).format('0,000.00')}`} USDT
                    </div>
                    <div
                        className={
                            Number(values.pnlUsd) >= 0
                                ? 'text-experts-green-2'
                                : 'text-experts-red-2'
                        }
                    >
                        {`(${numeral(values.pnl).format('0,000.00')})%`}
                    </div>
                </div>
            )
        },
    },
]

const SectionCalculator = (props) => {
    const [emisorIndex, setEmisorIndex] = useState(0)
    const [dates, setDates] = useState([])
    const [hackValue, setHackValue] = useState(null)
    const [value, setValue] = useState([])
    const [selectedTrade, setSelectTrade] = useState(0)

    const handlePickDate = useCallback((v) => {
        setValue(v)
    }, [])

    const onOpenChange = (open) => {
        if (open) {
            setHackValue([null, null])
            setDates([null, null])
        } else {
            setHackValue(null)
        }
    }

    const disabledDate = (current) => {
        // Can not select days before today and today

        return (
            (current && current > dayjs().startOf('day')) ||
            current < dayjs().subtract(2, 'month')
        )
    }

    const { trades, loading } = useFetchSimulatorTrades({ rangeDate: dates })
    const { totalTrades = [], ...currentTrade } = trades?.[emisorIndex] ?? {}

    const handleOnClickNext = () => {
        setEmisorIndex((state) => state + 1)
    }

    const handleOnClickPrev = () => {
        setEmisorIndex((state) => state - 1)
    }

    const scrollTable = useResponsiveValue({
        base: { y: 700, x: 600 },
        lg: { y: 700 },
    })

    return (
        <div className="w-full max-w-[1370px] mx-auto mt-4  px-4 xl:px-0">
            <div className="flex flex-col md:flex-row gap-3 md:gap-6 xl:gap-10">
                {trades && trades.length > 1 && (
                    <div className="flex flex-row items-center justify-between relative md:w-2/6 lg:w-1/2">
                        <Button
                            onClick={handleOnClickPrev}
                            variant="danger"
                            className="h-9 flex items-center"
                            disabled={emisorIndex === 0}
                        >
                            <AiOutlineLeft className=" text-xl" />
                        </Button>
                        <div className="font-semibold text-2xl text-black">
                            Strategy
                        </div>
                        <Button
                            onClick={handleOnClickNext}
                            variant="primary"
                            className="h-9 flex items-center"
                            disabled={
                                !trades ||
                                (trades && trades.length === 0) ||
                                emisorIndex === trades.length - 1
                            }
                        >
                            {' '}
                            <AiOutlineRight className=" text-xl" />
                        </Button>
                    </div>
                )}
                <div className="ml-auto flex flex-row gap-2 lg:gap-4 items-center justify-end w-full md:w-4/6 lg:w-1/2">
                    <div className="w-7/12 md:w-full">
                        <RangePicker
                            defaultValue={[
                                dayjs().subtract(30, 'day'),
                                dayjs().subtract(1, 'day'),
                            ]}
                            value={hackValue || value}
                            className="w-full  py-1"
                            size="small"
                            locale={locale}
                            // defaultValue={[
                            //     dayjs()
                            //         .subtract(30, 'day')
                            //         .format('YYYY/MM/DD'),
                            //     dayjs().subtract(1, 'day').format('YYYY/MM/DD'),
                            // ]}
                            onCalendarChange={(val) => setDates(val)}
                            onChange={handlePickDate}
                            onOpenChange={onOpenChange}
                            style={{ height: '35.95px' }}
                            disabledDate={disabledDate}
                        />
                    </div>
                </div>
            </div>
            <div className=" grid grid-cols-4 gap-6 mt-3">
                <div className="col-span-4 lg:col-span-2 h-96">
                    <AccountsCalculator data={currentTrade} loading={loading} />
                </div>
                <div className="col-span-4 md:col-span-2 lg:col-span-2 h-96">
                    <ContainerInvest
                        data={totalTrades?.[selectedTrade]}
                        loading={loading}
                    />
                </div>
            </div>
            <div className="flex flex-row mt-5 mb-3">
                <div className="font-medium text-sm text-black">HISTORY</div>
            </div>
            <div className="custom-landing-ant-table">
                <TableAnt
                    loading={loading}
                    className="flex-1"
                    center
                    columns={columns}
                    // pagination={false}
                    dataSource={totalTrades.map((v, index) => ({
                        ...v,
                        index,
                    }))}
                    scroll={scrollTable}
                    onRow={({ index }) => {
                        return { onClick: () => setSelectTrade(index) }
                    }}
                />
            </div>
        </div>
    )
}

export default SectionCalculator
