import React from 'react'

import AlertModal from './../Modals/GenericModals/AlertModal'

const ActiveChildsTrades = ({ ...props }) => {
    return (
        <AlertModal
            {...props}
            detail="You cannot open a new positon, some users have open trades. Please try again later."
        />
    )
}

export default ActiveChildsTrades
