export const valueToPercent = (value, divider = 100) => (1 / divider) * value

export const calculateMargin = (accountBalance, risk) => accountBalance * risk

export const calculateReferenceMargin = (
    action,
    currentContracts,
    orderPrice,
    leverage,
    size
) => {
    if (action === 'Increase') {
        const quantityContracts = currentContracts * (size / 100)
        return (quantityContracts * orderPrice) / leverage
    }

    return (
        ((currentContracts * (leverage / size) - currentContracts) *
            orderPrice) /
        leverage
    )
}

export const calculateReferenceQty = (
    action,
    currentContracts,
    orderPrice,
    leverage,
    size
) => {
    if (action === 'Increase') {
        return currentContracts * (size / 100)
    }
    return currentContracts
}

export const calculateRealLeverage = (action, size, lastRealLeverage) => {
    if (!lastRealLeverage || action === 'Leverage') {
        return size
    }
    return lastRealLeverage
}

export const calculateRealQty = (currentQty, lastRealQty) => {
    return lastRealQty + currentQty
}
// export const calculateRealQty = (action, size, entryQty, lastRealQty) => {
//     if (!lastRealQty) return entryQty
//     if (action === 'Increase') return lastRealQty * (size / 100) + lastRealQty
//     return lastRealQty
// }

export const calculateMaintenanceFee = (value) => {
    return value * 0.0017
}

export const calculateOpenFee = (value) => value * 0.0012

// Initial Margin = Qty * price / leverage
// Calcualte LP https://www.bybit.com/en-US/help-center/bybitHC_Article?id=000001067&language=en_US
// MM Calculate https://blog.bybit.com/ja-JP/post/initial-margin-and-maintenance-margin-bltb46e827134b48820/

export const calculateLiquidationPrice = (
    marginMode,
    side,
    price,
    leverage,
    marketPrice,
    balance,
    // margin,
    qty
) => {
    if (marginMode === 'Isolated') {
        if (side === 'Long') return price * (1 - 1 / leverage + 0.005)

        return price * (1 + 1 / leverage - 0.005)
    }

    const maintenanceMargin = qty * price * 0.005

    // LP (Long) = MP - (AB+IM-MM)/EPS
    // LP (Short) = MP+(AB+IM-MM)/EPS

    if (maintenanceMargin > balance) return 'N/A'
    if (side === 'Long') {
        const lp = marketPrice - (balance - maintenanceMargin) / qty
        return lp < 0 ? 0 : lp
    }

    return marketPrice + (balance - maintenanceMargin) / qty
}

export const calculatePnlPercent = (margin, pnl) => {
    return (100 / margin) * pnl
}

export const calculatePnl = (side, entryPrice, exitPrice, qty) => {
    if (side === 'Long') return (exitPrice - entryPrice) * qty
    return (entryPrice - exitPrice) * qty
}

// https://www.binance.com/en/support/faq/how-to-use-binance-futures-calculator-360036498511
export const calculateTakeProfitTrigger = (
    side,
    entryPrice,
    realLeverage,
    roe
) => {
    // const price = lastAverageEntryPrice ?? entryPrice
    // 0.35
    if (side === 'Long') return entryPrice * (roe / realLeverage + 1)
    return entryPrice * (1 - roe / realLeverage)
}

export const calculateConditionPrice = (
    side,
    entryPrice,
    lastAverageEntryPrice,
    realLeverage,
    conditionPercent
) => {
    const price = lastAverageEntryPrice ?? entryPrice
    // 0.35

    if (side === 'Long') return price * (conditionPercent / realLeverage + 1)
    return price * (1 - conditionPercent / realLeverage)
}

export const calculateAverageEntryPrice = (
    action,
    accumulatedBought,
    accumulatedQty,
    price
) => {
    if (action === 'Increase') {
        return accumulatedBought / accumulatedQty
    }

    return price
}

export const calculateAccountPercent = (accountBalance, realMargin) =>
    (100 / accountBalance) * realMargin

export const makeSimulation = (stages, parameters) => {
    const {
        accountBalance,
        qty: entryQty,
        leverage: entryLeverage,
        entryPrice,
        risk,
        side,
        initialTP,
        // takeProfit: tp,
    } = parameters ?? {}

    const margin = calculateMargin(accountBalance, risk)

    let sumAmountBought = entryQty * entryPrice
    let sumQuantity = entryQty

    const _openFee = calculateOpenFee(entryQty * entryPrice)
    const _maintenanceFee = calculateMaintenanceFee(entryQty * entryPrice)

    const _tpTrigger = calculateTakeProfitTrigger(
        side,
        entryPrice,
        entryLeverage,
        valueToPercent(initialTP)
    )

    let marginMode = 'Isolated'
    const _pnl = calculatePnl(side, entryPrice, _tpTrigger, entryQty)

    // let availableBalance = accountBalance - margin

    return [
        {
            name: 'Open Trade',
            side,
            entryPrice,
            size: entryLeverage,
            // rMultiplier: 1,
            rMargin: margin,
            rQty: entryQty,
            value: entryQty * entryPrice,
            realLeverage: entryLeverage,
            realQty: entryQty,
            maintenanceFee: _maintenanceFee,
            openFee: _openFee,
            realMargin: margin,
            liqPrice: calculateLiquidationPrice(
                marginMode,
                side,
                entryPrice,
                entryLeverage,
                accountBalance,
                entryPrice,
                // margin,
                entryQty
            ),
            tpTrigger: _tpTrigger,
            percentAccount: risk * 100,
            averageEntryPrice: entryPrice,
            action: 'Default',
            conditionPrice: entryPrice,
            pnlPercent: calculatePnlPercent(margin, _pnl),
            distanceToTp: _tpTrigger - entryPrice,
            pnl: _pnl,
        },
        ...stages.reduce((acc, value, index) => {
            const { action, condition, name, takeProfit } = value
            // const takeProfit = stages[index + 1]?.takeProfit
            //     ? stages[index + 1]?.takeProfit
            //     : stages[index]?.takeProfit

            const size = Number(value.size)

            if (action === 'Cross') {
                marginMode = 'Cross'
            }

            // if (action === '')
            const {
                realLeverage: lastRealLeverage,
                realQty: lastRealQty,
                averageEntryPrice: lastAverageEntryPrice,
                realMargin: lastRealMargin,
            } = acc.length > 0 ? acc[acc.length - 1] : {}

            const conditionPercent = -valueToPercent(condition)

            const conditionPrice = calculateConditionPrice(
                side,
                entryPrice,
                lastAverageEntryPrice,
                lastRealLeverage ?? entryLeverage,
                conditionPercent
            )

            const realLeverage = calculateRealLeverage(
                action,
                size,
                lastRealLeverage
            )

            // const rMultiplier = calculateMultiplier(
            //     action,
            //     entryPrice,
            //     conditionPrice,
            //     entryLeverage,
            //     lastRealLeverage,
            //     size,
            //     lastRQty
            // )

            // 1,241.21'rMultiplier', rMultiplier)

            if (action === 'Increase') {
                const qty = valueToPercent(size) * sumQuantity
                sumAmountBought = sumAmountBought + qty * conditionPrice

                sumQuantity = sumQuantity + qty
            }

            const averageEntryPrice = calculateAverageEntryPrice(
                action,
                sumAmountBought,
                sumQuantity,
                lastAverageEntryPrice ?? entryPrice
            )

            const rMargin = calculateReferenceMargin(
                action,
                lastRealQty ?? entryQty,
                averageEntryPrice ?? entryPrice,
                lastRealLeverage ?? entryLeverage,
                size
            )

            const rQty = calculateReferenceQty(
                action,
                lastRealQty ?? entryQty,
                averageEntryPrice ?? entryPrice,
                lastRealLeverage ?? entryLeverage,
                size
            )

            // Tener cuidado con el cross
            const realQty = calculateRealQty(
                action === 'Leverage' || action === 'Cross' ? 0 : rQty,
                lastRealQty ?? entryQty
            )

            const _value = realQty * averageEntryPrice

            const maintenanceFee = calculateMaintenanceFee(_value)

            const openFee = calculateOpenFee(_value)

            const currentMargin = lastRealMargin ?? margin

            // const realMargin = currentMargin + rMargin + openFee
            const realMargin = currentMargin + rMargin
            // availableBalance = accountBalance - realMargin

            const liqPrice =
                accountBalance < realMargin
                    ? 'N/A'
                    : calculateLiquidationPrice(
                          marginMode,
                          side,
                          averageEntryPrice,
                          realLeverage,
                          conditionPrice,
                          accountBalance,
                          realQty
                      )

            const tpPercent = valueToPercent(takeProfit)

            const tpTrigger = calculateTakeProfitTrigger(
                side,
                averageEntryPrice,
                realLeverage,
                tpPercent
            )

            const percentAccount = calculateAccountPercent(
                accountBalance,
                realMargin
            )

            const pnl = calculatePnl(
                side,
                averageEntryPrice,
                tpTrigger,
                realQty
            )
            const pnlPercent = calculatePnlPercent(realMargin, pnl)

            return [
                ...acc,
                {
                    side,
                    size,
                    value: _value,
                    // rMultiplier,
                    rMargin,
                    rQty,
                    realLeverage,
                    realQty,
                    maintenanceFee,
                    openFee,
                    realMargin,
                    liqPrice,
                    tpTrigger,
                    percentAccount,
                    averageEntryPrice,
                    action,
                    conditionPrice,
                    name,
                    pnlPercent,
                    pnl,
                    distanceToTp: tpTrigger - averageEntryPrice,
                },
            ]
        }, []),
    ]
}
