/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useState } from 'react'
import {
    useClearDeletedSymbols,
    useClearPendingSymbols,
    useCloseActiveOrder,
    useClosePosition,
    useFetchTrades,
    useSetChildActiveOrders,
    useSubscribePosition,
    useTradesReducerState,
    useUnsubscribePosition,
    useFetchOrders,
} from 'src/store/reducers/tradesReducer/trades.hook'
import {
    useEmisors,
    useSelectedEmisorInfo,
} from 'src/store/reducers/dashboardReducer/dashboardReducer'
import PendingOrders from './PendingOrders'
import LiveTrades from './LiveTrades'
import MMTrades from './MMTrades'
import ActiveChildsOrders from './ActiveChildsOrders'
import SetSLTPModal from './SetSLTPModal'
import useResponsiveValue from 'src/hooks/useResponsiveValue'
import { useStrategieState } from './../../../store/reducers/strategies/strategiesReducer'
import { HEDGING } from 'src/constants/phase.constants'
import HedgingTrades from './HedgingTrades'

const TradesAndOrders = () => {
    const [isManual, setIsManual] = useState(false)
    const [tradeToEditSlTP, setTradeToEditSLTP] = useState(null)
    const { emisorSelected } = useEmisors()
    const { strategies } = useStrategieState()
    const { reloading } = useFetchTrades()
    const { childsActiveOrders } = useTradesReducerState()
    const setChildsActiveOrders = useSetChildActiveOrders()

    useFetchOrders()
    useClearPendingSymbols()
    useClearDeletedSymbols()
    const { subscribePosition, loading: subscribing } = useSubscribePosition()
    const { unsubscribePosition, loading: unsubscribing } =
        useUnsubscribePosition()

    const { closeActiveOrder, loading: deletingActive } = useCloseActiveOrder()
    const { closePosition, loading: deletingPosition } = useClosePosition()

    const handleActivate = useCallback(
        (trade) => {
            const { symbol, side, id } = trade
            subscribePosition({ keyId: emisorSelected, id, side, symbol })
        },
        [subscribePosition, emisorSelected]
    )

    const handleDeactivate = useCallback(
        (trade) => {
            const { symbol, id } = trade
            unsubscribePosition({ symbol, id })
        },
        [unsubscribePosition]
    )
    const { shared, sharedActive } = useSelectedEmisorInfo() ?? {}

    const isMobile = useResponsiveValue({ base: true, lg: false })

    const selectedStrategy = useMemo(() => {
        if (strategies.length === 0) return undefined
        // const _side = side === 'Long' ? 'Buy' : 'Sell'
        return strategies.filter((v) => v.active)?.[0]
    }, [strategies])

    const isHeding = useMemo(() => {
        if (selectedStrategy === undefined) return false
        return selectedStrategy.phases.some((v) => v.action === HEDGING)
    }, [selectedStrategy])

    return (
        <React.Fragment>
            <SetSLTPModal
                isManual={isManual}
                emisorSelected={emisorSelected}
                visible={tradeToEditSlTP !== null}
                tradeData={tradeToEditSlTP}
                onCancel={() => setTradeToEditSLTP(null)}
            />
            <ActiveChildsOrders
                visible={childsActiveOrders}
                onClose={() => setChildsActiveOrders(false)}
            />
            {!isHeding ? (
                <React.Fragment>
                    <LiveTrades
                        isMobile={isMobile}
                        selectedStrategy={selectedStrategy}
                        subscribing={subscribing}
                        reloading={reloading}
                        deleting={deletingPosition}
                        disabled={shared && !sharedActive}
                        onSubscribeMM={handleActivate}
                        onClose={closePosition}
                        onEditSlTp={(value) => {
                            setIsManual(false)
                            setTradeToEditSLTP(value)
                        }}
                    />

                    <MMTrades
                        onUnsubscribeMM={handleDeactivate}
                        unsubscribing={unsubscribing}
                        reloading={reloading}
                        deleting={deletingPosition}
                        onClose={closePosition}
                        disabled={shared && !sharedActive}
                        onEditSlTp={(value) => {
                            setIsManual(true)
                            setTradeToEditSLTP(value)
                        }}
                        isMobile={isMobile}
                    />
                </React.Fragment>
            ) : (
                <HedgingTrades
                    onClose={closePosition}
                    onActiveMM={handleActivate}
                    onDeactiveMM={handleDeactivate}
                    // Boolean when any action is pending
                    pending={subscribing || unsubscribing || deletingPosition}
                    onEditSlTp={(value) => {
                        setIsManual(true)
                        setTradeToEditSLTP(value)
                    }}
                />
            )}
            <PendingOrders
                onClose={closeActiveOrder}
                deleting={deletingActive}
                disabled={shared && !sharedActive}
                isMobile={isMobile}
            />
        </React.Fragment>
    )
}

export default TradesAndOrders
