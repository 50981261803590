import React from 'react'

import { Radio } from 'antd'
import useResponsiveValue from 'src/hooks/useResponsiveValue'
import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'
import { numberValidation } from 'src/service/inputValidations'
import numeral from 'numeral'
import Button from 'src/components/Buttons/Button'

const SimulatorForm = ({
    visible,
    side,
    risk,
    formik,
    riskType,
    qty,
    onSetRisk,
    onSetRiskType,
    onSetSide,
}) => {
    const optionsSide = [
        { label: 'Long', value: 'Long' },
        { label: 'Short', value: 'Short' },
    ]
    const optionsRisk = [
        { label: '1%', value: 0.01 },
        { label: '2%', value: 0.02 },
        { label: '3%', value: 0.03 },
    ]

    const inputSize = useResponsiveValue({ base: 'small', xl: 'middle' })
    const buttonSize = useResponsiveValue({ base: 'small', xl: 'normal' })

    return (
        <form onSubmit={formik.handleSubmit} className="mb-4">
            <div className="flex flex-col lg:flex-row w-full gap-2 xl:gap-5">
                {' '}
                <div className="flex flex-col xl:flex-grow md:flex-row gap-2 xl:gap-5">
                    <div className="flex flex-row w-full gap-2 xl:gap-5">
                        <div className="min-w-fit">
                            {' '}
                            <div className="mb-1 text-xs xl:text-base">
                                Side
                            </div>
                            <Radio.Group
                                options={optionsSide}
                                onChange={({ target: { value } }) => {
                                    onSetSide(value)
                                }}
                                value={side}
                                optionType="button"
                                size={inputSize}
                            />
                        </div>
                        <div className="md:w-24 md:flex-grow flex-grow-0 xl:flex-grow-1">
                            {' '}
                            <div className="mb-1 text-xs xl:text-base">
                                Balance
                            </div>
                            <FormikAntInput
                                formik={formik}
                                name="accountBalance"
                                showError={false}
                                onChange={(e) => {
                                    const {
                                        target: { value, name },
                                    } = e
                                    const _value = numberValidation(value, 3, 0)
                                    if (_value)
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: _value,
                                        })
                                }}
                                onBlur={(e) => {
                                    const { name, value } = e.target

                                    formik.setTouched({ [name]: true })
                                    if (Number(value) < 100) {
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: '100',
                                        })
                                    }
                                }}
                            />
                        </div>{' '}
                        <div className="md:w-200 md:flex-grow flex-grow-0 xl:flex-grow-1">
                            {' '}
                            <div className="mb-1 text-xs xl:text-base">Qty</div>
                            <FormikAntInput
                                formik={formik}
                                name="qty"
                                disabled
                                showError={false}
                                value={numeral(qty).format('0.0004')}
                            />
                        </div>
                    </div>
                    <div className="min-w-fit flex flex-row gap-2 xl:gap-5">
                        <div className=" ">
                            <div className="mb-1 text-xs xl:text-base">
                                Margin
                            </div>
                            <div className="flex flex-row">
                                <Radio.Group
                                    options={optionsRisk}
                                    onChange={({ target: { value } }) => {
                                        onSetRisk(value)
                                        onSetRiskType('default')
                                    }}
                                    value={
                                        riskType === 'default'
                                            ? risk
                                            : undefined
                                    }
                                    optionType="button"
                                    size={inputSize}
                                />
                                <div
                                    className={`custom-input-risk ml-1 ${
                                        riskType === 'custom'
                                            ? 'custom-input-risk-selected'
                                            : ''
                                    }`}
                                >
                                    <FormikAntInput
                                        formik={formik}
                                        name="customRisk"
                                        placeholder=""
                                        className="w-12"
                                        showError={false}
                                        onFocus={(e) => {
                                            onSetRiskType('custom')
                                        }}
                                        onChange={(e) => {
                                            const {
                                                target: { value, name },
                                            } = e
                                            const _value = numberValidation(
                                                value,
                                                3,
                                                0,
                                                100
                                            )

                                            onSetRiskType('custom')
                                            if (_value)
                                                formik.setValues({
                                                    ...formik.values,
                                                    [name]: _value,
                                                })
                                        }}
                                        onBlur={(e) => {
                                            const { name, value } = e.target

                                            formik.setTouched({
                                                [name]: true,
                                            })
                                            if (value === '0') {
                                                formik.setValues({
                                                    ...formik.values,
                                                    [name]: '1',
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>{' '}
                    </div>{' '}
                </div>
                <div className="w-full lg:w-3/12 2xl:w-auto flex-grow flex flex-col xl:flex-row gap-2 xl:gap-5">
                    <div className="flex-1 flex flex-row gap-2 xl:gap-5">
                        <div className="w-12 lg:w-auto flex-1">
                            <div className="mb-1 text-xs xl:text-base">
                                Leverage
                            </div>
                            <FormikAntInput
                                formik={formik}
                                name="leverage"
                                placeholder=""
                                showError={false}
                                onChange={(e) => {
                                    const {
                                        target: { value, name },
                                    } = e
                                    const _value = numberValidation(
                                        value,
                                        1,
                                        0,
                                        100
                                    )

                                    if (_value)
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: _value,
                                        })
                                }}
                                onBlur={(e) => {
                                    const { name, value } = e.target

                                    formik.setTouched({ [name]: true })
                                    if (value === '0') {
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: '10',
                                        })
                                    }
                                }}
                            />
                        </div>
                        <div className="flex-1">
                            <div className="mb-1 text-xs xl:text-base">
                                Entry Price
                            </div>
                            <FormikAntInput
                                formik={formik}
                                name="entryPrice"
                                onChange={(e) => {
                                    const {
                                        target: { value, name },
                                    } = e
                                    const _value = numberValidation(
                                        value,
                                        10,
                                        0
                                    )
                                    if (_value)
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: _value,
                                        })
                                }}
                                onBlur={(e) => {
                                    const { name, value } = e.target

                                    formik.setTouched({ [name]: true })
                                    if (value === '0') {
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: '1',
                                        })
                                    }
                                }}
                                showError={false}
                            />
                        </div>
                        <div className="flex-1">
                            <div className="mb-1 text-xs xl:text-base">
                                Take Profit
                            </div>
                            <FormikAntInput
                                formik={formik}
                                name="takeProfit"
                                onChange={(e) => {
                                    const {
                                        target: { value, name },
                                    } = e
                                    const _value = numberValidation(value, 4, 0)
                                    if (_value)
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: _value,
                                        })
                                }}
                                showError={false}
                                onBlur={(e) => {
                                    const { name, value } = e.target

                                    formik.setTouched({ [name]: true })
                                    if (value === '0') {
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: '10',
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-2 lg:mt-0 lg:ml-auto flex w-full lg:w-36">
                    <Button
                        size={buttonSize}
                        type="submit"
                        className="w-full lg:mt-[20px] xl:mt-[28px] h-6 xl:h-8"
                    >
                        Calculate
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default SimulatorForm
