import React from 'react'

import { Popconfirm } from 'antd'
import Button from 'src/components/Buttons/Button'
import { AiOutlineStop } from 'react-icons/ai'

const OrderCard = ({ data, onClose, deleting, disabled, isMobile }) => {
    const {
        side,
        symbol,
        price,
        qty: quantity,
        order_id: orderId,
        reduce_only: reduceOnly,
    } = data ?? {}

    return (
        <div className="shadow-experts-lg bg-white border border-experts-gray-2 rounded-md pt-4 px-4 flex flex-col box-border">
            <div className="flex flex-row">
                <div className="flex-1">
                    <div className="font-extrabold text-black text-base">
                        {symbol}
                    </div>
                </div>
                <div className="ml-auto">
                    <div
                        className={`mr-2 font-bold tracking-wider text-sm ${
                            side === 'Buy'
                                ? 'text-green-600'
                                : 'text-experts-red-2'
                        }`}
                    >
                        {reduceOnly && <span className="ml-2">Close</span>}
                    </div>{' '}
                </div>
            </div>
            <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="">
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        Side
                    </div>
                    <div className="flex flex-row">
                        <div
                            className={`font-bold tracking-wider text-sm ${
                                side === 'Buy'
                                    ? 'text-green-600'
                                    : 'text-experts-red-2'
                            }`}
                        >
                            {side === 'Buy' ? 'Long' : 'Short'}
                        </div>{' '}
                    </div>
                </div>
                <div className="">
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        Qty
                    </div>
                    <div className="text-black font-semibold text-sm">
                        {quantity}
                    </div>
                </div>
                <div className="">
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        Price
                    </div>
                    <div className="text-black font-semibold text-sm">
                        {price}
                    </div>
                </div>
                {/* <div className="">
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        PNL
                    </div>
                </div> */}
            </div>

            <div className="flex flex-row relative -mx-4 bg-experts-purple-1 border-t  mt-4 divide-x">
                <Popconfirm
                    placement="top"
                    title="Are you sure you want to close this order?"
                    onConfirm={() =>
                        onClose({
                            orderId: orderId,
                            symbol: symbol,
                            side: side === 'Buy' ? 'Long' : 'Short',
                        })
                    }
                >
                    <Button
                        variant=""
                        className="w-full rounded-none shadow-none font-medium text-xs h-9 text-experts-red-2 hover:bg-experts-red-1 hover:scale-100"
                        loading={deleting}
                        disabled={deleting || reduceOnly}
                    >
                        {' '}
                        {!deleting ? <AiOutlineStop /> : null}
                    </Button>
                </Popconfirm>
            </div>
        </div>
    )
}

export default OrderCard
