/* eslint-disable no-unused-vars */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch } from 'react-redux'
import rootReducer, { PERSISTED_KEYS } from './reducers'

import { initialState as authInitialState } from './reducers/auth/authReducer'
import { initialState as userInfoInitialState } from './reducers/userInfoReducer/userInfoReducer'
import { initialState as tradesInitialState } from './reducers/tradesReducer/trades.slice'
import { initialState as dashboardInitialState } from './reducers/dashboardReducer/dashboardReducer'

const preloadedState = load({
    states: PERSISTED_KEYS,
    preloadedState: {
        authReducer: cloneDeep(authInitialState),
        userInfoReducer: cloneDeep(userInfoInitialState),
        tradesReducer: cloneDeep(tradesInitialState),
        dashboardReducer: cloneDeep(dashboardInitialState),
    },
})

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: rootReducer,
    middleware: [
        ...getDefaultMiddleware({ thunk: true }),
        save({ states: PERSISTED_KEYS }),
    ],
    preloadedState: {
        ...preloadedState,
        tradesReducer: {
            ...preloadedState.tradesReducer,
            executing: false,
            removing: false,
            childsActiveTrades: false,
            childsActiveOrders: false,
        },
    },
})

export default store
