import { useCallback, useState, useRef, useEffect } from 'react'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'src/store/services/axiosConfig'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useActivedStrategies } from 'src/store/reducers/strategies/strategiesReducer'
import useDeepCompareEffect from './../../../hooks/useDeepCompareEffect'

export const getSymbolsByStrategy = createAsyncThunk(
    'symbolsStrategies/symbolstrategyget',
    async ({ strategyIds, cancelToken }, { rejectWithValue, getState }) => {
        try {
            const symbols = {}
            for (let index = 0; index < strategyIds.length; index++) {
                const res = await axiosInstance.get(
                    `bybit/admin/symbolstrategyget/${strategyIds[index]}`,
                    {
                        cancelToken,
                    }
                )
                symbols[strategyIds[index]] = res.data.data.symbols
            }

            return symbols
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const initialState = {}

const symbolsStrategiesSlice = createSlice({
    name: 'symbolsStrategies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            getSymbolsByStrategy.fulfilled,
            (state, { payload }) => {
                return { ...state, ...payload }
            }
        )
    },
})

export const { reloadStrategies } = symbolsStrategiesSlice.actions
export default symbolsStrategiesSlice.reducer

const selectSelf = (state) => state.symbolsStrategyReducer

export const useSymbolStrategyState = () => {
    return useSelector(selectSelf)
}

export const useGetSymbolByStrategy = () => {
    const dispatch = useDispatch()
    const [fetching, setFetching] = useState(false)
    const activeStrategies = useActivedStrategies()
    const cancelCall = useRef(null)

    const symbolsStrategy = useSymbolStrategyState()

    const fetch = useCallback(async () => {
        if (fetching) {
            console.log('Cancel Fetching')
            cancelCall.current.cancel('Reload Executed')
        }

        try {
            setFetching(true)
            cancelCall.current = axios.CancelToken.source()

            await dispatch(
                getSymbolsByStrategy({
                    strategyIds: activeStrategies.map((v) => v.id),
                    cancelToken: cancelCall.current.token,
                })
            ).unwrap()
        } catch (error) {
            console.log({ error })
        }
        setFetching(false)
    }, [getSymbolsByStrategy, activeStrategies, fetching, dispatch])

    useDeepCompareEffect(() => {
        if (activeStrategies.length > 0) fetch()
    }, [activeStrategies])

    return { fetching, symbolsStrategy }
}

// Hook to get symbols by strategyid using the getSymbolsByStrategy action
export const useGetSymbolsByStrategy = (strategyId) => {
    const dispatch = useDispatch()
    const [fetching, setFetching] = useState(false)
    const cancelCall = useRef(null)
    const symbolsStrategy = useSymbolStrategyState()

    const fetch = useCallback(async (strategyId) => {
        if (fetching) {
            console.log('Cancel Fetching')
            cancelCall.current.cancel('Reload Executed')
        }

        try {
            setFetching(true)
            cancelCall.current = axios.CancelToken.source()

            await dispatch(
                getSymbolsByStrategy({
                    strategyIds: [strategyId],
                    cancelToken: cancelCall.current.token,
                })
            ).unwrap()
        } catch (error) {
            console.log({ error })
        }
        setFetching(false)
    }, [getSymbolsByStrategy, strategyId, fetching, dispatch])

    useEffect(() => {
        if (strategyId) fetch(strategyId)
    }, [strategyId])

    return { fetching, strategySymb: symbolsStrategy?.[strategyId] }
}
