import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as actions from 'src/store/reducers/auth/authReducer'

const LogoutView = () => {
    const dispatch = useDispatch()
    const logoutAction = useCallback(
        () => dispatch(actions.logout()),
        [dispatch]
    )

    useEffect(() => {
        logoutAction()
    }, [])

    return <></>
}

export default LogoutView
