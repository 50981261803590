import { createSlice, isAnyOf } from '@reduxjs/toolkit'

// import { updateArrayPosition } from 'src/utils/arrays'
import dayjs from 'dayjs'
import {
    deleteArrayPosition,
    insertArrayPosition,
    updateArrayPosition,
} from '../../../utils/arrays'

// import useDebounce from './../../../hooks/useDebounce'

import {
    getTrades,
    getOrders,
    closeActiveOrder,
    closePosition,
    placeLimitOrder,
    placeMarketOrder,
    repurchaseLimitOrder,
} from './trades.actions'

export const initialState = {
    bybitTrades: [],
    tradesMoney: [],
    activeOrders: [],
    symbolsUsed: [],
    // Aqui se agregara los symbolos pendientes por cargar despues de la ejecucion una orden nueva
    symbolsPending: [],
    symbolsOrderDeleted: [],
    lastOrderExecutedTime: null,
    lastDeletedOrderExecutedTime: null,
    requestFetchOrderTime: null,
    requestFetchTradeTime: null,
    fetchedOrders: false,
    fetchedTrades: false,
    amountPercentInTrades: 0,
    amountPercentInOrders: 0,
    selectedSymbol: null,
    selectedSide: 'Long',
    executing: false,
    reload: false,
    childsActiveTrades: false,
    childsActiveOrders: false,
}

// Hacer que cuando se lanze una nueva orden, una bandera que representa que se acaba de agregar una order nueva y no se debe lanzar otra
// orden bajo la misma moneda hasta que se haya cargado

const tradeSlice = createSlice({
    name: 'trade',
    initialState,
    reducers: {
        reloadTrades: (state) => {
            state.reload = true
        },
        setSymbol: (state, { payload }) => {
            state.selectedSymbol = payload
        },
        // removePosition: (state, { payload }) => {},
        addPendingSymbol: (state, { payload }) => {
            state.symbolsPending = [...state.symbolsPending, payload]
        },
        clearPendingSymbols: (state, { payload = [] }) => {
            state.symbolsPending = payload
        },
        clearOrderDeletedSymbols: (state, { payload = [] }) => {
            state.symbolsOrderDeleted = payload
        },
        setChildsActiveTrades: (state, { payload = false }) => {
            state.childsActiveTrades = payload
        },
        setChildsActiveOrders: (state, { payload = false }) => {
            state.childsActiveOrders = payload
        },
        setSide: (state, { payload }) => {
            state.selectedSide = payload
        },
        saveOrder: (state, { payload }) => {
            const { order, type, index, symbol } = payload

            if (type === 'ADD') {
                state.activeOrders = [...state.activeOrders, order]
            } else if (type === 'UPDATE') {
                state.activeOrders = updateArrayPosition(
                    state.activeOrders,
                    index,
                    order
                )
            } else if (type === 'INSERT') {
                state.activeOrders = insertArrayPosition(
                    state.activeOrders,
                    index,
                    order
                )
            } else if (type === 'REMOVEALL') {
                state.activeOrders = state.activeOrders.filter(
                    (v) => v.symbol !== symbol
                )
            } else {
                state.activeOrders = deleteArrayPosition(
                    state.activeOrders,
                    index
                )
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTrades.pending, (state) => {
                state.requestFetchTradeTime = dayjs().toString()
                state.fetchedTrades = false
            })
            .addCase(
                getTrades.fulfilled,
                (
                    state,
                    {
                        payload: {
                            bybitTrades,
                            trades,
                            symbols,
                            amountPercentInTrades,
                        },
                    }
                ) => {
                    const newState = {
                        ...state,
                        bybitTrades: bybitTrades ?? [],
                        tradesMoney: trades ?? [],
                        symbolsUsed: symbols ?? [],
                        reload: false,
                        amountPercentInTrades,
                        fetchedTrades: true,
                    }
                    if (
                        state.lastOrderExecutedTime &&
                        dayjs(state.requestFetchTradeTime) >
                            dayjs(state.lastOrderExecutedTime)
                    ) {
                        const symbolIndex = state.symbolsPending.findIndex(
                            (v) => symbols.find((s) => s[0] === v)
                        )

                        if (symbolIndex !== -1)
                            newState.symbolsPending = deleteArrayPosition(
                                state.symbolsPending,
                                symbolIndex
                            )
                    }

                    return {
                        ...state,
                        bybitTrades: bybitTrades ?? [],
                        tradesMoney: trades ?? [],
                        symbolsUsed: symbols ?? [],
                        reload: false,
                        amountPercentInTrades,
                        fetchedTrades: true,
                    }
                }
            )
            .addCase(getOrders.pending, (state) => {
                state.requestFetchOrderTime = dayjs().toString()
                state.fetchedOrders = false
            })
            .addCase(getOrders.fulfilled, (state, { payload: { orders } }) => {
                // const newState = {
                //     ...state,
                //     fetchedOrders: true,
                // }
                // newState.activeOrders = orders

                state.fetchedOrders = true
            })
            .addCase(
                closeActiveOrder.fulfilled,
                (state, { payload: { symbol, side } }) => {
                    state.reload = !state.reload
                    state.lastDeletedOrderExecutedTime = dayjs().toString()
                    state.symbolsOrderDeleted = [
                        ...state.symbolsOrderDeleted,
                        [symbol, side],
                    ]
                }
            )
            .addCase(closePosition.fulfilled, (state) => {
                state.reload = !state.reload
            })
            .addMatcher(
                isAnyOf(placeLimitOrder.pending, placeMarketOrder.pending),
                (state, { payload }) => {
                    state.executing = true
                }
            )
            .addMatcher(
                isAnyOf(placeLimitOrder.rejected, placeMarketOrder.rejected),
                (state, { payload }) => {
                    state.executing = false
                }
            )
            .addMatcher(
                isAnyOf(
                    placeLimitOrder.fulfilled,
                    placeMarketOrder.fulfilled,
                    repurchaseLimitOrder.fulfilled
                ),
                (state, { payload }) => {
                    state.reload = !state.reload
                    state.executing = false
                    state.lastOrderExecutedTime = dayjs().toString()
                }
            )
    },
})

export default tradeSlice.reducer
export const {
    reloadTrades,
    setSymbol,
    addPendingSymbol,
    clearPendingSymbols,
    clearOrderDeletedSymbols,
    setChildsActiveTrades,
    setChildsActiveOrders,
    setSide,
    saveOrder,
} = tradeSlice.actions
