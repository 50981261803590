import React, { useCallback, useMemo, useState } from 'react'

// import * as actions from 'src/store/reducers/dashboardReducer/dashboardReducer'
import { Select } from 'antd'
import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'
import { numberValidation } from 'src/service/inputValidations'
import Button from 'src/components/Buttons/Button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { validations } from 'src/service/yupValidations'
import classNames from 'classnames'

import AddKeyModal from './AddKeyModal'
import { useSaveKey } from 'src/store/reducers/dashboardReducer/dashboardReducer'

const { Option } = Select

const KeysComponent = ({ emisorId, stateButton, firstAccount }) => {
    const [showModal, setShowModal] = useState(false)
    const schema = yup.object().shape({
        name: validations.generic,
        key: validations.generic,
        secret: validations.generic,
        riskPorc: validations.generic,
    })

    const { saveKey } = useSaveKey()

    const initialValues = {
        name: '',
        key: '',
        secret: '',
        btcEth: '100',
        altcoin: '100',
        riskPorc: '',
        maxTrades: '1',
    }

    const handleSubmit = useCallback((values, { setSubmitting }) => {
        setShowModal(true)
        setSubmitting(true)
    }, [])

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema: schema,
    })

    const handleConfirmSave = useCallback(async () => {
        const { values } = formik
        setShowModal(false)
        saveKey({ ...values }).then(() => {
            formik.setSubmitting(false)
            formik.resetForm(initialValues)
        })
    }, [formik, emisorId])

    const modified = useMemo(() => {
        let _modified = false
        for (const key in formik.values) {
            if (formik.values[key] !== initialValues[key]) {
                _modified = true
                break
            }
        }

        return _modified
    }, [formik.values])

    return (
        <div>
            <AddKeyModal
                visible={showModal}
                onClose={() => {
                    setShowModal(false)
                    formik.setSubmitting(false)
                }}
                onSave={() => handleConfirmSave()}
            />
            <div className="bg-white mt-3 rounded-md shadow-md">
                <form onSubmit={formik.handleSubmit}>
                    <div className="px-4 pt-5 ">
                        <div className="text-base mb-3">
                            {firstAccount ? 'Main' : 'Sub Account'}
                        </div>
                        <div className="flex flex-col lg:flex-row">
                            <div className="flex-1 mr-2 md:mr-8">
                                <FormikAntInput
                                    className="h-10 mr-2 md:mr-8"
                                    name="name"
                                    placeholder={'Name'}
                                    formik={formik}
                                    value={formik.values?.name}
                                />
                            </div>
                            <div className="flex-1 mr-2 md:mr-8">
                                <FormikAntInput
                                    className="h-10"
                                    placeholder={'Key'}
                                    name="key"
                                    formik={formik}
                                    value={formik.values?.key}
                                />
                            </div>
                            <div className="flex-1 mr-2 md:mr-8">
                                <FormikAntInput
                                    placeholder={'Secret'}
                                    className="h-10"
                                    name="secret"
                                    formik={formik}
                                    value={formik.values?.secret}
                                />
                            </div>
                            <div className="w-full lg:w-1/4 flex-row gap-3 mt-3 lg:mt-0 hidden lg:flex">
                                <Button
                                    disabled={formik.isSubmitting || !modified}
                                    onClick={() =>
                                        formik.resetForm(initialValues)
                                    }
                                    variant="secondary"
                                    className={`w-full h-10`}
                                >
                                    RESET
                                </Button>
                                <Button
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                    loading={formik.isSubmitting}
                                    className="w-full h-10"
                                >
                                    SAVE
                                </Button>{' '}
                            </div>
                        </div>
                    </div>
                    <div className="px-4 pb-2">
                        <div className="text-xs mt-4 mb-2">
                            ACCOUNT SETTINGS
                        </div>
                        <div className="flex-1 flex flex-col md:flex-row w-full gap-3 md:gap-0 lg:w-3/4">
                            <div className="flex-1 flex flex-col md:mr-8">
                                <FormikAntInput
                                    className="h-10 border-0 border-b focus:outline-none text-center rounded-none"
                                    name="btcEth"
                                    formik={formik}
                                    value={formik.values?.btcEth}
                                    variant="none"
                                    showError={false}
                                    onChange={(e) => {
                                        const {
                                            target: { value, name },
                                        } = e
                                        const _value = numberValidation(
                                            value,
                                            2,
                                            0,
                                            100,
                                            true
                                        )

                                        if (_value)
                                            formik.setValues({
                                                ...formik.values,
                                                [name]: _value,
                                            })
                                    }}
                                    onBlur={(e) => {
                                        const { name, value } = e.target

                                        formik.setTouched({ [name]: true })
                                        if (value === '0') {
                                            formik.setValues({
                                                ...formik.values,
                                                [name]: '100',
                                            })
                                        }
                                    }}
                                />
                                <div className="text-sm text-center mt-2">
                                    BTC / ETH
                                </div>
                            </div>
                            <div className="flex-1 flex flex-col md:mr-8">
                                <FormikAntInput
                                    className="h-10 border-0 border-b  focus:outline-none text-center  rounded-none"
                                    name="altcoin"
                                    formik={formik}
                                    value={formik.values?.altcoin}
                                    variant="none"
                                    showError={false}
                                    onChange={(e) => {
                                        const {
                                            target: { value, name },
                                        } = e
                                        const _value = numberValidation(
                                            value,
                                            2,
                                            0,
                                            100,
                                            true
                                        )

                                        if (_value)
                                            formik.setValues({
                                                ...formik.values,
                                                [name]: _value,
                                            })
                                    }}
                                    onBlur={(e) => {
                                        const { name, value } = e.target

                                        formik.setTouched({ [name]: true })
                                        if (value === '0') {
                                            formik.setValues({
                                                ...formik.values,
                                                [name]: '100',
                                            })
                                        }
                                    }}
                                />
                                <div className="text-sm text-center  mt-2">
                                    ALTCOIN
                                </div>
                            </div>
                            <div className="flex-1 flex flex-col md:mr-8">
                                <div className="custom-select-border-bottom">
                                    <Select
                                        value={
                                            formik.values?.riskPorc !== ''
                                                ? formik.values?.riskPorc
                                                : null
                                        }
                                        placeholder="Select a risk percent"
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                        }}
                                        className={classNames(
                                            'text-center rounded-sm',
                                            {
                                                'ring-1 ring-red-600':
                                                    (formik.touched.riskPorc &&
                                                        formik.errors
                                                            .riskPorc) ||
                                                    (formik.errors.riskPorc &&
                                                        formik.submitCount > 0),
                                            }
                                        )}
                                        size="large"
                                        // disabled={!edit}
                                        // bordered={false}
                                        onChange={(val) => {
                                            formik.setValues({
                                                ...formik.values,
                                                riskPorc: val,
                                            })
                                        }}
                                    >
                                        <Option value="1">1%</Option>
                                        <Option value="2">2%</Option>
                                        <Option value="3">3%</Option>
                                    </Select>
                                </div>
                                <div className="text-sm text-center mt-2">
                                    MAX RISK %
                                </div>
                            </div>
                            <div className="flex-1 flex flex-col md:mr-8">
                                {' '}
                                <div className="custom-select-border-bottom">
                                    <Select
                                        defaultValue={formik.values?.maxTrades}
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                        }}
                                        className="text-center rounded-sm"
                                        size="large"
                                        // disabled={!edit}

                                        onChange={(val) => {
                                            formik.setValues({
                                                ...formik.values,
                                                maxTrades: val,
                                            })
                                        }}
                                    >
                                        <Option value="1">1</Option>
                                    </Select>
                                </div>
                                <div className="text-sm text-center mt-2">
                                    MAX TRADES
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex-row gap-3 mt-3 lg:mt-0 flex lg:hidden">
                            <Button
                                disabled={formik.isSubmitting || !modified}
                                onClick={() => formik.resetForm(initialValues)}
                                variant="secondary"
                                className={`w-full h-10 `}
                            >
                                RESET
                            </Button>
                            <Button
                                disabled={formik.isSubmitting}
                                type="submit"
                                loading={formik.isSubmitting}
                                className="w-full h-10"
                            >
                                SAVE
                            </Button>{' '}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default KeysComponent
