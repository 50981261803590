import { PHASE_TYPES } from 'src/constants/phase.constants'

export const setOptionsValidationsByType = (type) => {
    let options = {
        decimals: 2,
        min: 0,
        enableEmptyValue: true,
    }
    if (type === PHASE_TYPES.STOP_LOSS) {
        options = {
            ...options,
            max: 100,
        }
    }
    return options
}
