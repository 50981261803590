import React from 'react'
import { Radio, Select } from 'antd'
import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'
import { numberValidation } from 'src/service/inputValidations'
import CustomModal from './../../../Modals/GenericModals/CustomModal'
import { formatMoneyNumber } from 'src/utils/formatter'
import useResponsiveValue from 'src/hooks/useResponsiveValue'

const { Option } = Select

const SimulatorFormModal = ({
    visible,
    side,
    risk,
    formik,
    riskType,
    qty,
    onSetRisk,
    onSetRiskType,
    onSetSide,
    onCancel,
}) => {
    const optionsRisk = [
        { label: '1%', value: 0.01 },
        { label: '2%', value: 0.02 },
        { label: '3%', value: 0.03 },
    ]

    const modalSize = useResponsiveValue({ base: 375, md: 450 })
    return (
        <CustomModal
            title="Calculator"
            visible={visible}
            onCancel={onCancel}
            onOk={() => {
                formik.handleSubmit()
            }}
            width={modalSize}
        >
            <form onSubmit={formik.handleSubmit} className="mb-4 space-y-4">
                <div>
                    <div className="text-experts-gray-6 text-xs">Side</div>
                    <Select
                        className="w-full"
                        value={side}
                        onChange={(value) => {
                            onSetSide(value)
                        }}
                        size={'middle'}
                    >
                        <Option value="Long">Long</Option>
                        <Option value="Short">Short</Option>
                    </Select>
                </div>
                <div>
                    <div className="text-experts-gray-6 text-xs">
                        Account Balance
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="accountBalance"
                        showError={false}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const _value = numberValidation(value, 3, 0)
                            if (_value)
                                formik.setValues({
                                    ...formik.values,
                                    [name]: _value,
                                })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (Number(value) < 100) {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '100',
                                })
                            }
                        }}
                        size={'middle'}
                    />
                </div>
                <div>
                    <div className="text-experts-gray-6 text-xs">Qty</div>
                    <FormikAntInput
                        formik={formik}
                        name="qty"
                        disabled
                        showError={false}
                        value={formatMoneyNumber(qty)}
                        size={'middle'}
                    />
                </div>
                <div>
                    <div className="text-experts-gray-6 text-xs">Margin</div>
                    <div className="flex flex-row">
                        <div className="custom-radio">
                            <Radio.Group
                                options={optionsRisk}
                                onChange={({ target: { value } }) => {
                                    onSetRisk(value)
                                    onSetRiskType('default')
                                }}
                                value={
                                    riskType === 'default' ? risk : undefined
                                }
                                optionType="button"
                                size={'middle'}
                            />
                        </div>
                        <div
                            className={`custom-input-risk ml-1 ${
                                riskType === 'custom'
                                    ? 'custom-input-risk-selected'
                                    : ''
                            }`}
                        >
                            <FormikAntInput
                                formik={formik}
                                name="customRisk"
                                placeholder=""
                                className="w-16"
                                showError={false}
                                onFocus={(e) => {
                                    onSetRiskType('custom')
                                }}
                                onChange={(e) => {
                                    const {
                                        target: { value, name },
                                    } = e
                                    const _value = numberValidation(
                                        value,
                                        3,
                                        0,
                                        100
                                    )

                                    onSetRiskType('custom')
                                    if (_value)
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: _value,
                                        })
                                }}
                                onBlur={(e) => {
                                    const { name, value } = e.target

                                    formik.setTouched({
                                        [name]: true,
                                    })
                                    if (value === '0') {
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: '1',
                                        })
                                    }
                                }}
                                size={'middle'}
                            />
                        </div>
                    </div>
                </div>{' '}
                <div>
                    <div className="text-experts-gray-6 text-xs">Leverage</div>
                    <FormikAntInput
                        formik={formik}
                        name="leverage"
                        placeholder=""
                        showError={false}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const _value = numberValidation(value, 1, 0, 100)

                            if (_value)
                                formik.setValues({
                                    ...formik.values,
                                    [name]: _value,
                                })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (value === '0') {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '10',
                                })
                            }
                        }}
                        size={'middle'}
                    />
                </div>
                <div>
                    <div className="mb-1 text-xs xl:text-base">Entry Price</div>
                    <FormikAntInput
                        formik={formik}
                        name="entryPrice"
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const _value = numberValidation(value, 10, 0)
                            if (_value)
                                formik.setValues({
                                    ...formik.values,
                                    [name]: _value,
                                })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (value === '0') {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '1',
                                })
                            }
                        }}
                        size={'middle'}
                        showError={false}
                    />
                </div>
                <div>
                    <div className="mb-1 text-xs xl:text-base">Take Profit</div>
                    <FormikAntInput
                        formik={formik}
                        name="takeProfit"
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const _value = numberValidation(value, 4, 0)
                            if (_value)
                                formik.setValues({
                                    ...formik.values,
                                    [name]: _value,
                                })
                        }}
                        showError={false}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (value === '0') {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '10',
                                })
                            }
                        }}
                        size={'middle'}
                    />
                </div>
            </form>
        </CustomModal>
    )
}

export default SimulatorFormModal
