import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useImperativeHandle,
} from 'react'

import { sleep } from './../../../utils/promise'
import SimulatorForm from './Simulator/SimulatorForm'

import SimulatorResults from './Simulator/SimulatorResults'
import useResponsiveValue from 'src/hooks/useResponsiveValue'
import Button from './../../Buttons/Button'
import Icon from './../../Icons/Icon'
import SimulatorStaticsParams from './Simulator/SimulatorStaticsParams'
import SimulatorFormModal from './Simulator/SimulatorFormModal'
import { validations } from 'src/service/yupValidations'
import { useFormik } from 'formik'
import * as yup from 'yup'

const MyConfigSimulator = React.forwardRef(
    (
        {
            stages,
            // initialLeverage = 30,
            // initialTP = 50,
        },
        ref
    ) => {
        const [init, setInit] = useState(false)
        const [visible, setVisible] = useState(false)
        const [side, setSide] = useState('Long')
        const [risk, setRisk] = useState(0.01)
        const [riskType, setRiskType] = useState('default')

        const [{ parameters, showResults, loading }, setResults] = useState({
            parameters: {},
            showResults: false,
            loading: false,
        })

        useEffect(() => {
            setInit(true)
        }, [])

        const handleSubmit = useCallback(
            async (values) => {
                setVisible(false)
                setResults((state) => ({
                    parameters: {},
                    showResults: true,
                    loading: true,
                }))

                await sleep(1000)

                const {
                    accountBalance,
                    entryPrice,
                    leverage,
                    takeProfit,
                    ...restValues
                } = Object.keys(values).reduce(
                    (acc, v) => ({ ...acc, [v]: Number(values[v]) }),
                    {}
                )
                const _risk =
                    riskType === 'default'
                        ? risk
                        : Number(values.customRisk) / 100

                setResults((state) => ({
                    ...state,
                    parameters: {
                        risk: _risk,
                        side,
                        qty: ((accountBalance * _risk) / entryPrice) * leverage,
                        leverage: leverage,
                        accountBalance,
                        entryPrice,
                        initialTP: takeProfit,
                        ...restValues,
                    },
                    loading: false,
                }))
            },
            [risk, side, riskType]
        )

        // eslint-disable-next-line no-unused-vars
        const isMobile = useResponsiveValue({ base: true, lg: false })
        const buttonSize = useResponsiveValue({
            base: 'small',
            lg: 'normal',
        })

        const schema = useMemo(() => {
            const schemaObj = {
                accountBalance: validations.noZero,
                leverage: validations.noZero,
                entryPrice: validations.noZero,
                takeProfit: validations.noZero,
            }
            if (riskType === 'default') return schemaObj
            return { ...schemaObj, customRisk: validations.noZero }
        }, [riskType])

        const yupSchema = yup.object().shape(schema)

        const formik = useFormik({
            initialValues: {
                accountBalance: '10000',
                leverage: '50',
                entryPrice: '20000',
                customRisk: '10',
                takeProfit: '100',
            },
            validationSchema: yupSchema,
            onSubmit: handleSubmit,
        })

        const qty = useMemo(() => {
            if (
                !formik.values.accountBalance ||
                !formik.values.entryPrice ||
                !formik.values.entryPrice
            )
                return 0

            const _risk =
                riskType === 'default'
                    ? risk
                    : Number(formik.values.customRisk) / 100

            return (
                ((formik.values.accountBalance * _risk) /
                    formik.values.entryPrice) *
                formik.values.leverage
            )
        }, [
            formik.values.accountBalance,
            formik.values.entryPrice,
            formik.values.leverage,
            formik.values.customRisk,
            risk,
            riskType,
        ])

        const goToCalculator = useCallback(() => {
            if (isMobile) setVisible(true)
            document.getElementById('calculator').scrollIntoView()
        }, [isMobile])

        useEffect(() => {
            if (isMobile) setVisible(false)
        }, [isMobile])

        useImperativeHandle(ref, () => ({
            goToCalculator,
        }))

        if (!init) return null
        return (
            <div id="calculator" className="pb-12 lg:pb-0">
                {!isMobile ? (
                    <React.Fragment>
                        <div className="font-bold text-black text-xs xl:text-base">
                            Calculator
                        </div>
                        <div className="bg-white shadow-experts-lg rounded-lg py-5 lg:py-8 px-3 lg:px-5 mt-4">
                            <SimulatorForm
                                formik={formik}
                                side={side}
                                risk={risk}
                                riskType={riskType}
                                qty={qty}
                                onSetRisk={(v) => setRisk(v)}
                                onSetRiskType={(v) => setRiskType(v)}
                                onSetSide={(v) => setSide(v)}
                            />
                            <SimulatorResults
                                side={side}
                                show={showResults}
                                loading={loading}
                                stages={stages}
                                parameters={parameters}
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <SimulatorFormModal
                            visible={visible}
                            formik={formik}
                            side={side}
                            risk={risk}
                            riskType={riskType}
                            qty={qty}
                            onSetRisk={(v) => setRisk(v)}
                            onSetRiskType={(v) => setRiskType(v)}
                            onSetSide={(v) => setSide(v)}
                            onCancel={() => setVisible(false)}
                        />
                        <div className="flex flex-row">
                            <div className="font-bold text-sm text-black">
                                Calculator
                            </div>
                            <div className="ml-auto">
                                <Button
                                    onClick={() => setVisible(true)}
                                    size={buttonSize}
                                    className="px-3"
                                >
                                    <Icon
                                        name="3-calculate"
                                        className="h-[18px] w-[18px]"
                                    />
                                </Button>
                            </div>
                        </div>
                        {showResults && (
                            <React.Fragment>
                                <div className="mt-4 mb-6">
                                    <SimulatorStaticsParams
                                        side={side}
                                        accountBalance={
                                            formik.values.accountBalance
                                        }
                                        qty={qty}
                                        margin={`${risk * 100}%`}
                                        leverage={formik.values.leverage}
                                        entryPrice={formik.values.entryPrice}
                                    />
                                </div>
                                <SimulatorResults
                                    show={showResults}
                                    loading={loading}
                                    stages={stages}
                                    parameters={parameters}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        )
    }
)

MyConfigSimulator.displayName = 'MyConfigSimulator'

export default MyConfigSimulator
