import { Divider, Dropdown, Menu } from 'antd'
import numeral from 'numeral'
import React from 'react'
import { HiDotsVertical } from 'react-icons/hi'
import { HEDGING, TITLE_BY_TYPE } from 'src/constants/phase.constants'
import Icon from './../../../Icons/Icon'

const StageItemMobile = ({
    index = 0,
    disabledButtons,
    data,
    hideDelete,
    onRemoveStage,
    onEdit,
}) => {
    const { action, condition, size, takeProfit } = data

    const menu = (
        <Menu>
            <Menu.Item onClick={onEdit}>
                <div className="flex flex-row items-center px-1">
                    <Icon
                        name="3-edit"
                        className="w-3 h-3 mr-3 text-experts-green-7"
                    />{' '}
                    <div className="text-[10px] text-black font-semibold">
                        Edit
                    </div>
                </div>
            </Menu.Item>
            {!hideDelete && (
                <React.Fragment>
                    {' '}
                    <Divider className="my-1" />
                    <Menu.Item onClick={onRemoveStage}>
                        <div className="flex flex-row items-center px-1">
                            <Icon name="3-trash" className="w-3 h-3 mr-3" />{' '}
                            <div className="text-[10px] text-black font-semibold">
                                Delete
                            </div>
                        </div>
                    </Menu.Item>
                </React.Fragment>
            )}
        </Menu>
    )
    return (
        <div className="bg-white rounded-lg shadow-experts-lg p-3">
            <div className="flex flex-row">
                <div className="font-bold text-black tracking-wider text-base">
                    {' '}
                    Stage {index + 1}
                </div>
                <div className="ml-auto flex flex-row items-center">
                    <div className="mr-2 rounded-full bg-experts-gray-10 shadow-inner text-black font-medium text-xs px-2 py-1">
                        {' '}
                        {TITLE_BY_TYPE[action]}
                    </div>
                    <Dropdown overlay={menu}>
                        <a onClick={(e) => e.preventDefault()}>
                            <HiDotsVertical />
                        </a>
                    </Dropdown>
                </div>
            </div>
            <div className="grid grid-cols-3 mt-3">
                <div>
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        Negative %
                    </div>
                    <div className="text-black font-medium">{condition}</div>
                </div>
                <div>
                    {' '}
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        Leverage/Qty
                    </div>
                    <div className="text-black font-medium">
                        {' '}
                        {numeral(size).format('0.00')}x
                    </div>
                </div>
                <div>
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        Take Profit
                    </div>
                    <div className="text-black font-medium">{action === HEDGING ? 'N/A' : `${takeProfit}%`}</div>
                </div>
            </div>
        </div>
    )
}

export default StageItemMobile
