import React from 'react'
import { HiDotsVertical } from 'react-icons/hi'

const CryptoCurrencyCard = ({ data }) => {
    const { symbol } = data ?? {}
    return (
        <div className="bg-white rounded-[5px] border border-experts-gray-2 pl-3 pr-2 py-2 ">
            <div className="flex flex-row ">
                <div className="flex flex-row gap-x-1 flex-1 overflow-hidden">
                    <div className="flex">
                        <div className="w-6 h-6 rounded-full bg-experts-yellow-3 mr-4"></div>
                        <div className="text-black font-medium tracking-wider text-sm">
                            {symbol}
                        </div>
                    </div>
                    <div className="ml-auto text-right text-xs">
                        <div>$0,000.00</div>
                        <div className="font-medium font-oxanium ">0.00%</div>
                    </div>{' '}
                </div>
                <div className="ml-auto">
                    {/* <Dropdown overlay={menu}> */}
                    <a onClick={(e) => e.preventDefault()}>
                        <HiDotsVertical />
                    </a>
                    {/* </Dropdown> */}
                </div>
            </div>
        </div>
    )
}

export default CryptoCurrencyCard
