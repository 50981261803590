import React from 'react'
import Icon from 'src/components/Icons/Icon'

const NoRecord = (props) => {
    return (
        <div className="py-5 flex flex-col items-center text-experts-gray-3">
            <Icon name="3-activetrades" className="w-[88px] h-[62px]" />
            <div className="text-xs mt-2">No Record Yet</div>
        </div>
    )
}

export default NoRecord
