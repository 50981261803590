import React, { useEffect, useCallback, useMemo } from 'react'
import { Select } from 'antd'
import useResponsiveValue from 'src/hooks/useResponsiveValue'
import { useGetSymbolByStrategy } from './../../store/reducers/strategies/symbolsStrategy'
import {
    useSetSymbol,
    useTradesReducerState,
} from 'src/store/reducers/tradesReducer/trades.hook'
import { useActivedStrategies } from 'src/store/reducers/strategies/strategiesReducer'

// const availableSymbols = [
//     'ADAUSDT',
//     'AVAXUSDT',
//     'BNBUSDT',
//     'BTCUSDT',
//     'ETHUSDT',
//     'MANAUSDT',
//     'MATICUSDT',
//     // 'ARUSDT',
//     // 'CELOUSDT',
//     'DASHUSDT',
//     'FTMUSDT',
//     'LRCUSDT',
//     'LTCUSDT',
//     'RUNEUSDT',
//     'VETUSDT',
//     'UNIUSDT',
//     'XRPUSDT',
//     'ATOMUSDT',
//     'CHZUSDT',
//     'SOLUSDT',
// ].sort()

const Option = Select.Option

const SelectSymbol = () => {
    const { selectedSymbol, selectedSide } = useTradesReducerState()

    const setSymbol = useSetSymbol()

    const activeStrategies = useActivedStrategies()

    const currentStrategyBySide = useMemo(() => {
        const _side = selectedSide === 'Long' ? 'Buy' : 'Sell'
        return activeStrategies.find((v) => v.side === _side)
    }, [selectedSide, activeStrategies])

    const { symbolsStrategy, fetching } = useGetSymbolByStrategy() ?? {}

    const symbols = symbolsStrategy?.[currentStrategyBySide?.id] ?? []

    const updateSymbolWhenStrategyChange = useCallback(() => {
        if (
            !selectedSymbol ||
            !symbols.find((v) => v.symbol === selectedSymbol)
        )
            setSymbol(symbols[0].symbol)
    }, [selectedSymbol, symbols, setSymbol])

    useEffect(() => {
        if (symbols.length > 0) updateSymbolWhenStrategyChange()
    }, [symbols])

    const selectSize = useResponsiveValue({ base: 'medium', xl: 'large' })

    return (
        <div className="custom-select">
            <Select
                loading={fetching}
                size={selectSize}
                defaultValue="all"
                className="w-32 lg:w-64 md text-left text-sm xl:text-lg mt-2"
                onChange={(v) => {
                    setSymbol(v)
                }}
                // bordered={false}
                value={selectedSymbol}
            >
                {symbols?.map((s, index) => {
                    return (
                        <Option key={`${s}`} value={s.symbol}>
                            {s.symbol}
                        </Option>
                    )
                })}
            </Select>
        </div>
    )
}

export default SelectSymbol
