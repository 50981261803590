import { removeZerosIfInteger } from 'src/utils/formatter'
import { countCaracters } from './../utils/stringUtils'
import { removeExtraDecimals } from '../utils/formatter'

export const numberValidation = (
    value,
    decimals = undefined,
    minValue = undefined,
    maxValue = undefined,
    onlyInteger = false
) => {
    const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/

    if (value === '' || value === '0') {
        return minValue.toString() ?? '0'
    }

    let _value = value

    if (!onlyInteger && countCaracters(_value, '.') > 1) return undefined
    if (onlyInteger && countCaracters(_value, '.') > 0) return undefined

    if (_value.length <= 20 && !Number.isNaN(Number(_value))) {
        if (regex.test(_value) && Number(_value) !== 0) {
            _value = removeZerosIfInteger(_value).toString()
        }

        // if (onlyInteger && !isInteger(_value)) return undefined
        // if (!onlyInteger)
        if (decimals) {
            _value = removeExtraDecimals(_value, decimals)
        }
        console.log({ maxValue, _value })
        if (minValue && Number(_value) < minValue) return undefined
        if (maxValue && Number(_value) > maxValue) return undefined
        return _value.trim()
    }
    return undefined
}

export const removeDotIfFloatNotCompleted = (v) => {
    if (v === '' || countCaracters(v, '.') === 0) return undefined
    const splited = v.split('.')
    if (splited[1].length === 0) return splited[0]
}
