import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import axios from 'axios'
// import axios from 'axios'

import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axiosInstance from '../../services/axiosConfig'
import { sleep } from './../../../utils/promise'
import { notification } from 'antd'
import customAntNotifications from 'src/service/customAntNotifications'

export const getTraders = createAsyncThunk(
    'admin/traders',
    async (cancelToken, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(`bybit/admin/getallusers/`, {
                cancelToken,
            })
            return res.data.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const getSymbols = createAsyncThunk(
    'admin/fetchSymbols',
    async (cancelToken, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(`bybit/admin/symbolgetall/`, {
                cancelToken,
            })

            return res.data.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const getSharedKeys = createAsyncThunk(
    'admin/sharekeys',
    async (cancelToken, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/admin/sharedkeygetall/`,
                {
                    cancelToken,
                }
            )
            return res.data.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const addSymbol = createAsyncThunk(
    'admin/addSymbol',
    async ({ symbol, cancelToken }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post(`bybit/admin/symboladd/`, {
                symbol,
                cancelToken,
            })
            return res.data.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const deleteSymbol = createAsyncThunk(
    'admin/symboldelete',
    async ({ id, cancelToken }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.delete(
                `bybit/admin/symboldelete/${id}`,
                {
                    cancelToken,
                }
            )
            return res.data.result.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const addSharedkey = createAsyncThunk(
    'admin/addSharedKey',
    async ({ keyId, users }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post(`bybit/admin/sharedkeyadd/`, {
                users: users,
                keyId: keyId,
            })
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

const initialState = { traders: [], symbols: [], sharedkeys: [], reload: false }

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        reload: (state) => {
            state.reload = !state.reload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTraders.fulfilled, (state, { payload }) => {
                state.traders = payload
            })
            .addCase(getSymbols.fulfilled, (state, { payload }) => {
                state.symbols = payload
            })
            .addCase(getSharedKeys.fulfilled, (state, { payload }) => {
                console.log({ payload })
                state.sharedkeys = payload
            })
    },
})

export const { reload } = adminSlice.actions

const selectSelf = (state) => state.adminReducer
const selectSymbolsAdmin = createSelector(selectSelf, (state) => state.symbols)

export const useReloadAdmin = () => {
    const dispatch = useDispatch()
    return useCallback(() => dispatch(reload()), [dispatch])
}

export const useAdmin = () => {
    return useSelector(selectSelf)
}

export const useAdminSymbols = () => {
    return useSelector(selectSymbolsAdmin)
}

export const useShareKeys = () => {
    const { sharedkeys } = useAdmin()
    return { sharedkeys }
}

export const useFetchTraders = () => {
    const dispatch = useDispatch()
    const [fetching, setFetching] = useState(false)
    const lastReloadValue = useRef(null)
    const cancelCall = useRef(null)
    const { reload } = useAdmin()

    const fetch = useCallback(() => {
        if (fetching && reload !== lastReloadValue.current) {
            console.log('Cancel Order')
            cancelCall.current.cancel('Reload Executed')
        }
        setFetching(true)
        cancelCall.current = axios.CancelToken.source()

        dispatch(getTraders(cancelCall.current.token))
    }, [dispatch])

    useEffect(() => {
        fetch()
    }, [reload])
}

export const useFetchSymbols = () => {
    const dispatch = useDispatch()
    const [fetching, setFetching] = useState(false)
    const lastReloadValue = useRef(null)
    const cancelCall = useRef(null)
    const { reload } = useAdmin()

    const fetch = useCallback(() => {
        if (fetching && reload !== lastReloadValue.current) {
            console.log('Cancel Order')
            cancelCall.current.cancel('Reload Executed')
        }
        setFetching(true)
        cancelCall.current = axios.CancelToken.source()

        dispatch(getSymbols(cancelCall.current.token))
    }, [dispatch])

    useEffect(() => {
        fetch()
    }, [reload])
}

export const useFetchShareKeys = () => {
    const dispatch = useDispatch()
    const [fetching, setFetching] = useState(false)
    const lastReloadValue = useRef(null)
    const cancelCall = useRef(null)
    const { reload } = useAdmin()

    const fetch = useCallback(() => {
        if (fetching && reload !== lastReloadValue.current) {
            console.log('Cancel Order')
            cancelCall.current.cancel('Reload Executed')
        }
        setFetching(true)
        cancelCall.current = axios.CancelToken.source()

        dispatch(getSharedKeys(cancelCall.current.token))
    }, [dispatch])

    useEffect(() => {
        fetch()
    }, [reload])
}

export const useAddSymbol = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const action = useCallback(
        async (symbol) => {
            if (loading) return
            setLoading(true)

            try {
                await sleep(100)
                await dispatch(
                    addSymbol({
                        symbol,
                    })
                ).unwrap()
                notification.success({
                    icon: customAntNotifications.sucess,
                    message: 'Create New Crypto Currency',
                    description:
                        'You successfully create a new Crypto Currency',
                    placement: 'bottomRight',
                })
            } catch (err) {
                setError(err)
                const errorArgs = {
                    icon: customAntNotifications.error,
                    message: 'Error',
                    description:
                        err?.error === 'true'
                            ? err?.message
                            : 'Something went wrong while trying to process your transaction. Try again',
                    placement: 'bottomRight',
                }
                notification.error(errorArgs)
            } finally {
                setLoading(false)
            }
        },
        [loading, dispatch]
    )

    return { addSymbol: action, loading, error }
}

export const useSetSharedKey = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const fetch = useCallback(
        async (keyId, users) => {
            try {
                setLoading(true)
                await sleep(600)
                await dispatch(addSharedkey({ keyId, users })).unwrap()
            } catch (error) {
                setError(error)
                throw error
            }
            setLoading(false)
        },
        [dispatch]
    )

    return { SetSharedKey: fetch, error, loading }
}

export default adminSlice.reducer
