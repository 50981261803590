import { createSelector } from '@reduxjs/toolkit'

export const selectSelf = (state) => state.tradesReducer
export const selectedSymbolSelect = createSelector(
    selectSelf,
    (reducer) => reducer.selectedSymbol
)
export const symbolsUsedSelect = createSelector(
    selectSelf,
    (reducer) => reducer.symbolsUsed
)
