import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Drawer } from 'antd'
import { TbArrowLeft } from 'react-icons/tb'

import { useUserInfo } from './../store/reducers/userInfoReducer/userInfoReducer'
import { PATHS, VIEW_TITLE_BY_PATHS } from './../constants/pathConstants'
import Icon from './Icons/Icon'

const menus = [
    { menuName: 'HOME', path: '/dashboard', icon: 'overview' },
    // { menuName: 'LIVE TRADING', path: '/livetrading', icon: MdDashboard },
    // { menuName: 'TRADES', path: '/dashboard', icon: TbArrowsRightLeft },
    // { menuName: 'ACCOUNTS', path: '/keys', icon: MdOutlinePeopleOutline },
    // { menuName: 'CONFIG', path: '/config', icon: MdOutlineSettings },
]

// eslint-disable-next-line no-unused-vars
const getViewTitleByPath = (pathName) => {
    const index = PATHS.findIndex((v) => v.includes(pathName))

    if (index !== -1) return VIEW_TITLE_BY_PATHS[PATHS[index]]
    return ''
}

const RightMenu = ({ isMobile }) => {
    const navigate = useNavigate()

    const { username } = useUserInfo()

    return (
        <div className="flex flex-row ml-auto h-8 lg:h-11">
            <div className="flex justify-center items-center cursor-pointer text-base lg:text-2xl mr-3 lg:mr-8 h-full w-8 lg:w-11 bg-white rounded-[9px] shadow-experts-lg">
                <Icon name="3-bell" className="w-4 h-4 lg:w-5 lg:h-5" />
            </div>
            <div className="text-white flex flex-row items-center justify-center rounded-lg bg-experts-gray-7 shadow-experts-lg px-3 lg:px-5 h-full gap-3 md:gap-3">
                <div className=" text-xs lg:text-[13px] font-semibold">
                    {username}
                </div>
                <div
                    className="flex cursor-pointer text-base lg:text-lg"
                    onClick={() => navigate('profile')}
                >
                    {/* <AiOutlineUser /> */}
                    <Icon
                        name="1-user"
                        className="w-4 h-4 lg:w-[18px] lg:h-[18px]"
                    />
                </div>
            </div>
        </div>
    )
}

const ContextMenuItem = ({ path, menuName, icon }) => {
    const navigate = useNavigate()
    // const Icon = icon
    return (
        <div
            className=" flex flex-row pl-[25%]  pt-4 cursor-pointer"
            onClick={() => navigate(path)}
        >
            <Icon name={icon} className="w-5 h-5 relative mt-[0.18rem]" />
            <span className="px-2 pb-2 border-transparent border-2 ">
                {menuName}
            </span>
        </div>
    )
}

const ContextMenu = () => {
    return (
        <div className="">
            {menus.map((v) => (
                <ContextMenuItem
                    key={`context-menu-item-${v.menuName}`}
                    {...v}
                />
            ))}
        </div>
    )
}

const LeftMenu = () => {
    const [visible, setVisible] = useState(false)
    const handleShowDrawer = () => setVisible((state) => !state)
    const { pathname } = useLocation()

    return (
        <React.Fragment>
            <Drawer
                drawerStyle={{ backgroundColor: '#8D793F' }}
                headerStyle={{
                    backgroundColor: '#FFF',
                    border: 0,
                    margin: 0,
                    paddingTop: '1.2rem',
                }}
                bodyStyle={{
                    backgroundColor: '#FFF',
                    color: '8D793F',
                    paddingRight: '2.5rem',
                }}
                title={
                    <button onClick={handleShowDrawer} className=" float-right">
                        <TbArrowLeft size={'1.6rem'} className="" />
                    </button>
                }
                width={320}
                placement="left"
                closable={false}
                onClose={handleShowDrawer}
                visible={visible}
            >
                <ContextMenu />
            </Drawer>
            <div className="text-black font-bold text-base xl:text-2xl">
                {getViewTitleByPath(pathname)}
            </div>
        </React.Fragment>
    )
}

const DefaultNavbar = () => {
    return (
        <div className="flex items-center w-full my-auto lg:mt-auto lg:mb-8">
            <LeftMenu />
            <RightMenu />
        </div>
    )
}

const Header = () => {
    return (
        <header className="flex items-end min-h-[80px] h-20 lg:h-[100px] lg:min-h-[100px] text-sm font-medium lg:px-3 xl:px-2 2xl:px-0">
            <DefaultNavbar />
        </header>
    )
}

export default Header
