import React from 'react'
import { useTradesReducerState } from 'src/store/reducers/tradesReducer/trades.hook'
import NoRecord from './NoRecord'
import TableTrades from './Tables/TableTrades'
import TradeCard from './TradeCard'

const lowerStageNumber = (stageName) => {
    const chars = stageName.split(' ')

    const stageNum = Number(chars[1]) - 1

    if (stageNum === 0) return 'Entry Stage'
    return `${chars[0]} ${stageNum}`
}

const MMTrades = ({
    reloading,
    unsubscribing,
    onUnsubscribeMM,
    onClose,
    deleting,
    disabled,
    onEditSlTp,
    isMobile,
}) => {
    const { tradesMoney, activeOrders } = useTradesReducerState()

    return (
        <React.Fragment>
            <div className="flex justify-start font-bold text-experts-gray-9 text-[16px] pl-2">
                Money Management Trades
            </div>{' '}
            {!isMobile ? (
                <div className="w-full max-w-full">
                    <TableTrades
                        dataSource={tradesMoney.map(
                            ({ phase, manualTP, takeProfitTrigger, ...v }) => {
                                return {
                                    ...v,
                                    phase: lowerStageNumber(phase),
                                    tp: manualTP
                                        ? activeOrders?.find(
                                              (a) =>
                                                  a.symbol === v.symbol &&
                                                  a.reduce_only
                                          )?.price ?? '0'
                                        : '0',
                                    takeProfitTrigger: manualTP
                                        ? activeOrders?.find(
                                              (a) =>
                                                  a.symbol === v.symbol &&
                                                  a.reduce_only
                                          )?.price ?? '0'
                                        : takeProfitTrigger,
                                }
                            }
                        )}
                        checked
                        onAction={onUnsubscribeMM}
                        onClose={onClose}
                        // activeSwitch={false}
                        buttonText="Deactivate money management"
                        // hideColumns={['pnlusd']}
                        onEditSlTp={onEditSlTp}
                        loading={unsubscribing || reloading || deleting}
                        disabled={disabled}
                        enableEditSlTP
                    />
                </div>
            ) : (
                <div className="space-y-2">
                    {tradesMoney.length === 0 && <NoRecord />}
                    {tradesMoney
                        .map(({ phase, ...v }) => {
                            return {
                                ...v,
                                phase: lowerStageNumber(phase),
                                tp:
                                    activeOrders?.find(
                                        (a) =>
                                            a.symbol === v.symbol &&
                                            a.reduce_only
                                    )?.price ?? '0',
                            }
                        })
                        .map((v) => (
                            <TradeCard
                                key={`trade-card-mm-${v.id}`}
                                checked
                                data={v}
                                onClose={onClose}
                                loading={unsubscribing || reloading || deleting}
                                onEditSlTp={onEditSlTp}
                                onSwitch={onUnsubscribeMM}
                                enableEditSlTP
                            />
                        ))}
                </div>
            )}
        </React.Fragment>
    )
}

export default MMTrades
