import React, { useState, useCallback, useEffect } from 'react'

import { Select, Modal, Table as TableAnt } from 'antd'
// import { IoIosHelpCircleOutline } from 'react-icons/io'
// import classNames from 'classnames'

import Button from './../components/Buttons/Button'

import {
    useEmisors,
    useFetchKeysEmisor,
    useFetchSubFromSelectedEmisor,
    useSwitchTradingManager,
} from './../store/reducers/dashboardReducer/dashboardReducer'
// import {} from 'antd/lib/mentions'
import { sleep } from 'src/utils/promise'

import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import { Doughnut, Bar } from 'react-chartjs-2'
import {
    Chart,
    ArcElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    BarElement,
} from 'chart.js'

const { Option } = Select

Chart.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip
)

const dataG = {
    labels: ['Hi'],
    datasets: [
        {
            label: '',
            data: [2],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(106, 90, 205, 0.2)',
                'rgba(255, 165, 0, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(106, 90, 205, 1)',
                'rgba(255, 165, 0, 1)',
            ],
            borderWidth: 1,
        },
    ],
}

const SwitchEmisorOwner = ({
    visible,
    onClose,
    enableTrading,
    reloadSubs,
    currentEmisor,
    selectedKey,
}) => {
    const [loading, setLoading] = useState(false)
    const switchTradingManager = useSwitchTradingManager()

    const handleOnChange = useCallback(async () => {
        try {
            setLoading(true)
            await sleep(600)
            await switchTradingManager(
                currentEmisor.index,
                selectedKey
            ).unwrap()
            reloadSubs()
            onClose()
        } catch (error) {
            window.alert('Failed to switch')
            console.log(error)
        }
        setLoading(false)
    }, [enableTrading, currentEmisor, selectedKey])

    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            onCancel={onClose}
            maskClosable={loading}
            footer={null}
            width={526}
            bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignContent: 'center',
            }}
        >
            <div className="flex flex-col gap-2 text-center py-3">
                <div className="font-semibold text-base  tracking-widest">
                    Active Trade
                </div>
                <div className="font-bold text-base tracking-widest ">
                    Enter your code to start trading
                </div>
            </div>
            <div></div>

            <div className="flex flex-row w-full gap-4 mt-4">
                <Button
                    loading={loading}
                    onClick={() => {
                        handleOnChange()
                    }}
                    className="w-full"
                    variant="light"
                >
                    Switch
                </Button>
                <Button className="w-full" onClick={() => onClose()}>
                    Cancel
                </Button>
            </div>
        </Modal>
    )
}

// const ShareCode = ({ profitFee }) => {
//     return (
//         <div className="flex flex-col md:flex-row  lg:flex-col gap-4 xl:gap-6">
//             <div className="w-full bg-white rounded-lg shadow-experts-lg px-8 lg:px-5 xl:px-10 py-7">
//                 <div className="flex flex-col gap-4">
//                     <div className="flex flex-col justify-end items-center">
//                         <div className="font-medium text-xs xl:text-sm  tracking-wider">
//                             ACTIVATION CODE
//                         </div>
//                         <div className="mt-5 xl:mt-7 text-2xl xl:text-3xl  font-extrabold leading-none tracking-wider">
//                             asdasd
//                         </div>
//                     </div>

//                     <Button>Copy</Button>
//                 </div>
//             </div>
//         </div>
//     )
// }

const TraderStatistics = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [emisorSelected, setEmisorSelected] = useState(undefined)
    // const [selectedKey, setSelectedKey] = useState(null)

    const { userEmisors, emisors } = useEmisors()
    console.log({ emisors })
    const params = useParams()
    console.log({ params })
    useFetchKeysEmisor()
    const { reload } = useFetchSubFromSelectedEmisor(emisorSelected)

    useEffect(() => {
        if (userEmisors.length > 0 && !emisorSelected) {
            setEmisorSelected(0)
        }
    }, [userEmisors, emisorSelected])

    const dataBarGraph = {
        labels: [],
        datasets: [
            {
                label: 'Wins',
                data: [],
                borderColor: 'rgb(1, 166, 166)',
                backgroundColor: 'rgba(1, 166, 166, 0.5)',
                borderWidth: 1,
            },
            {
                label: 'Losses',
                data: [],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderWidth: 1,
            },
        ],
    }

    return (
        <div className="flex flex-col md:flex-row gap-4 lg:gap-10 xl:pr-12">
            <div className="lg:pb-6 flex-grow flex flex-col gap-4 xl:gap-6">
                <div className="flex flex-row items-end w-full border-b  pb-4 xl:pb-6">
                    <div className="flex flex-row flex-grow">
                        <div className="">
                            <div className="text-base Xl:text-lg font-medium relative ">
                                Live active trading account
                            </div>
                            <div className="text-lg xl:text-2xl font-extrabold  uppercase border-0 w-full flex flex-row items-end gap-8">
                                {/* <div>{userEmisors[emisorSelected]?.name}</div> */}
                                {userEmisors.length > 0 && (
                                    <Select
                                        size="medium"
                                        defaultValue="all"
                                        className=" w-32 text-left  text-lg xl:text-2xl mt-2"
                                        onChange={(v) => {
                                            setEmisorSelected(v)
                                        }}
                                        bordered={false}
                                        value={emisorSelected}
                                    >
                                        {userEmisors.map((key, index) => {
                                            return (
                                                <Option
                                                    key={`${key.name}`}
                                                    value={index}
                                                >
                                                    {key.name}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="ml-auto flex flex-col items-end">
                        <Button
                            // disabled
                            variant="none"
                            onClick={() => {
                                setShowModal(true)
                            }}
                            className={classNames(
                                'w-[86px] mt-3 text-[9px] xl:text-xs leading-3 tracking-wide py-[0.4rem] font-medium',
                                {
                                    'bg-gradient-to-r from-experts-blue-300 to-experts-blue-200 text-white': true,
                                    ' bg-gradient-to-b from-experts-gray-250 to-experts-gray-150 border ': false,
                                }
                            )}
                        >
                            Switch
                        </Button>
                    </div>
                </div>

                <div className="flex flex-row gap-6">
                    <div className="flex-1 flex flex-col gap-6">
                        <div className="w-full flex flex-row bg-white rounded-lg shadow-experts-lg gap-5 px-3 py-2 lg:px-6 lg:py-4">
                            <div className="w-4/6 mx-auto">
                                <Bar
                                    options={{
                                        responsive: true,
                                        plugins: {},
                                        scales: {
                                            x: {
                                                stacked: true,
                                                grid: {
                                                    drawBorder: true,
                                                    color: 'rgba(116, 116, 116, 0.2)',
                                                    opacity: '0',
                                                },
                                            },
                                            y: {
                                                stacked: true,
                                                grid: {
                                                    drawBorder: true,
                                                    color: 'rgba(116, 116, 116, 0.2)',
                                                    opacity: '0.5',
                                                },
                                            },
                                        },
                                    }}
                                    data={dataBarGraph}
                                />
                            </div>
                            <div className="flex-1 flex flex-col gap-4">
                                <div className="text-xl text-center">Title</div>
                                <div className="w-full flex flex-row border-b pb-2">
                                    <div>Temp</div>
                                    <div className="ml-auto">0</div>
                                </div>
                                <div className="w-full flex flex-row border-b pb-2">
                                    <div>Temp</div>
                                    <div className="ml-auto">0</div>
                                </div>
                                <div className="w-full flex flex-row border-b pb-2">
                                    <div>Temp</div>
                                    <div className="ml-auto">0</div>
                                </div>
                                <div className="w-full flex flex-row border-b pb-2">
                                    <div>Temp</div>
                                    <div className="ml-auto">0</div>
                                </div>
                            </div>
                        </div>
                        <TableAnt
                            className="flex-1 max-h-[450px]"
                            center
                            columns={[]}
                            dataSource={[]}
                        />
                    </div>
                    <div className="w-1/3 flex flex-col">
                        <div className="w-full bg-white rounded-lg shadow-experts-lg px-8 lg:px-5 xl:px-10 py-7">
                            <div className="w-64 mx-auto">
                                {' '}
                                <Doughnut
                                    title="Balances"
                                    data={dataG}
                                    options={{
                                        plugins: {
                                            legend: {
                                                position: 'top',
                                            },
                                            autocolors: {
                                                mode: 'data',
                                            },
                                            title: {
                                                display: true,
                                                text: 'Chart.js Line Chart',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <SwitchEmisorOwner
                    visible={showModal}
                    selectedKey={0}
                    currentEmisor={
                        userEmisors ? userEmisors[emisorSelected] : null
                    }
                    reloadSubs={reload}
                    onClose={() => setShowModal(false)}
                />
            </div>{' '}
            {/* <div className="w-full md:w-[250px] xl:w-[392px] pb-6">
                <ShareCode />
            </div> */}
        </div>
    )
}

TraderStatistics.propTypes = {}

export default TraderStatistics
