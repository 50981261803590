import React, { useState } from 'react'
import Button from 'src/components/Buttons/Button'
import useResponsiveValue from 'src/hooks/useResponsiveValue'
import {
    useAdminSymbols,
    useFetchSymbols,
} from 'src/store/reducers/adminReducer/adminReducer'
import Icon from './../../Icons/Icon'
import CryptoCurrenciesList from './CryptoCurrenciesList'
import CryptoCurrenciesModal from './CryptoCurrenciesModal'

const CryptoCurrenciesPage = (props) => {
    const [visible, setVisible] = useState(false)
    const buttonSize = useResponsiveValue({
        base: 'small',
        lg: 'normal',
    })
    useFetchSymbols()
    const symbols = useAdminSymbols()

    return (
        <React.Fragment>
            <CryptoCurrenciesModal
                visible={visible}
                onClose={() => setVisible(false)}
                symbols={symbols}
            />
            <div className="w-full h-full">
                <div className="flex flex-row items-end justify-between">
                    <div className="text-lg font-bold">Crypto Currencies</div>
                    <Button
                        size={buttonSize}
                        onClick={() => setVisible(true)}
                        variant="gradient"
                        className="w-full lg:w-auto text-sm lg:text-lg"
                    >
                        <Icon name="3-new" className="h-[18px] w-[18px] mr-3" />
                        <div className="">Add New Crypto</div>
                    </Button>
                </div>{' '}
                <CryptoCurrenciesList symbols={symbols} />
            </div>
        </React.Fragment>
    )
}

export default CryptoCurrenciesPage
