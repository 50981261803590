import React, { useCallback } from 'react'
import { useFormik } from 'formik'

import FormikAntInput from '../../../FormikAntDInputs/FormikAntInput'
import Button from '../../../Buttons/Button'
import { Select, Tooltip } from 'antd'

import numeral from 'numeral'
import useResponsiveValue from '../../../../hooks/useResponsiveValue'
import {
    PHASE_TYPES_ARR,
    TITLE_BY_TYPE,
    FIELD_TITLE_BY_TYPE,
    TOOLTIP_MESSAGE,
    CONDITION_NEGATIVE_TITLE_BY_TYPE,
    HEDGING,
} from 'src/constants/phase.constants'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { setOptionsValidationsByType } from './../helpers/stagesFieldValidatios'
import {
    formatValueToNumberOnChange,
    formatValueToNumber,
} from 'src/utils/inputsUtilities'
import * as yup from 'yup'

const { Option } = Select

const schema = yup.object().shape({
    condition: yup
        .number()
        .moreThan(0, 'Must be greater than 0')
        .required('Required'),
    size: yup
        .number()
        .moreThan(0, 'Must be greater than 0')
        .required('Required'),
    takeProfit: yup
        .string()
        .required('Required')
        .when('action', (action) => {
            console.log(action)
            if (action === HEDGING) {
                return yup.string().equals(['N/A'])
            } else {
                return yup
                    .number()
                    .moreThan(0, 'Must be greater than 0')
                    .required('Required')
            }
        }),
})

const StageEditForm = ({
    initialValues = {
        // name: '',
        action: 'Leverage',
        condition: '50',
        size: '50',
        takeProfit: '100',
    },
    onSave,
    onCancel,
}) => {
    const handleSubmit = useCallback(
        ({ size, action, takeProfit, ...values }) => {
            const $takeProfit = action === HEDGING ? 'N/A' : takeProfit

            onSave({
                ...values,
                takeProfit: $takeProfit,
                action,
                size: numeral(size).format('0.00'),
            })
        },
        []
    )


    const formik = useFormik({
        initialValues: {
            ...initialValues,
            takeProfit: initialValues.action === HEDGING ? 'N/A' : initialValues.takeProfit,
        },
        onSubmit: handleSubmit,
        validationSchema: schema,
    })

    const buttonSize = useResponsiveValue({ base: 'small', xl: 'normal' })
    const inputSize = useResponsiveValue({ base: 'small', xl: 'middle' })
    
    return (
        <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col py-2 lg:py-6 px-3 lg:px-8"
        >
            <div className="flex flex-col md:flex-row gap-3 lg:gap-8">
                <div className="flex-1">
                    <div className="text-xs tracking-wide mb-2">
                        Action to take
                    </div>
                    <Select
                        className="w-full"
                        value={formik.values?.action}
                        onChange={(v) => {
                            const newState = {
                                ...formik.values,
                                action: v,
                            }
                            if (v === HEDGING) {
                                newState.takeProfit = 'N/A'
                            } else if (formik.values?.takeProfit === 'N/A') {
                                newState.takeProfit = '100'
                            }
                            formik.setValues(newState)
                        }}
                        size={inputSize}
                    >
                        {PHASE_TYPES_ARR.map((type) => (
                            <Option key={`option-${type}`} value={type}>
                                {TITLE_BY_TYPE[type]}
                            </Option>
                        ))}
                    </Select>
                    {/* <FormikAntInput formik={formik} /> */}
                </div>
                <div className="flex-1">
                    <div className="text-xs tracking-wide mb-2">
                        {CONDITION_NEGATIVE_TITLE_BY_TYPE[
                            formik.values?.action
                        ] || CONDITION_NEGATIVE_TITLE_BY_TYPE.DEFAULT}
                        %
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="condition"
                        // showError={false}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e

                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 2,
                            })

                            formik.setValues({
                                ...formik.values,
                                [name]: $value,
                            })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (value === '0') {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '10',
                                })
                            } else {
                                const $value = formatValueToNumber(value, '10')

                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }
                        }}
                    />
                </div>
                <div className="flex-1">
                    <div className="flex flex-row">
                        <div className="text-xs tracking-wide mb-2">
                            {FIELD_TITLE_BY_TYPE[formik.values?.action]}
                        </div>
                        <Tooltip title={TOOLTIP_MESSAGE[formik.values?.action]}>
                            <AiOutlineQuestionCircle className="ml-1" />
                        </Tooltip>
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="size"
                        // showError={false}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(
                                value,
                                setOptionsValidationsByType(
                                    formik.values?.action
                                )
                            )
                            formik.setValues({
                                ...formik.values,
                                [name]: $value,
                            })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (value === '0') {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '10',
                                })
                            } else {
                                const $value = formatValueToNumber(value, '10')

                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }
                        }}
                    />
                </div>
                <div className="flex-1">
                    <div className="text-xs tracking-wide mb-2">
                        Take Profit %
                    </div>
                    <FormikAntInput
                        formik={formik}
                        name="takeProfit"
                        // showError={false}
                        disabled={formik.values?.action === HEDGING}
                        value={
                            formik.values?.action === HEDGING
                                ? 'N/A'
                                : formik.values?.takeProfit
                        }
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 4,
                                min: 0,
                                enableEmptyValue: true,
                            })

                            formik.setValues({
                                ...formik.values,
                                [name]: $value,
                            })
                        }}
                        onBlur={(e) => {
                            const { name, value } = e.target

                            formik.setTouched({ [name]: true })
                            if (value === '0') {
                                formik.setValues({
                                    ...formik.values,
                                    [name]: '10',
                                })
                            } else {
                                const $value = formatValueToNumber(value, '10')

                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }
                        }}
                    />
                </div>
            </div>
            <div className="ml-auto flex gap-2 mt-5">
                <Button
                    size={buttonSize}
                    type="submit"
                    className="w-20 lg:w-24 h-8"
                >
                    Accept
                </Button>
                <Button
                    size={buttonSize}
                    onClick={onCancel}
                    variant="light"
                    className="w-20 lg:w-24 h-8"
                >
                    Cancel
                </Button>
            </div>
        </form>
    )
}

const StageEdit = ({ isNew, initialValues, onSave, onCancel }) => {
    return (
        <div className="rounded-md relative border-experts-gray-2 bg-white shadow-experts-lg overflow-hidden">
            <div className="py-1 px-3 lg:px-8 rounded-md bg-black ">
                <div className="font-semibold text-white tracking-widest text-sm lg:text-base">
                    {isNew ? 'New Stage' : 'Edit Stage'}
                </div>
            </div>
            <StageEditForm
                onSave={onSave}
                onCancel={onCancel}
                initialValues={initialValues}
            />
        </div>
    )
}

export default StageEdit
