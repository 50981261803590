const LIVE_TRADES = '/livetrades'
const TOGGLE = '/toggle'
const CONFIG = '/config'
const TRADE = '/trade'
const ADMIN = '/admin'

export const PATHS = [LIVE_TRADES, TOGGLE, CONFIG, TRADE, ADMIN]

export const VIEW_TITLE_BY_PATHS = {
    [LIVE_TRADES]: 'My Live Trades',
    [TOGGLE]: 'Toggle',
    [CONFIG]: 'My Config',
    [TRADE]: 'Place Trade',
    [ADMIN]: 'Admin',
}
