import React, { useState, useMemo } from 'react'
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom'
// import AlertManager from '../AlertManager'
import Header from '../header'

// import LuxePassIcon from '../Icons/LuxePassIcon'

import LogoutConfirm from '../Modals/LogoutConfirm'
import useResponsiveValue from './../../hooks/useResponsiveValue'
import Icon from './../Icons/Icon'
import { useFetchMMStrategies } from 'src/store/reducers/strategies/strategiesReducer'
import { useUserInfo } from './../../store/reducers/userInfoReducer/userInfoReducer'

const MenuSideBarIcon = () => {
    const navigate = useNavigate()

    return (
        <div className="h-[160px] w-full relative flex items-end">
            <div
                className="mx-auto mb-10 cursor-pointer"
                onClick={() => navigate('/')}
            >
                <Icon name="emblem" className="w-20 h-20" />
                {/* <LuxePassIcon width={iconSize} height={iconSize} /> */}
            </div>
        </div>
    )
}

const MenuSideBarItem = ({
    children,
    menuName,
    id,
    to = '',
    title,
    onClick,
    icon,
    isMobile,
}) => {
    // const navigate = useNavigate()
    const location = useLocation()

    const isSelected = useMemo(() => {
        return to && location.pathname.includes(to)
    }, [location, to])

    // const isMobile = useMediaQuery('(max-width: 1023px)')

    const props = to ? { to } : { onClick }

    return (
        <Link className="mx-auto flex flex-col items-center" id={id} {...props}>
            {' '}
            <div
                className={`w-9 h-9 lg:h-11 lg:w-11 flex justify-center items-center rounded-xl ${
                    isSelected
                        ? 'bg-experts-green-5 text-white'
                        : 'bg-experts-gray-6 text-experts-gray-5'
                }`}
            >
                <Icon
                    name={icon}
                    className={`h-4 w-4 lg:h-6 lg:w-6 ${
                        isSelected
                            ? 'text-experts-green-1'
                            : 'text-experts-gray-5'
                    }`}
                />
            </div>
            {!isMobile && (
                <div
                    className={`mt-2 text-[10px] lg:text-xs ${
                        isSelected ? 'text-white' : 'text-experts-gray-5'
                    }`}
                >
                    {title}
                </div>
            )}
        </Link>
    )
}

const menus = [
    {
        menuName: 'toggle',
        to: '/toggle',
        icon: '2-toggle',
        staking: true,
        title: 'Toggle',
    },
    {
        menuName: 'config',
        to: '/config',
        icon: '2-configuration',
        staking: true,
        title: 'Configuration',
    },
    // {
    //     menuName: 'livetrades',
    //     to: '/livetrades',
    //     icon: RiDashboardLine,
    //     staking: true,
    //     title: 'Live trades',
    // },
    {
        menuName: 'trade',
        to: '/trade',
        icon: '2-livetrade',
        staking: true,
        title: 'Trade',
    },
    {
        menuName: 'admin',
        to: '/admin',
        icon: '2-livetrade',
        staking: true,
        title: 'Admin',
        isSuperUser: true,
    },
]

const MenuSideBar = () => {
    const [visibleModal, setVisibleModal] = useState(false)

    const navigate = useNavigate()
    const { isSuperuser } = useUserInfo()

    return (
        <div className="sticky bg-experts-gray-7 left-0 top-0 w-[68px] min-w-[68px] lg:w-28 lg:max-w-[112px] 2xl:w-[129px] 2xl:min-w-[129px] shadow-[inset_-2px_0px_6px_#00000029] z-[999]">
            <LogoutConfirm
                visible={visibleModal}
                onClose={() => setVisibleModal((state) => !state)}
                onConfirm={() => navigate('/logout')}
            />
            <MenuSideBarIcon />{' '}
            <div className="relative flex flex-row md:flex-col mb-10 md:gap-7 lg:gap-12">
                {menus.map((v) => {
                    if (v?.isSuperUser && !isSuperuser) return null
                    return <MenuSideBarItem key={`menu-${v.menuName}`} {...v} />
                })}
            </div>
            <div className="absolute bottom-5 left-0 right-0 flex">
                <MenuSideBarItem
                    title={'Log out'}
                    onClick={() => {
                        setVisibleModal((state) => !state)
                    }}
                    icon={'2-off'}
                />
            </div>
        </div>
    )
}

const MenuMobile = () => {
    const [visibleModal, setVisibleModal] = useState(false)

    const { isSuperuser } = useUserInfo()
    const navigate = useNavigate()

    return (
        <div className="fixed bg-experts-gray-7  left-0 bottom-0 right-0 shadow-[inset_-2px_0px_6px_#00000029] z-[999]">
            <LogoutConfirm
                visible={visibleModal}
                onClose={() => setVisibleModal((state) => !state)}
                onConfirm={() => navigate('/logout')}
            />
            {/* <MenuSideBarIcon /> */}
            <div className="flex flex-row">
                <div className="relative flex flex-row w-full px-8 pt-4 pb-3">
                    {menus.map((v) => {
                        // if (v?.staking && v.staking !== staked) return null
                        if (v?.isSuperUser && !isSuperuser) return null
                        return (
                            <MenuSideBarItem
                                key={`menu-${v.menuName}`}
                                id={`menu-${v.menuName}`}
                                isMobile
                                {...v}
                            />
                        )
                    })}
                    <MenuSideBarItem
                        onClick={() => {
                            setVisibleModal((state) => !state)
                        }}
                        id={`menu-logout`}
                        isMobile
                        icon={'2-off'}
                    />
                </div>
            </div>
        </div>
    )
}

const LogedLayout = () => {
    const isMobile = useResponsiveValue({ base: true, lg: false })

    useFetchMMStrategies()
    return (
        <div className="bg-experts-purple-1 h-screen p-0 flex flex-col md:flex-row justify-start min-w-[400px]">
            {!isMobile ? <MenuSideBar /> : <MenuMobile />}

            <main id="layout" className="w-full flex flex-col overflow-y-auto">
                <div className="w-full md:max-w-[calc(100vw_-_100px)] lg:max-w-[calc(100vw_-_129px)] xl:max-w-none flex flex-col mx-auto px-5 md:px-0 xl:pl-16 xl:pr-12 2xl:pr-20 pb-8">
                    <Header />
                    <Outlet />
                </div>
                <div className="mt-auto w-full md:max-w-[calc(100vw_-_100px)] lg:max-w-[calc(100vw_-_129px)] xl:max-w-none flex mx-auto px-5 md:px-0 xl:pl-16 xl:pr-12 2xl:pr-20 pb-20 lg:pb-0">
                    <p>tradingexperts.io ver. {process.env.REACT_APP_VERSION}</p>
                </div>
            </main>
        </div>
    )
}

export default LogedLayout
