import { combineReducers } from 'redux'
import { clear } from 'redux-localstorage-simple'
import authReducer from './auth/authReducer'
import userInfoReducer from './userInfoReducer/userInfoReducer'
import dashboardReducer from './dashboardReducer/dashboardReducer'
import tradesReducer from './tradesReducer/trades.slice'
import strategiesReducer from './strategies/strategiesReducer'
import symbolsStrategyReducer from './strategies/symbolsStrategy'
import simulatorReducer from './simulatorReducer/simulatorReducer'
import adminReducer from './adminReducer/adminReducer'
// import placeTradeReducer from './placeTrade/placeTradeReducer'
import globalTypes from './globalTypes'

export const PERSISTED_KEYS = [
    'authReducer',
    'userInfoReducer',
    'tradesReducer',
    'dashboardReducer',
]

const appReducer = combineReducers({
    authReducer,
    userInfoReducer,
    dashboardReducer,
    tradesReducer,
    strategiesReducer,
    symbolsStrategyReducer,
    simulatorReducer,
    adminReducer,
    // placeTradeReducer,
})

const rootReducer = (state, action) => {
    // Here I reset all the data of my reducers when user logout
    if (action.type === globalTypes.LOG_OUT) {
        localStorage.removeItem('token')
        clear({})
        state = {}
    }
    return appReducer(state, action)
}

export default rootReducer
