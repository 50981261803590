import React, { useState, useEffect } from 'react'
import packageJSON from '../../package.json'
import dayjs from 'dayjs'

const buildDateGreaterThan = (latestDate, currentDate) => {
    const latest = dayjs(latestDate)
    const current = dayjs(currentDate)

    if (latest.isAfter(current)) {
        return true
    }
    return false
}

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false)

        const refreshCacheAndReload = () => {
            console.log('Clearing cache and hard reloading...')
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then(function (names) {
                    for (const name of names) caches.delete(name)
                })
            }
            // delete browser cache and hard reload
            window.location.reload(true)
        }

        useEffect(() => {
            fetch('/meta.json')
                .then((response) => response.json())
                .then((meta) => {
                    const latestVersionDate = meta.buildDate
                    const currentVersionDate = packageJSON.buildDate
                    const shouldForceRefresh = buildDateGreaterThan(
                        latestVersionDate,
                        currentVersionDate
                    )

                    if (shouldForceRefresh) {
                        console.log(
                            `We have a new version - ${latestVersionDate}. Should force refresh`
                        )
                        setIsLatestBuildDate(false)
                        refreshCacheAndReload()
                    } else {
                        console.log(
                            `You already have the latest version - ${latestVersionDate}. No cache refresh needed.`
                        )
                        setIsLatestBuildDate(true)
                    }
                })
        }, [])

        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component {...props} /> : null}
            </React.Fragment>
        )
    }

    return ClearCacheComponent
}

export default withClearCache
