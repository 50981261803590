import { useCallback } from 'react'

/**
 * Create a hook that will retry a promise function until it succeeds or an limit is reached
 * @param {Function} promiseFn - The promise function to retry
 * @param {Object} options - The options object
 * @param {Number} options.retries - The number of times to retry the promise function
 * @param {Number} options.minTimeout - The minimum timeout between retries
 * @param {Number} options.maxTimeout - The maximum timeout between retries
 * @parm {Array} deps - The dependencies array
 */
export const useRetryCallback = (promiseFn, options, deps = []) => {
    const { retries = 5, minTimeout = 1000, maxTimeout = 5000 } = options || {}

    const retry = useCallback(
        async (...args) => {
            let lastError
            for (let i = 0; i < retries; i++) {
                console.log('retrying', i)
                try {
                    return await promiseFn(...args)
                } catch (error) {
                    lastError = error
                    const timeout = Math.min(minTimeout * 2 ** i, maxTimeout)
                    await new Promise((resolve) => setTimeout(resolve, timeout))
                }
            }
            throw lastError
        },
        [promiseFn, retries, minTimeout, maxTimeout, ...deps]
    )

    return retry
}
