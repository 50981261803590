import React from 'react'
import { Input, Form } from 'antd'
// import classNames from 'classnames'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import useResponsiveValue from 'src/hooks/useResponsiveValue'

const FormItem = Form.Item

// const classes = {
//     size: {
//         small: 'h-10 text-xs',
//         middle: 'h-[60px] text-xs',
//     },
// }

const FormikAntInputPassword = ({
    formik,
    name,
    placeholder,
    size = 'middle',
    disabled = false,
    className = '',
    ...props
}) => {
    const touched = formik.touched[name]
    const hasError = formik.errors[name]
    const submittedError = hasError && formik.submitCount > 0
    const touchedError = hasError && touched
    const value = formik.values[name]
    const inputSize =
        size ?? useResponsiveValue({ base: 'small', xl: 'middle' })
    return (
        <FormItem
            validateStatus={
                submittedError || touchedError ? 'error' : 'success'
            }
            help={submittedError || touchedError ? hasError : false}
        >
            <Input.Password
                id={name}
                name={name}
                placeholder={placeholder}
                value={value}
                className={className}
                // style={{ fontSize: '12px' }}
                iconRender={(visible) =>
                    visible ? (
                        <EyeOutlined style={{ color: '#000' }} />
                    ) : (
                        <EyeInvisibleOutlined style={{ color: '#000' }} />
                    )
                }
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                size={inputSize}
                {...props}
            />
        </FormItem>
    )
}

export default FormikAntInputPassword
