/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Input, Form } from 'antd'
import classNames from 'classnames'

const FormItem = Form.Item

const FormikAntInpuWithButton = ({
    formik,
    name,
    placeholder,
    value,
    icon,
    onClickButton,
    ...props
}) => {
    const touched = formik.touched[name]
    const hasError = formik.errors[name]
    const submittedError = hasError && formik.submitCount > 0
    const touchedError = hasError && touched
    const values = formik?.values

    useEffect(() => {
        if (value) formik.setValues({ ...values, [name]: value ?? '' })
    }, [value])

    return (
        <FormItem
            validateStatus={
                submittedError || touchedError ? 'error' : 'success'
            }
            help={submittedError || touchedError ? hasError : false}
        >
            <Input.Group compact className="flex">
                <Input
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    bordered={false}
                    value={value}
                    {...props}
                    className={classNames(
                        `h-[60px] text-xs rounded-sm shadow-experts-lg bg-white placeholder:`
                    )}
                    style={{ width: 'calc(100% - 95px)', fontSize: '12px' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <button
                    onClick={() => onClickButton()}
                    className="h-[60px] w-[80px] text-2xl rounded-md ml-auto flex justify-center items-center shadow-experts-lg "
                >
                    {icon}
                </button>
            </Input.Group>
        </FormItem>
    )
}

export default FormikAntInpuWithButton
