import React, { useRef, useCallback, useMemo } from 'react'
import CustomModal from 'src/components/Modals/GenericModals/CustomModal'
import {
    useAddSymbol,
    useReloadAdmin,
} from 'src/store/reducers/adminReducer/adminReducer'
import CryptoCurrenciesForm from './CryptoCurrenciesForm'
import allSymbols from 'src/JSONS/symbols.json'

const CryptoCurrenciesModal = ({
    visible = false,
    onClose,
    onSave,
    data,
    symbols = [],
}) => {
    const { addSymbol, loading } = useAddSymbol()
    const reloadAdmin = useReloadAdmin()
    const formRef = useRef(null)

    const handleClose = () => {
        formRef.current.resetForm()
        onClose()
    }
    const handleOkay = useCallback(() => {
        formRef.current.submitForm()
    }, [])

    const handleSubmit = useCallback(
        async ({ crypto }) => {
            // //llamar insercion a base
            addSymbol(crypto).then(() => {
                handleClose()
                reloadAdmin()
            })
        },
        [addSymbol, reloadAdmin]
    )

    const availableSymbols = useMemo(() => {
        return allSymbols.reduce((acc, symbol) => {
            if (symbols.findIndex((s) => s.symbol === symbol.symbol) !== -1)
                return acc
            return [...acc, symbol.symbol]
        }, [])
    }, [symbols])
    return (
        <CustomModal
            loading={loading}
            visible={visible}
            title={'Add New Crypto'}
            width={350}
            onCancel={handleClose}
            onOk={handleOkay}
        >
            <CryptoCurrenciesForm
                ref={formRef}
                onSubmit={handleSubmit}
                symbols={availableSymbols}
            />
        </CustomModal>
    )
}

export default CryptoCurrenciesModal
