import React from 'react'
// import { Menu } from 'antd'
import { HiDotsVertical } from 'react-icons/hi'
// import Icon from './../../Icons/Icon'

const TraderCard = ({ onEdit, data }) => {
    // const menu = (
    //     <Menu>
    //         {/* <Menu.Item onClick={onEdit}>
    //             <div className="flex flex-row items-center px-1">
    //                 <Icon
    //                     name="3-edit"
    //                     className="w-3 h-3 mr-3 text-experts-green-7"
    //                 />{' '}
    //                 <div className="text-[10px] text-black font-semibold">
    //                     Edit
    //                 </div>
    //             </div>
    //         </Menu.Item> */}
    //     </Menu>
    // )
    const { username, email } = data ?? {}

    return (
        <div className="bg-white shadow-experts-lg rounded-[5px] p-3">
            <div className="flex flex-row">
                <div className="flex-1">
                    <div className=" text-black text-[15px] font-bold">
                        {username}
                    </div>
                    <div className="flex flex-row mt-2">
                        <div>
                            <div className="font-semibold text-[9px] font-oxanium">
                                Email
                            </div>
                            <div>{email}</div>
                        </div>
                        {/* <div
                            className={`ml-auto text-xs font-bold ${
                                profitPercent >= 0
                                    ? 'text-green-600'
                                    : 'text-experts-red-2'
                            }`}
                        >
                            0 %
                        </div> */}
                    </div>
                </div>
                <div className="">
                    {/* <Dropdown overlay={menu}> */}
                    <a onClick={(e) => e.preventDefault()}>
                        <HiDotsVertical />
                    </a>
                    {/* </Dropdown> */}
                </div>
            </div>
        </div>
    )
}

export default TraderCard
