import React from 'react'
import TraderCard from './TraderCard'

const TradersList = ({ traders = [] }) => {
    return (
        <div className="gap-4 grid grid-cols-4 mt-3">
            {traders.map((t, index) => {
                return <TraderCard key={index} data={t} />
            })}
        </div>
    )
}

export default TradersList
