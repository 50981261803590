import React, { useState } from 'react'
// eslint-disable-next-line no-unused-vars
import { Popconfirm, Switch } from 'antd'
import { formatMoneyNumber } from 'src/utils/formatter'
import Icon from 'src/components/Icons/Icon'
import Button from 'src/components/Buttons/Button'
import { AiOutlineStop } from 'react-icons/ai'

const TradeCard = ({
    data,
    loading,
    disabled,
    onSwitch,
    checked = false,
    onEditSlTp,
    enableEditSlTP = false,
    onClose,
}) => {
    const [showInfo, setShowInfo] = useState(false)
    const {
        side,
        symbol,
        leverage,
        quantity,
        takeProfitTrigger,
        takeProfitPercent,
        estimateProfit,
        pnlusd,
        pnl,
        positionValue,
        entryprice,
        liqPrice,
        sl,
        tp,
        // manualTP,
        order_id: orderId,
    } = data ?? {}
    return (
        <div className="shadow-experts-lg bg-white border border-experts-gray-2 rounded-md pt-4 px-4 flex flex-col box-border">
            <div className="flex flex-row">
                <div className="flex-1">
                    <div className="font-extrabold text-black text-base">
                        {symbol}
                    </div>
                    <div className="flex flex-row">
                        <div
                            className={`mr-2 font-bold tracking-wider text-sm ${
                                side === 'Buy'
                                    ? 'text-green-600'
                                    : 'text-experts-red-2'
                            }`}
                        >
                            {side === 'Buy' ? 'Long' : 'Short'}
                        </div>{' '}
                        <div className="text-experts-yellow-3 font-bold text-sm">
                            {leverage}x
                        </div>
                    </div>
                </div>
                <div className="ml-auto">
                    <Switch
                        loading={loading}
                        disabled={loading || disabled}
                        className="w-[57px] "
                        onChange={() => {
                            onSwitch(data)
                        }}
                        checked={checked}
                    />
                </div>{' '}
            </div>
            <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="">
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        Qty
                    </div>
                    <div className="text-black font-semibold text-sm">
                        {quantity}
                    </div>
                </div>
                <div className="">
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        Position Value
                    </div>
                    <div className="text-black font-semibold text-sm">
                        {formatMoneyNumber(positionValue)}
                    </div>
                </div>
                <div className="">
                    <div className="text-[10px] font-medium text-experts-gray-5">
                        PNL
                    </div>
                    <div
                        className={`text-sm font-semibold ${
                            Number(pnl) >= 0
                                ? 'text-green-600'
                                : 'text-experts-red-2'
                        }`}
                    >
                        {Number(pnl)}%
                    </div>
                    <div
                        className={`text-sm font-semibold ${
                            Number(pnlusd) >= 0
                                ? 'text-green-600'
                                : 'text-experts-red-2'
                        }`}
                    >
                        {formatMoneyNumber(Number(pnlusd))}
                    </div>
                </div>
            </div>

            {showInfo && (
                <React.Fragment>
                    {takeProfitTrigger && (
                        <div className="grid grid-cols-3  gap-6 mt-3">
                            <div className="">
                                {' '}
                                <div className="">
                                    <div className="text-[10px] font-medium text-experts-gray-5">
                                        Take Profit Percent
                                    </div>
                                    <div className="text-green-600 font-semibold text-sm">
                                        {takeProfitPercent} %
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {' '}
                                <div className="">
                                    <div className="text-[10px] font-medium text-experts-gray-5">
                                        Est. PNL
                                    </div>
                                    <div className="text-green-600  font-semibold text-sm">
                                        ± {formatMoneyNumber(estimateProfit)}
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                {' '}
                                <div className="">
                                    <div className="text-[10px] font-medium text-experts-gray-5">
                                        Profit At
                                    </div>
                                    <div className="text-black font-semibold text-sm">
                                        {formatMoneyNumber(takeProfitTrigger)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="grid grid-cols-3 gap-6 mt-3">
                        <div className="">
                            <div className="text-[10px] font-medium text-experts-gray-5">
                                Entry Price
                            </div>
                            <div className="text-black font-semibold text-sm">
                                {formatMoneyNumber(entryprice)}
                            </div>
                        </div>
                        <div className="">
                            <div className="text-[10px] font-medium text-experts-gray-5">
                                Liq. Price
                            </div>
                            <div className="text-experts-red-2 font-semibold text-sm">
                                {formatMoneyNumber(liqPrice)}
                            </div>
                        </div>
                        <div className="">
                            <div className="text-[10px] font-medium text-experts-gray-5">
                                SL/TP
                            </div>
                            <div className="font-semibold text-sm">
                                <div className="flex flex-row">
                                    <span className="text-experts-green-2">
                                        {formatMoneyNumber(sl)}{' '}
                                    </span>
                                    /
                                    <span className="text-experts-red-2 ">
                                        {' '}
                                        {formatMoneyNumber(tp)}
                                    </span>{' '}
                                    {enableEditSlTP && (
                                        <button
                                            onClick={() => onEditSlTp(data)}
                                            className="ml-2"
                                        >
                                            <Icon
                                                name="3-edit"
                                                className="h-4 w-4 hover:text-experts-green-1 delay-150 duration-300"
                                            />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
            <div className="flex flex-row relative -mx-4 bg-experts-purple-1 border-t  mt-4 divide-x">
                <Button
                    onClick={() => setShowInfo((state) => !state)}
                    variant=""
                    className="w-full rounded-none shadow-none font-medium text-[10px] h-9 hover:scale-100"
                >
                    {' '}
                    {showInfo ? 'Hide Info' : 'Show Info'}
                </Button>
                <Popconfirm
                    placement="top"
                    title="Are you sure you want to close this position?"
                    onConfirm={() =>
                        onClose({
                            orderId: orderId,
                            symbol: symbol,
                        })
                    }
                >
                    <Button
                        disabled={loading || disabled}
                        loading={loading}
                        variant=""
                        className="w-full rounded-none shadow-none font-medium text-xs h-9 text-experts-red-2 hover:bg-experts-red-1 hover:scale-100"
                    >
                        {' '}
                        {!loading ? <AiOutlineStop /> : null}
                    </Button>
                </Popconfirm>
            </div>
            {/* <div
                onClick={() => setShowInfo((state) => !state)}
                className="cursor-pointer relative flex  justify-center border-t font-medium text-[10px] text-experts-gray-5 bg-experts-purple-1 -mx-4 py-1 mt-2"
            >
                {showInfo ? 'Hide Info' : 'Show Info'}
            </div> */}
        </div>
    )
}

export default TradeCard
