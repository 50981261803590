import React, { useState } from 'react'
import CryptoCurrenciesPage from 'src/components/AdminComponents/CryptoCurrenciesPage'
import SubAccountsPage from 'src/components/AdminComponents/SubAccountsPage'
import TradersPage from 'src/components/AdminComponents/TradersPage'
import VerticalTabs from 'src/components/Tabs/VerticalTabs'
import { useIsSuperUser } from 'src/store/reducers/userInfoReducer/userInfoReducer'

const ShareKeysPage = () => {
    return <div className="w-full h-full bg-red-400 "></div>
}

const adminPages = [
    { index: 'subAcc', component: SubAccountsPage },
    { index: 'shareKeys', component: ShareKeysPage },
    { index: 'traders', component: TradersPage },
    { index: 'cryptoCurrencies', component: CryptoCurrenciesPage },
]

const AdminView = (props) => {
    const [selectedTab, setSelectedTab] = useState(adminPages[0].index)

    useIsSuperUser()

    return (
        <div className="flex flex-row h-[calc(100vh_-_108px)]">
            <VerticalTabs onSelect={(index) => setSelectedTab(index)} />
            <div className="flex-1 flex w-full h-full scroll-generic overflow-y-auto pl-6">
                {adminPages.map((p) => {
                    return (
                        <div
                            key={`page-${p.index}`}
                            className={`flex-1 pr-2 ${
                                p.index === selectedTab ? 'block' : 'hidden'
                            }`}
                        >
                            <p.component />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default AdminView
