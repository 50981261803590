import React, { useState, useCallback } from 'react'
import StageItem from './StageItem'
import StageEdit from './StageEdit'
import useDeepCompareEffect from 'src/hooks/useDeepCompareEffect'
import ActiveMMStrategyModal from '../../ActiveMMStrategyModal'
import StageItemMobile from './StageItemMobile'
import StageEditMobileModal from './StageEditMobileModal'
import Icon from 'src/components/Icons/Icon'
import useResponsiveValue from '../../../../hooks/useResponsiveValue'

const StagesRatio = ({
    data = [],
    onAddStage,
    onSaveStage,
    onRemoveStage,
    addedNewStage = false,
    activeMM,
    strategyName,
}) => {
    const [{ activeStrategyModal }, setVisibleModals] = useState({
        activeStrategyModal: false,
    })
    const [selectedStage, setSelectedStage] = useState(null)
    const [stageAction, setStageAction] = useState('edit')

    // const limit = useMemo(() => {
    //     return 8 - data.length
    // }, [data])

    const setNewStage = useCallback(() => {
        const { saved } = data[data.length - 1] || {}

        if (!saved) {
            setSelectedStage(data.length - 1)
            setStageAction('new')
        } else setSelectedStage(null)
    }, [data])

    const handleSaveStage = useCallback((index, values) => {
        onSaveStage(index, values)
        setSelectedStage(null)
    }, [])

    useDeepCompareEffect(() => {
        setNewStage()
    }, [data])

    const handleSetVisibleModal = (modal) =>
        setVisibleModals((state) => ({ ...state, [modal]: !state[modal] }))

    const isMobile = useResponsiveValue({ base: true, lg: false })

    return (
        <React.Fragment>
            <ActiveMMStrategyModal
                strategyName={strategyName}
                visible={activeStrategyModal}
                message="You can't modified an active strategy"
                onClose={() => handleSetVisibleModal('activeStrategyModal')}
            />
            {isMobile && (
                <StageEditMobileModal
                    title={stageAction === 'new' ? 'New Stage' : 'Update Stage'}
                    visible={selectedStage !== null}
                    index={selectedStage}
                    initialValues={data[selectedStage]}
                    onCancel={() => {
                        if (!data[selectedStage].saved)
                            onRemoveStage(selectedStage)
                        setSelectedStage(null)
                    }}
                    onSave={(index, formValues) => {
                        handleSaveStage(index, formValues)
                    }}
                />
            )}
            <div className="lg:mt-6 space-y-3 lg:space-y-4">
                {data.map((value, index) => {
                    // eslint-disable-next-line no-lone-blocks

                    /* const [negative, positive] = value.data
                    const $value = {
                        actionPositive: positive.action,
                        actionNegative: negative.action,
                        conditionPositive: positive.condition,
                        conditionNegative: negative.condition,
                        sizePositive: positive.size,
                        sizeNegative: negative.size,
                        takeProfitPositive: positive.takeProfit,
                        takeProfitNegative: negative.takeProfit,
                    }
                     */
                    console.log('value', value)
                    if (isMobile) {
                        return (
                            <StageItemMobile
                                key={`stage-${index}`}
                                index={index}
                                disabledButtons={
                                    selectedStage && selectedStage !== index
                                }
                                hideDelete={data.length === 1}
                                onEdit={() => {
                                    setStageAction('edit')
                                    if (activeMM)
                                        handleSetVisibleModal(
                                            'activeStrategyModal'
                                        )
                                    else setSelectedStage(index)
                                }}
                                onRemoveStage={() => {
                                    if (activeMM)
                                        handleSetVisibleModal(
                                            'activeStrategyModal'
                                        )
                                    else onRemoveStage(index)
                                }}
                                data={value}
                            />
                        )
                    }
                    return index === selectedStage ? (
                        <StageEdit
                            key={`stage-edit-${index}`}
                            isNew={!value.saved}
                            initialValues={value}
                            onCancel={() => {
                                if (!value.saved) onRemoveStage(index)
                                setSelectedStage(null)
                            }}
                            onSave={(formValues) =>
                                handleSaveStage(index, formValues)
                            }
                        />
                    ) : (
                        <StageItem
                            key={`stage-${index}`}
                            index={index}
                            disabledButtons={
                                selectedStage && selectedStage !== index
                            }
                            hideDelete={data.length === 1}
                            onEdit={() => {
                                setStageAction('edit')
                                if (activeMM)
                                    handleSetVisibleModal('activeStrategyModal')
                                else setSelectedStage(index)
                            }}
                            onRemoveStage={() => {
                                if (activeMM)
                                    handleSetVisibleModal('activeStrategyModal')
                                else onRemoveStage(index)
                            }}
                            data={value}
                        />
                    )
                })}
            </div>
            <button
                disabled={selectedStage || activeMM}
                onClick={onAddStage}
                className="btn btn-add-stage h-16 lg:h-24 rounded-lg w-full disabled:bg-experts-gray-4/10 disabled:cursor-not-allowed mt-6"
            >
                <span className="font-semibold  flex relative text-base lg:text-xl">
                    <Icon
                        name="3-new"
                        className="relative w-4 h-4 mr-4 mt-[2px]"
                    />{' '}
                    New
                </span>
            </button>
        </React.Fragment>
    )
}

export default StagesRatio
