import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import axios from 'axios'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDeepCompareEffect from 'src/hooks/useDeepCompareEffect'

import axiosInstance from '../../services/axiosConfig'

export const getSimulatorTrades = createAsyncThunk(
    'simulator/trades',
    async ({ start, end }, { rejectWithValue, dispatch }) => {
        try {
            // const res = await axios.post(
            //     `https://igniite.io/api/bybit/historyTrade/`,
            //     {
            //         start,
            //         end,
            //     }
            // )
            // const res = await axiosInstance.post(`bybit/historyTrade/`, {
            //     start,
            //     end,
            // })
            const res = await axiosInstance.post(
                `bybit/externalhistorytrade/`,
                {
                    start,
                    end,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

const setPnlBySymbol = (trades) => {
    const pnlBySymbolObj = trades.reduce((acc, val) => {
        const symbol = val.symbol.substring(0, val.symbol.indexOf('USDT'))
        if (!acc[symbol]) {
            return { ...acc, [symbol]: Number(val.pnlUsd) }
        }
        return { ...acc, [symbol]: acc[symbol] + Number(val.pnlUsd) }
    }, {})

    return Object.keys(pnlBySymbolObj)
        .sort()
        .map((v) => ({
            name: v,
            value: pnlBySymbolObj[v],
        }))
}

const initialState = { trades: [] }

const simulatorSlice = createSlice({
    name: 'simulator',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSimulatorTrades.fulfilled, (state, { payload }) => {
            state.trades = payload.data.map((value) => {
                return {
                    ...value,
                    totalTrades: value.totalTrades.map(
                        ({ side, leverage, entryPrice, pnl, ...rest }) => ({
                            ...rest,
                            side,
                            leverage,
                            entryPrice,
                            pnl,
                        })
                    ),
                    pnlBySymbol: setPnlBySymbol(value.totalTrades),
                }
            })
        })
    },
})

// export const {} = referralSlice.actions

const selectSelf = (state) => state.simulatorReducer

export const useSimulator = () => {
    return useSelector(selectSelf)
}

export const useFetchSimulatorTrades = ({ rangeDate = [] }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { trades } = useSimulator()
    const fetch = useCallback(async () => {
        setLoading(true)
        try {
            if (rangeDate.length > 0) {
                const [start, end] = rangeDate

                await dispatch(
                    getSimulatorTrades({
                        start: start.format('YYYY-MM-DD'),
                        end: end.format('YYYY-MM-DD'),
                    })
                ).unwrap()
            } else {
                const start = dayjs().subtract(30, 'day').format('YYYY-MM-DD')
                const end = dayjs().subtract(1, 'day').format('YYYY-MM-DD')

                await dispatch(getSimulatorTrades({ start, end })).unwrap()
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [dispatch, rangeDate])

    useDeepCompareEffect(() => {
        fetch()
    }, [rangeDate])

    return { loading, trades, fetch }
}

export default simulatorSlice.reducer
