import React from 'react'
import { useTradesReducerState } from 'src/store/reducers/tradesReducer/trades.hook'
import TableTrades from './Tables/TableTrades'
import TradeCard from './TradeCard'
import NoRecord from './NoRecord'

const LiveTrades = ({
    reloading,
    subscribing,
    deleting,
    onSubscribeMM,
    onClose,
    disabled,
    onEditSlTp,
    isMobile,
    selectedStrategy,
}) => {
    const { bybitTrades, activeOrders } = useTradesReducerState()

    return (
        <React.Fragment>
            {' '}
            <div
                className={
                    'flex justify-start font-bold text-experts-gray-9 text-[16px] pl-2'
                }
            >
                Live Trades
            </div>
            {!isMobile ? (
                <div className="w-full max-w-full">
                    <TableTrades
                        dataSource={bybitTrades.map(({ phase, ...v }) => {
                            return {
                                ...v,
                                tp:
                                    activeOrders?.find(
                                        (a) =>
                                            a.symbol === v.symbol &&
                                            a.reduce_only
                                    )?.price ?? '0',
                                isRatio: selectedStrategy?.ratioStrategy,
                            }
                        })}
                        buttonText="Activate money Management"
                        onAction={onSubscribeMM}
                        onClose={onClose}
                        hideColumns={[
                            'phase',
                            'nextPhaseSE',
                            'nextStageInfo',
                            'takeProfitTrigger',
                        ]}
                        loading={subscribing || reloading || deleting}
                        disabled={disabled}
                        enableEditSlTP
                        onEditSlTp={onEditSlTp}
                    />
                </div>
            ) : (
                <div className="space-y-2">
                    {bybitTrades.length === 0 && <NoRecord />}
                    {bybitTrades
                        .map(({ phase, ...v }) => {
                            return {
                                ...v,
                                tp:
                                    activeOrders?.find(
                                        (a) =>
                                            a.symbol === v.symbol &&
                                            a.reduce_only
                                    )?.price ?? '0',
                            }
                        })
                        .map((v) => (
                            <TradeCard
                                key={`trade-card-${v.id}`}
                                data={v}
                                onClose={onClose}
                                loading={subscribing || reloading || deleting}
                                onEditSlTp={onEditSlTp}
                                onSwitch={onSubscribeMM}
                            />
                        ))}
                </div>
            )}
        </React.Fragment>
    )
}

export default LiveTrades
