import { useMemo } from 'react'
import { makeSimulation } from 'src/service/simulatorServices'
import { Table } from 'antd'
import { formatMoneyNumber } from 'src/utils/formatter'
import numeral from 'numeral'

const ACTION_LEVERAGE = 'Leverage'
const ACTION_CROSS = 'Cross'
const ACTION_INCREASE = 'Increase'
const ACTION_DEFAULT = 'Default'

// eslint-disable-next-line no-unused-vars
const ACTIONS = {
    [ACTION_LEVERAGE]: 'Change Leverage',
    [ACTION_CROSS]: 'Change Cross',
    [ACTION_INCREASE]: 'Increase Quantity',
    [ACTION_DEFAULT]: 'Initial State',
}

const setPercentAccountColor = (percentAccount) => {
    const _percentAccount = Number(percentAccount.replace('%', ''))
    if (_percentAccount > 90) return 'text-red-600'
    if (_percentAccount > 80) return 'text-red-300'
    if (_percentAccount > 70) return 'text-yellow-600'
    if (_percentAccount > 60) return 'text-yellow-300'
    if (_percentAccount > 40) return 'text-green-400'
    return 'text-green-600'
}

const SimulatorResults = ({ loading, show, parameters = {}, stages = [] }) => {
    if (!show) return

    const columns = [
        {
            title: 'Side',
            dataIndex: 'side',
            key: 'side',

            render: (value) => (
                <span
                    className={`text-center ${
                        value === 'Long'
                            ? 'text-green-600'
                            : 'text-experts-red-2'
                    }`}
                >
                    {value}
                </span>
            ),
        },
        {
            title: 'Stage',
            dataIndex: 'stage',
            key: 'stage',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'Real Leverage',
            dataIndex: 'realLeverage',
            key: 'leverage',
        },
        {
            title: 'Real Qty',
            dataIndex: 'realQty',
            key: 'qty',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'M. Fee',
            dataIndex: 'maintenanceFee',
            key: 'maintenanceFee',
        },
        {
            title: 'Open Fee',
            dataIndex: 'openFee',
            key: 'openFee',
        },
        {
            title: 'Real Margin',
            dataIndex: 'realMargin',
            key: 'realMargin',
        },
        {
            title: 'Entry Price',
            dataIndex: 'averageEntryPrice',
            key: 'averageEntryPrice',
        },
        {
            title: 'Liq. Price',
            dataIndex: 'liqPrice',
            key: 'liqPrice',
            render: (value) => (
                <span className="font-bold text-experts-red-2">{value}</span>
            ),
        },
        {
            title: 'S.E. Price',
            dataIndex: 'conditionPrice',
            key: 'conditionPrice',
        },
        {
            title: 'Next Stage',
            dataIndex: 'nextStage',
            key: 'nextStage',
        },
        {
            title: 'TP Trigger',
            dataIndex: 'tpTrigger',
            key: 'tpTrigger',
        },
        {
            title: 'Distance To TP',
            dataIndex: 'distanceToTp',
            key: 'distanceToTp',
        },
        {
            title: 'PNL',
            dataIndex: 'pnl',
            key: 'pnl',
            render: (value) => (
                <span className="font-bold text-green-600">{value}</span>
            ),
        },
        {
            title: '% PNL',
            dataIndex: 'pnlPercent',
            key: 'pnlPercent',
            render: (value) => (
                <span className="font-bold text-green-600">{value}</span>
            ),
        },
        {
            title: '% Account',
            dataIndex: 'percentAccount',
            key: 'percentAccount',
            render: (value) => (
                <span className={setPercentAccountColor(value)}>{value}</span>
            ),
        },
    ].map((v) => ({ ...v, align: 'center' }))

    const data = useMemo(() => {
        if (Object.keys(parameters).length === 0) return []
        const simulation = makeSimulation(stages, parameters)
        return simulation.map(
            (
                {
                    action,
                    size,
                    realLeverage,
                    maintenanceFee,
                    openFee,
                    realMargin,
                    liqPrice,
                    tpTrigger,
                    percentAccount,
                    averageEntryPrice,
                    realQty,
                    value,
                    conditionPrice,
                    pnlPercent,
                    pnl,
                    distanceToTp,
                    ...v
                },
                index
            ) => ({
                ...v,

                stage: `Stage ${index}`,
                size,
                value: formatMoneyNumber(value),
                action: ACTIONS[action],
                realLeverage: realLeverage + 'x',
                realQty: formatMoneyNumber(realQty),
                averageEntryPrice: formatMoneyNumber(averageEntryPrice),

                nextStage:
                    index < simulation.length - 1
                        ? formatMoneyNumber(
                              simulation[index + 1].conditionPrice
                          )
                        : 'N/A',
                maintenanceFee: formatMoneyNumber(maintenanceFee),
                conditionPrice: formatMoneyNumber(conditionPrice),
                openFee: formatMoneyNumber(openFee),
                realMargin: formatMoneyNumber(realMargin),
                liqPrice: formatMoneyNumber(liqPrice),
                tpTrigger: formatMoneyNumber(tpTrigger),
                percentAccount:
                    numeral(percentAccount).format('0,000.00', Math.floor) +
                    '%',
                pnlPercent:
                    numeral(pnlPercent).format('0,000.00', Math.floor) + '%',
                pnl: formatMoneyNumber(pnl, 2, 2),
                distanceToTp: formatMoneyNumber(distanceToTp),
            })
        )
    }, [stages, parameters])
    return (
        <div className="w-full">
            <Table
                className="w-full"
                loading={loading}
                size="small"
                columns={columns}
                dataSource={loading ? [] : data}
                scroll={{ x: 1500 }}
            />
        </div>
    )
}

export default SimulatorResults
