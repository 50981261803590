/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react'
import { Select, Tooltip } from 'antd'
import numeral from 'numeral'
import { useFormik } from 'formik'
import CustomModal from '../../../Modals/GenericModals/CustomModal'
import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'
import { numberValidation } from 'src/service/inputValidations'
import {
    CONDITION_NEGATIVE_TITLE_BY_TYPE,
    PHASE_TYPES_ARR,
    PHASE_TYPES,
    TITLE_BY_TYPE,
    FIELD_TITLE_BY_TYPE,
    TOOLTIP_MESSAGE,
} from 'src/constants/phase.constants'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { CONDITION_POSITIVE_TITLE_BY_TYPE } from './../../../../constants/phase.constants'
import {
    formatValueToNumber,
    formatValueToNumberOnChange,
} from 'src/utils/inputsUtilities'
import { setOptionsValidationsByType } from '../helpers/stagesFieldValidatios'

const { Option } = Select

const StageEditMobileModal = ({
    initialValues,
    onCancel,
    onSave,
    loading,
    index,
    ...props
}) => {
    const $setInitialValues = (initialValues = {}) => ({
        /**
         * Action type to take when condition is met positive
         */
        actionSecondary: null,
        /**
         * Action type to take when condition is met negative
         */
        action: 'Leverage',

        /**
         * Condition to check for positive
         */
        conditionSecondary: null,
        /**
         * Condition to check for negative
         */
        condition: '50',

        /**
         * Size to take when condition is met positive
         * @type {string}
         */
        sizeSecondary: null,

        /**
         * Size to take when condition is met negative
         */
        size: '50',

        /**
         * Take profit to take when condition is met positive
         */
        takeProfitSecondary: null,

        /**
         * Take profit to take when condition is met negative
         */
        takeProfit: '100',
        ...initialValues,
    })

    const handleSubmit = useCallback(
        ({ size, ...values }) => {
            onSave(index, { ...values, size: numeral(size).format('0.00') })
        },
        [index]
    )

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: $setInitialValues(initialValues),
        onSubmit: handleSubmit,
    })

    useEffect(() => {
        if (initialValues) formik.resetForm($setInitialValues(initialValues))
    }, [initialValues])

    return (
        <CustomModal
            {...props}
            loading={loading}
            onCancel={onCancel}
            onOk={() => {
                formik.submitForm()
            }}
            width={380}
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="space-y-4">
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            Action to take
                        </div>
                        <Select
                            className="w-full"
                            value={formik.values?.action}
                            onChange={(v) => {
                                formik.setValues({
                                    ...formik.values,
                                    action: v,
                                })
                            }}
                            size={'small'}
                        >
                            {PHASE_TYPES_ARR.map((type) => (
                                <Option key={`option-${type}`} value={type}>
                                    {TITLE_BY_TYPE[type]}
                                </Option>
                            ))}
                        </Select>
                        {/* <FormikAntInput formik={formik} /> */}
                    </div>{' '}
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            {CONDITION_NEGATIVE_TITLE_BY_TYPE[
                                formik.values?.action
                            ] || CONDITION_NEGATIVE_TITLE_BY_TYPE.DEFAULT}{' '}
                            %
                        </div>
                        <FormikAntInput
                            formik={formik}
                            name="condition"
                            showError={false}
                            onChange={(e) => {
                                const {
                                    target: { value, name },
                                } = e
                                const $value = formatValueToNumberOnChange(
                                    value,
                                    {
                                        decimals: 2,
                                        enableEmptyValue: true,
                                        min: 0,
                                        max: 100,
                                    }
                                )
                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }}
                            onBlur={(e) => {
                                const { name, value } = e.target

                                formik.setTouched({ [name]: true })
                                if (value === '0') {
                                    formik.setValues({
                                        ...formik.values,
                                        [name]: '10',
                                    })
                                } else {
                                    const $value = formatValueToNumber(
                                        value,
                                        '10'
                                    )

                                    formik.setValues({
                                        ...formik.values,
                                        [name]: $value,
                                    })
                                }
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <div className="flex flex-row">
                            <div className="text-xs tracking-wide mb-2">
                                {FIELD_TITLE_BY_TYPE[formik.values?.action]}
                            </div>
                            <Tooltip
                                title={TOOLTIP_MESSAGE[formik.values?.action]}
                            >
                                <AiOutlineQuestionCircle className="ml-1" />
                            </Tooltip>
                        </div>
                        <FormikAntInput
                            formik={formik}
                            name="size"
                            showError={false}
                            onChange={(e) => {
                                const {
                                    target: { value, name },
                                } = e
                                const $value = formatValueToNumberOnChange(
                                    value,
                                    setOptionsValidationsByType(
                                        formik.values?.action
                                    )
                                )
                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }}
                            onBlur={(e) => {
                                const { name, value } = e.target

                                formik.setTouched({ [name]: true })
                                if (value === '0') {
                                    formik.setValues({
                                        ...formik.values,
                                        [name]: '10',
                                    })
                                } else {
                                    const $value = formatValueToNumber(
                                        value,
                                        '10'
                                    )

                                    formik.setValues({
                                        ...formik.values,
                                        [name]: $value,
                                    })
                                }
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            Take Profit %
                        </div>
                        <FormikAntInput
                            formik={formik}
                            name="takeProfit"
                            showError={false}
                            onChange={(e) => {
                                const {
                                    target: { value, name },
                                } = e
                                const $value = formatValueToNumberOnChange(
                                    value,
                                    {
                                        decimals: 4,
                                        min: 0,
                                        enableEmptyValue: true,
                                    }
                                )

                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }}
                            onBlur={(e) => {
                                const { name, value } = e.target

                                formik.setTouched({ [name]: true })
                                if (value === '0') {
                                    formik.setValues({
                                        ...formik.values,
                                        [name]: '10',
                                    })
                                } else {
                                    const $value = formatValueToNumber(
                                        value,
                                        '10'
                                    )

                                    formik.setValues({
                                        ...formik.values,
                                        [name]: $value,
                                    })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="space-y-4 mt-4">
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            Secondary Action to take
                        </div>
                        <Select
                            className="w-full"
                            value={formik.values?.actionSecondary}
                            onChange={(v) => {
                                let $values = {
                                    ...formik.values,
                                    actionSecondary: v,
                                }
                                if (v === null) {
                                    /**
                                    Reset secondary values
                                */
                                    $values = {
                                        ...$values,
                                        conditionSecondary: null,
                                        takeProfitSecondary: null,
                                        sizeSecondary: null,
                                    }
                                } else {
                                    /**
                                    Set secondary values
                                */
                                    $values = {
                                        ...$values,
                                        conditionSecondary: '10',
                                        takeProfitSecondary: '10',
                                        sizeSecondary: '10',
                                    }
                                }
                                formik.setValues($values)
                            }}
                            size={'small'}
                        >
                            <Option value={PHASE_TYPES.EMPTY}>
                                {TITLE_BY_TYPE[PHASE_TYPES.EMPTY]}
                            </Option>
                            <Option value={PHASE_TYPES.CLOSE_PARTIAL}>
                                {TITLE_BY_TYPE[PHASE_TYPES.CLOSE_PARTIAL]}
                            </Option>
                        </Select>
                        {/* <FormikAntInput formik={formik} /> */}
                    </div>{' '}
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            Secondary {''}
                            {CONDITION_POSITIVE_TITLE_BY_TYPE[
                                formik.values?.action
                            ] || CONDITION_POSITIVE_TITLE_BY_TYPE.DEFAULT}{' '}
                            %
                        </div>
                        <FormikAntInput
                            formik={formik}
                            name="conditionSecondary"
                            showError={false}
                            disabled={formik.values?.actionSecondary === null}
                            onChange={(e) => {
                                const {
                                    target: { value, name },
                                } = e
                                const $value = formatValueToNumberOnChange(
                                    value,
                                    {
                                        decimals: 2,
                                        enableEmptyValue: true,
                                        min: 0,
                                        max: 100,
                                    }
                                )
                                formik.setValues({
                                    ...formik.values,
                                    [name]: $value,
                                })
                            }}
                            onBlur={(e) => {
                                const { name, value } = e.target

                                formik.setTouched({ [name]: true })
                                if (formik.values?.actionSecondary) {
                                    if (value === '0') {
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: '10',
                                        })
                                    } else {
                                        const $value = formatValueToNumber(
                                            value,
                                            '10'
                                        )

                                        formik.setValues({
                                            ...formik.values,
                                            [name]: $value,
                                        })
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <div className="flex flex-row">
                            <div className="text-xs tracking-wide mb-2">
                                Secondary{' '}
                                {FIELD_TITLE_BY_TYPE[formik.values?.action]}{' '}
                            </div>
                            <Tooltip
                                title={TOOLTIP_MESSAGE[formik.values?.action]}
                            >
                                <AiOutlineQuestionCircle className="ml-1" />
                            </Tooltip>
                        </div>
                        <FormikAntInput
                            formik={formik}
                            name="sizeSecondary"
                            showError={false}
                            disabled={formik.values?.actionSecondary === null}
                            onChange={(e) => {
                                const {
                                    target: { value, name },
                                } = e
                            }}
                            onBlur={(e) => {
                                const { name, value } = e.target

                                formik.setTouched({ [name]: true })
                                if (formik.values?.actionSecondary) {
                                    if (value === '0') {
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: '10',
                                        })
                                    } else {
                                        const $value = formatValueToNumber(
                                            value,
                                            '10'
                                        )

                                        formik.setValues({
                                            ...formik.values,
                                            [name]: $value,
                                        })
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className="flex-1">
                        <div className="text-xs tracking-wide mb-1">
                            Secondary Take Profit %
                        </div>
                        <FormikAntInput
                            formik={formik}
                            name="takeProfitSecondary"
                            showError={false}
                            disabled={formik.values?.actionSecondary === null}
                            onChange={(e) => {
                                const {
                                    target: { value, name },
                                } = e
                            }}
                            onBlur={(e) => {
                                const { name, value } = e.target

                                formik.setTouched({ [name]: true })
                                if (formik.values?.actionSecondary) {
                                    if (value === '0') {
                                        formik.setValues({
                                            ...formik.values,
                                            [name]: '10',
                                        })
                                    } else {
                                        const $value = formatValueToNumber(
                                            value,
                                            '10'
                                        )

                                        formik.setValues({
                                            ...formik.values,
                                            [name]: $value,
                                        })
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </form>
        </CustomModal>
    )
}

export default StageEditMobileModal
