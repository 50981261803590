import { AiOutlineClose } from 'react-icons/ai'

const CapsuleList = ({ name, list = [], onDelete }) => {
    return list.map((v, index) => (
        <div
            key={`${name}-${v}`}
            className="inline-block rounded-full bg-experts-yellow-2 h-6 overflow-hidden"
        >
            <div className="flex h-full">
                <div className="flex-auto px-3">{v}</div>
                <div
                    onClick={() => onDelete(index)}
                    className="flex-initial px-2 hover:cursor-pointer btn rounded-none hover:bg-yellow-500 h-full"
                >
                    <AiOutlineClose />
                </div>
            </div>
        </div>
    ))
}

export default CapsuleList
