import React from 'react'
import GenericModal from '../GenericModals/GenericModal'

const SomethingWentWrong = ({ visible, onClose }) => {
    return (
        <GenericModal visible={visible} onClose={onClose}>
            <div className="flex flex-col gap-2 text-center">
                <div className="font-bold text-base tracking-widest ">
                    Something went wrong while trying to proccess your request.
                </div>
            </div>
        </GenericModal>
    )
}

export default SomethingWentWrong
