import { Popconfirm, Switch, Table as TableAnt } from 'antd'

import { AiOutlineStop } from 'react-icons/ai'
import { formatMoneyNumber } from 'src/utils/formatter'

import useResponsiveValue from 'src/hooks/useResponsiveValue'
import Button from '../../../Buttons/Button'
import Icon from 'src/components/Icons/Icon'
import { MESSAGE_BY_ACTION } from 'src/constants/phase.constants'

// const LEVERAGE = 'Leverage'
// const CROSS = 'Cross'
// const INCREASE = 'Increase'
// const NA = 'N/A'

// const MESSAGE_BY_ACTION = {
//     [LEVERAGE]: 'Change Leverage',
//     [CROSS]: 'Change to Cross',
//     [INCREASE]: 'Increase Qty',
//     [NA]: 'N/A',
// }

const TableTrades = ({
    dataSource,
    onAction,
    onClose,
    buttonText = '',
    checked = false,
    hideColumns = [],
    loading,
    disabled,
    enableEditSlTP = false,
    onEditSlTp,
}) => {
    const columns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (value, record) => (
                <div className="flex flex-col items-center">
                    <div>
                        <div className="text-black font-bold text-xs xl:text-base">
                            {value}
                        </div>
                        <div className="flex flex-row">
                            <span
                                className={`mr-2 font-bold tracking-wider text-sm ${
                                    record.side === 'Buy'
                                        ? 'text-green-600'
                                        : 'text-experts-red-2'
                                }`}
                            >
                                {record.side === 'Buy' ? 'Long' : 'Short'}
                            </span>
                            <span
                                className={`text-experts-yellow-3 font-bold text-sm`}
                            >
                                {record.leverage}x{' '}
                                {record.isolated ? 'Isolated' : 'Cross'}
                            </span>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Used Balance',
            dataIndex: 'balancepercentage',
            key: 'balancepercentage',
            render: (value) => <span>{value}%</span>,
        },
        {
            title: 'P&L (ROE)',
            dataIndex: 'pnl',
            key: 'pnl',
            render: (value, record) => (
                <span className="flex flex-col">
                    <span
                        className={
                            Number(value) >= 0
                                ? 'text-green-600'
                                : 'text-experts-red-2'
                        }
                    >
                        {Number(value)}%
                    </span>
                    <span
                        className={
                            Number(value) >= 0
                                ? 'text-green-600'
                                : 'text-experts-red-2'
                        }
                    >
                        {formatMoneyNumber(Number(record.pnlusd))}
                    </span>
                </span>
            ),
        },
        {
            title: 'Take Profit',
            dataIndex: 'takeProfitTrigger',
            key: 'takeProfitTrigger',
            render: (value, record) => (
                <div className="">
                    <div className="text-green-600 font-bold tracking-wider">
                        {record?.takeProfitPercent} %
                    </div>
                    <div className="text-green-600 font-bold tracking-wider">
                        ± {formatMoneyNumber(record?.estimateProfit)}
                    </div>
                    <div className="text-black font-bold tracking-wider">
                        (At: {formatMoneyNumber(record.takeProfitTrigger)})
                    </div>
                </div>
            ),
        },
        {
            title: 'Position Value',
            dataIndex: 'positionValue',
            key: 'positionValue',
            render: (value) => (
                <span className="text-experts-green-2 font-bold tracking-wider">
                    {formatMoneyNumber(value)}
                </span>
            ),
        },
        {
            title: 'Entry Price',
            dataIndex: 'entryprice',
            key: 'entryprice',
            render: (value) => <span>{formatMoneyNumber(value)}</span>,
        },
        {
            title: 'Liquidation Price',
            dataIndex: 'liqPrice',
            key: 'liqPrice',
            render: (value) => (
                <span className="text-experts-red-2 font-bold tracking-wider">
                    {formatMoneyNumber(value)}
                </span>
            ),
        },
        {
            title: 'Stage',
            dataIndex: 'phase',
            key: 'phase',
        },
        {
            title: 'Next Stage Info',
            dataIndex: 'nextStageInfo',
            key: 'nextStageInfo',
            render: (value, record) => (
                <span className="flex flex-col">
                    <span className="mb-1">
                        {MESSAGE_BY_ACTION[record?.nextPhaseAction]}
                    </span>
                    <span>(At {formatMoneyNumber(record.nextPhaseSE)})</span>
                </span>
            ),
        },
        {
            title: 'SL/TP',
            dataIndex: 'stopLossTakeProfit',
            key: 'stopLossTakeProfit',
            render: (_, record) => {
                return (
                    <div className="flex flex-row justify-center text-black">
                        <span className="mr-2">
                            {formatMoneyNumber(record.sl)}/
                            {formatMoneyNumber(record.tp)}
                        </span>
                        {enableEditSlTP && (
                            <button onClick={() => onEditSlTp(record)}>
                                <Icon
                                    name="3-edit"
                                    className="h-4 w-4 hover:text-experts-green-1 delay-150 duration-300"
                                />
                            </button>
                        )}
                    </div>
                )
            },
        },
        {
            title: 'Money Management',
            key: 'action',
            dataIndex: 'action',
            width: '7%',
            render: (_, record) => (
                <Switch
                    loading={loading}
                    disabled={
                        loading ||
                        disabled ||
                        (record.isRatio && record.tp === '0')
                    }
                    className="w-[74px] "
                    size="small"
                    onChange={() => {
                        onAction(record)
                    }}
                    checked={checked}
                />
            ),
        },
        {
            title: 'Close Market',
            key: 'cancelOrder',
            dataIndex: 'cancelOrder',
            width: '7%',
            render: (_, record) => (
                <Popconfirm
                    placement="top"
                    title="Are you sure you want to close this position?"
                    onConfirm={() =>
                        onClose({
                            orderId: record.order_id,
                            symbol: record.symbol,
                        })
                    }
                >
                    <Button
                        //   onClick={() => {
                        //       onClose({
                        //           orderId: record.order_id,
                        //           symbol: record.symbol,
                        //       })
                        //   }}
                        disabled={loading || disabled}
                        size="small"
                        variant="lightDanger"
                        className="w-9"
                        loading={loading}
                    >
                        {!loading ? <AiOutlineStop /> : null}
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const scroll = useResponsiveValue({
        base: { y: '600px', x: '1000px' },
        lg: {},
    })

    return (
        <TableAnt
            size="small"
            className="flex-1"
            center
            columns={columns
                .filter((v) => !hideColumns.includes(v.dataIndex))
                .map((v) => ({ ...v, align: 'center' }))}
            dataSource={dataSource}
            scroll={scroll}
        />
    )
}

export default TableTrades
