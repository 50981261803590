/* eslint-disable camelcase */
import { useEffect } from 'react'
import {
    createReducer,
    createAsyncThunk,
    createSelector,
} from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../services/axiosConfig'
import { useSelector } from 'react-redux'

export const getUserExtraInfo = createAsyncThunk(
    'userInfo/getUserInfo',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get(`bybit/extrainfouser/`)
            return res.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

const selectSelf = (state) => state.userInfoReducer

export const userInfoSelector = createSelector(selectSelf, (self) => self)

export const useUserInfo = () => {
    return useSelector(userInfoSelector)
}

// Create a hook that will validate if the user is super user and will send user to home page if not through the useNavigate hook
// The validation is done inside the hook useEffect and will be executed only once

export const useIsSuperUser = () => {
    const { isSuperuser } = useUserInfo()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isSuperuser) {
            navigate('/')
        }
    }, [isSuperuser])
}

export const initialState = {
    id: '',
    lastLogin: '',
    isSuperuser: false,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    isStaff: false,
    isActive: false,
    dateJoined: '',
    alias: '',
    address: '',
    referralLink: '',
    country: '',
    authenticator: false,
}

const userInfoReducer = createReducer(initialState, (builder) => {
    builder.addCase(
        getUserExtraInfo.fulfilled,
        (
            state,
            {
                payload: {
                    last_login,
                    is_superuser,
                    first_name,
                    last_name,
                    is_staff,
                    is_active,
                    date_joined,
                    ...rest
                },
            }
        ) => {
            return {
                ...state,
                ...rest,

                lastLogin: last_login,
                isSuperuser: is_superuser,

                firstName: first_name,
                lastName: last_name,

                isStaff: is_staff,
                isActive: is_active,
                dateJoined: date_joined,
            }
        }
    )
})

export default userInfoReducer
