/* eslint-disable no-unused-vars */
import { Popconfirm, Switch, Table as TableAnt } from 'antd'

import { FaSpinner } from 'react-icons/fa'
import useResponsiveValue from 'src/hooks/useResponsiveValue'
import Button from '../../../Buttons/Button'
import { AiOutlineStop } from 'react-icons/ai'
import { formatMoneyNumber } from 'src/utils/formatter'

const columns = [
    {
        title: 'Symbol',
        dataIndex: 'symbol',
        key: 'symbol',
        render: (value) => (
            <span className="text-black font-bold text-xs xl:text-base">
                {value}
            </span>
        ),
    },
    {
        title: 'Side',
        dataIndex: 'side',
        key: 'side',
        render: (value, record) =>
            record.reduce_only ? (
                <span
                    className={`font-semibold tracking-wider flex flex-col ${
                        value === 'Buy'
                            ? 'text-experts-red-2'
                            : 'text-green-600'
                    }`}
                >
                    <span> {value === 'Buy' ? 'Short' : 'Long'}</span>
                    <span>Close</span>
                </span>
            ) : (
                <span
                    className={`font-semibold tracking-wider ${
                        value === 'Buy'
                            ? 'text-green-600'
                            : 'text-experts-red-2'
                    }`}
                >
                    {value === 'Buy' ? 'Long' : 'Short'}
                </span>
            ),
    },
    {
        title: 'Qty',
        dataIndex: 'qty',
        key: 'qty',
    },

    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: (value) => <span>{formatMoneyNumber(value)}</span>,
        // render: (value) => (
        //     <span className="text-experts-green-2 font-bold tracking-wider">
        //         {value}x
        //     </span>
        // ),
    },
    // {
    //     title: 'SL/TP',
    //     dataIndex: 'stopLossTakeProfit',
    //     key: 'stopLossTakeProfit',
    //     render: (_, record) => (
    //         <span className="">
    //             {record.stop_loss}/{record.take_profit}
    //         </span>
    //     ),
    // },
]

const TableOrders = ({
    dataSource,
    onAction,
    buttonText = '',
    checked = false,
    hideColumns = [],
    deleting,
}) => {
    const col = [
        ...columns,
        {
            title: 'Action',
            key: 'cancelOrder',
            dataIndex: 'cancelOrder',
            width: '7%',
            render: (_, record) => (
                <Popconfirm
                    placement="top"
                    title="Are you sure you want to close this order?"
                    onConfirm={() =>
                        onAction({
                            orderId: record.order_id,
                            symbol: record.symbol,
                            side: record.side,
                        })
                    }
                >
                    <Button
                        size="small"
                        className="w-9"
                        variant="lightDanger"
                        disabled={deleting || record.reduce_only}
                        loading={deleting}
                    >
                        {!deleting ? <AiOutlineStop /> : null}
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const scroll = useResponsiveValue({
        base: { y: '600px', x: '1000px' },
        lg: {},
    })

    return (
        <TableAnt
            size="small"
            className="flex-1 max-h-[450px]"
            center
            columns={col
                .filter((v) => !hideColumns.includes(v.dataIndex))
                .map((v) => ({ ...v, align: 'center' }))}
            dataSource={dataSource}
            scroll={scroll}
        />
    )
}

export default TableOrders
