import React, { useMemo } from 'react'
import { useTradesReducerState } from 'src/store/reducers/tradesReducer/trades.hook'
import NoRecord from './NoRecord'
import OrderCard from './OrderCard'
import TableOrders from './Tables/TableOrders'

const PendingOrders = ({ onClose, deleting, disabled, isMobile }) => {
    const { activeOrders, symbolsOrderDeleted } = useTradesReducerState()

    const _activeOrders = useMemo(() => {
        return activeOrders.filter(
            (a) =>
                !symbolsOrderDeleted.find(
                    (v) => v[0] === a.symbol && v[1] === a.side
                )
        )
    }, [activeOrders, symbolsOrderDeleted])

    return (
        <React.Fragment>
            {' '}
            <div className="flex justify-start font-bold text-experts-gray-9 text-[16px] pl-2">
                Active Orders
            </div>
            {!isMobile ? (
                <div className="w-full max-w-full">
                    <TableOrders
                        dataSource={activeOrders.filter(
                            (a) =>
                                !symbolsOrderDeleted.find(
                                    (v) => v[0] === a.symbol && v[1] === a.side
                                )
                        )}
                        onAction={onClose}
                        hideColumns={[]}
                        deleting={deleting}
                        disabled={disabled}
                    />
                </div>
            ) : (
                <div className="space-y-2">
                    {' '}
                    {_activeOrders.length === 0 && <NoRecord />}
                    {_activeOrders.map((v) => (
                        <OrderCard
                            key={`trade-card-mm-${v.id}`}
                            data={v}
                            onClose={onClose}
                            deleting={deleting}
                            disabled={disabled}
                        />
                    ))}
                </div>
            )}
        </React.Fragment>
    )
}

export default PendingOrders
